import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import Select from 'react-select';
import {useTranslation} from "react-i18next";
import {
  CButton, CCard, CCardBody, CCardHeader, CCardTitle,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea
} from "@coreui/react";
import {cilX} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  LayersControl,
  MapContainer,
  Marker, Popup,
  ScaleControl,
  TileLayer,
  useMapEvents,
  ZoomControl
} from 'react-leaflet';
import Leaflet from 'leaflet';
import Control from "react-leaflet-custom-control";
import dayjs from 'dayjs';
import DatePicker, {registerLocale} from 'react-datepicker';
import {enUS, ru} from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import 'dayjs/locale/ky';

import AccordionEsReport from "./AccordionEsReport";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import SpinnerDotted from "../../../components/preloaders/SpinnerDotted/SpinnerDotted";
import TimePicker from "../../../components/UI/TimePicker/TimePicker";
import {getTiles} from "../../../components/maps/tiles";
import MapLoader from "../../../components/preloaders/MapLoader/MapLoader";

import config from "../../../config";
import {initialEmergencyState, initialMainState} from "./initialConfig";
import {IEmergencyType} from "../../../interfaces/IEmergency";
import makeQuery from "../../../functions/makeQuery";
import {sortArrayOfObjectsByKey} from "../../../functions";

import {useGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useGetCountiesQuery} from "../../../store/counties/counties.api";
import {useGetLocalitiesQuery} from "../../../store/localities/localities.api";
import {
  useCreateEmergencyMutation,
  useLazyGetEmergencyByIdQuery, useUpdateEmergencyMutation
} from "../../../store/emergencies/emergencies.api";
import {
  useCreateAffectedPopulationMutation,
  useLazyGetAffectedPopulationByIdQuery, useUpdateAffectedPopulationMutation
} from "../../../store/affectedPopulation/affectedPopulation.api";
import {
  useCreateEmergencyFileMutation, useDeleteEmergencyFileMutation, useLazyGetEmergencyFilesQuery
} from "../../../store/emergencyFiles/emergencyFiles.api";
import {useGetDivisionsQuery} from "../../../store/divisions/divisions.api";
import {useGetInfoUserQuery} from "../../../store/account/account.api";

interface ICurrentEsType {
  id: number
  name: string
  type: string
  value: number | string | Date | boolean | any[] | null,
  field_id?: string | number
}

type TSelectOptions = {
  label: string,
  value: string | number
};

interface IMapEventsComponent {
  handlerZoom: (zoom: number) => void;
  getPosition?: (event: any) => void;
}

const reactSelectControlStyles = (baseStyles: any, state: any): any => {
  return {
    ...baseStyles,
    '&:hover': {
      borderColor: state.isFocused ? '#998fed' : '#d8dbe0'
    },
    borderColor: state.isFocused ? '#998fed' : '#d8dbe0',
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(50, 31, 219, 0.25)' : 'unset',
    marginBottom: '5px'
  }
};

registerLocale('en', enUS);
registerLocale('ru', ru);
registerLocale('ky', ru);

const MapEventsComponent: React.FC<IMapEventsComponent> = ({getPosition}) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      mapEvents.getZoom();
    },
    click: (e) => {
      //@ts-ignore
      if (getPosition && e.type === 'click') {
        getPosition(e.latlng)

      }
    }
  });
  return null;
};

const NewEsReport = () => {
  const {t, i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [mainState, setMainState] = useState(initialMainState);
  const [currentEsType, setCurrentEsType] = useState<ICurrentEsType[]>([]);
  const [title, setTitle] = useState<string>('');
  const [filesState, setFilesState] = useState<any[]>([]);
  const [emergencyFilesState, setEmergencyFilesState] = useState<any[]>([]);
  const [filesForDelete, setFilesForDelete] = useState<number[]>([]);
  const [emergencyState, setEmergencyState] = useState(initialEmergencyState);
  const [affectedPopulationId, setAffectedPopulationId] = useState(null);
  const [firstLoading, setFirstLoading] = useState(false);
  const [titleManuallyChanged, setTitleManuallyChanged] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState<number>(0);
  const [coordinatesVisible, setCoordinatesVisible] = useState(false);
  const [coordinates, setCoordinates]: any = useState({lat: '', lng: ''});
  const [mapKey, setMapKey] = useState(0);
  const [center, setCenter] = useState(false);
  const [publishDisable, setPublishDisable] = useState<boolean>(true);
  const [saveAsDraftDisable, setSaveAsDraftDisable] = useState<boolean>(true);

  const {data: me} = useGetInfoUserQuery();
  const {data: responseEmergencyTypes, isLoading: emergencyTypesLoading} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 100000, sort_by: 'name', sort_direction: 'asc'}));
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: counties, isLoading: countiesLoading} = useGetCountiesQuery();
  const {data: locality, isLoading: localityLoading} = useGetLocalitiesQuery();
  const {data: divisions, isLoading: divisionsLoading} = useGetDivisionsQuery();
  const [createEmergency, {isLoading: createIsLoading}] = useCreateEmergencyMutation();
  const [updateEmergency, {isLoading: updateIsLoading}] = useUpdateEmergencyMutation();
  const [createAffectedPopulation, {isLoading: createAffectedIsLoading}] = useCreateAffectedPopulationMutation();
  const [createEmergencyFile, {isLoading: createFileIsLoading}] = useCreateEmergencyFileMutation();
  const [getEmergencyById, {data: emergencyById, isLoading: getEmergencyLoading}] = useLazyGetEmergencyByIdQuery();
  const [getAffectedPopulationById] = useLazyGetAffectedPopulationByIdQuery();
  const [updateAffectedPopulation, {isLoading: updateAffectedIsLoading}] = useUpdateAffectedPopulationMutation();
  const [fetchEmergencyFiles, {data: emergencyFiles}] = useLazyGetEmergencyFilesQuery();
  const [deleteEmergencyFile] = useDeleteEmergencyFileMutation();
  const [savingError, setSavingError] = useState<{[key: string]: string} | null>(null);

  const id = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const isLoading = useMemo(() => {
    return (emergencyTypesLoading || getEmergencyLoading || regionsLoading || districtsLoading || countiesLoading || localityLoading || divisionsLoading);
  }, [emergencyTypesLoading, getEmergencyLoading, regionsLoading, districtsLoading, countiesLoading, localityLoading, divisionsLoading]);

  const saveIsLoading = useMemo(() => {
    return (createIsLoading || updateIsLoading || createAffectedIsLoading || createFileIsLoading || updateAffectedIsLoading);
  }, [createIsLoading, updateIsLoading, createAffectedIsLoading, createFileIsLoading, updateAffectedIsLoading]);

  const myRegions = useMemo(() => {
    if (!me || !regions) return [];
    return regions.filter(region => me.district.map((d: any) => d.region).includes(region.id));
  }, [me, regions]);

  const myDistricts = useMemo(() => {
    if (!me || !districts) return [];
    return districts.filter(district => me.district.map((d: any) => d.id).includes(district.id));
  }, [me, districts]);

  const isMyReport = useMemo(() => {
    if (!id || id === "create") return true;
    const report = emergencyById?.properties;
    if (!me || !myRegions || !myDistricts) return false;
    if (report?.region && !myRegions.find(el => el.id === report.region)) return false;
    if (report?.district && !myDistricts.find(el => el.id === report.district)) return false;
    return true;
  }, [id, me, myRegions, myDistricts, emergencyById]);

  useEffect(() => {
    let array: any[] = []
    if (emergencyFiles?.length) {
      array = emergencyFiles.map((el: any) => {
        return {
          id: el.id,
          emergency: el.emergency,
          name: el.file.split('/')[el.file.split('/').length - 1],
          file: el.file
        }
      })
      setEmergencyFilesState(array)
    }
  }, [emergencyFiles]);

  useEffect(() => {
    if (!titleManuallyChanged) {
      let autoTitle = '';
      if (dayjs(mainState.date_emergency).format() !== 'Invalid Date') {
        autoTitle += (dayjs(mainState.date_emergency).format("YYYY") + '-жыл.' + dayjs(mainState.date_emergency).format('DD.MM'));
      }
      if (mainState.region) {
        autoTitle += (autoTitle ? ' - ' : '') + regions?.find(el => String(el.id) === mainState.region)?.name || '';
      }
      if (mainState.district) {
        autoTitle += (autoTitle ? ' - ' : '') + districts?.find(el => String(el.id) === mainState.district)?.name || '';
      }
      if (mainState.type_emergency) {
        const name = responseEmergencyTypes?.results?.find(el => el.id === mainState.type_emergency)?.name?.split(' / ')[0] || '';
        autoTitle += (autoTitle ? ' - ' : '') + name;
      }
      setTitle(autoTitle);
      if (autoTitle !== mainState.title) setMainState(prevState => ({...prevState, title: autoTitle}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainState]);

  useEffect(() => {
    let array: any[] = [], value: any, newArray: any[] = [];

    if (mainState.type_emergency !== 0 && responseEmergencyTypes?.results?.length && location.pathname.includes('/es-report/update/') && !firstLoading) {
      responseEmergencyTypes?.results?.find(el => el.id === mainState.type_emergency)?.field_type_emergency?.forEach(el => {
        switch (el.type) {
          case 'number':
            value = 0;
            break;
          case 'textarea':
          case 'string':
            value = '';
            break;
          case 'time':
          case 'date':
          case 'datetime':
            value = null;
            break;
          case 'boolean':
            value = false;
            break;
          case 'image':
            value = [];
            break;
          default:
          // do nothing
        }
        array.push({...el, value})
      })

      emergencyById?.properties?.field_emergency?.forEach((el: any) => {
        newArray.push({
          field_id: el.id,
          id: el.field_type_emergency.id,
          name: el.field_type_emergency.name,
          type: el.field_type_emergency.type,
          value: el.meaning
        })
      });

      newArray.forEach(el => {
        const field = array.find(elem => el.id === elem.id);
        if (field) {
          field.value = el.value === "false" ? false : el.value === "true" ? true : el.value;
          field.field_id = el.field_id;
        }
      });

      setCurrentEsType(array)
      setFirstLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainState, responseEmergencyTypes, id]);

  useEffect(() => {
    if (!isNaN(Number(id)) && location.pathname.includes('/es-report/update/') && responseEmergencyTypes && regions && districts) {
      getEmergencyById(id).then(res => {
        setMainState({
          type_emergency: res.data?.properties?.type_emergency?.id,
          type_report: res.data?.properties?.type_report,
          date_emergency: res.data?.properties?.date_emergency,
          region: String(res.data?.properties?.region),
          district: String(res.data?.properties?.district),
          county: String(res.data?.properties?.county),
          locality: String(res.data?.properties?.locality),
          title: res.data?.properties?.title,
          description: res.data?.properties?.description,
          material_damage: res.data?.properties?.material_damage,
          human_casualties: res.data?.properties?.human_casualties,
          number_of_victims: res.data?.properties?.number_of_victims,
          latitude: Number(res.data?.properties?.latitude),
          longitude: Number(res.data?.properties?.longitude),
          status: res.data?.properties?.status,
          late_information: res.data?.properties?.late_information,
          field_emergency: []
        });

        if (res.data?.properties?.division) {
          setSelectedDivision(res.data.properties.division);
        }

        if (res.data?.properties?.type_report === 'emergency') {
          getAffectedPopulationById(res.data.properties.id).then(result => {
            setAffectedPopulationId(result?.data?.id)
            if (result.data) {
              let data: any = {...emergencyState}
              Object.entries(result.data).forEach(elem => {
                Object.entries(emergencyState).forEach(el => {
                  if (elem[0] === el[0]) {
                    data[el[0]] = elem[1]
                  }
                })
              })
              setEmergencyState(data)
            }
          })
        }

        setTitle(res.data?.properties?.title);
        // setTitleManuallyChanged(true);
      })
      fetchEmergencyFiles(id);
    } else {
      setMainState(initialMainState);
      setSelectedDivision(0);
      setEmergencyState(initialEmergencyState);
      setCurrentEsType([]);
      setFilesState([]);
      setEmergencyFilesState([]);
      setFilesForDelete([]);
      setTitle('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isLoading]);

  useEffect(() => {
    let publishDenied = false, draftDenied = false;
    const requiredFields = currentEsType.filter(el => additionalFieldsWhoIsRequired.includes(el.id));
    const denied = (!mainState.type_emergency || !mainState.type_report || !mainState.date_emergency || !mainState.region || !mainState.district || !mainState.title || !mainState.description || !mainState.latitude || !mainState.longitude);

    if (requiredFields.some(el => !el.value) || denied) {
      publishDenied = true;
      draftDenied = true;
    }

    if (mainState.type_report === 'emergency' && !publishDenied) {
      if (Number(mainState.human_casualties) !== Number(emergencyState.qty_dead) ||
        emergencyState.qty_health_facilities !== (Number(emergencyState.total_number_of_outpatient_facilities_affected) + Number(emergencyState.total_number_of_affected_hospitals))) {
        publishDenied = true;
      }
    }

    setPublishDisable(publishDenied);
    setSaveAsDraftDisable(draftDenied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainState, emergencyState, currentEsType]);

  const isDivisionExists = useMemo(() => {
    if (mainState?.type_emergency) {
      return !!responseEmergencyTypes?.results?.find(esType => esType.id === mainState.type_emergency)?.division_exists;
    }
  }, [mainState, responseEmergencyTypes]);

  const emergencyTypes: IEmergencyType[] = useMemo(() => {
    return responseEmergencyTypes?.results || [];
  }, [responseEmergencyTypes]);

  const handleSelectEmergencyType = (e: any, name: string) => {
    let array: any[] = [], value: any, field_id: any;
    const emergencyFields = emergencyById?.properties?.field_emergency || null;
    // @ts-ignore
    responseEmergencyTypes?.results?.find(el => el.id === e?.value)?.field_type_emergency?.forEach(el => {
      const fieldTypeEmergency = emergencyFields?.find((field: any) => field?.field_type_emergency?.id === el.id);
      if (fieldTypeEmergency) {
        value = fieldTypeEmergency.meaning;
        field_id = fieldTypeEmergency.id;
      }
      switch (el.type) {
        case 'number':
          if (!value || isNaN(value)) value = 0;
          break;
        case 'textarea':
        case 'string':
          if (!value) value = '';
          break;
        case 'time':
        case 'date':
        case 'datetime':
          if (!value) value = null;
          break;
        case 'boolean':
          if (!value) value = false;
          break;
        case 'image':
          if (!value) value = [];
          break;
        default:
          // do nothing
      }
      array.push({...el, value, field_id});
    });
    setCurrentEsType(array);

    if (e !== null) {
      setMainState(prevState => ({...prevState, [name]: e.value}))
    } else {
      setMainState(prevState => ({...prevState, [name]: 0}))
    }
  };

  const handleSelect = (e: any, name: string) => {
    const innerState = {...mainState}

    if (name === 'region') {
      innerState.district = '';
      innerState.county = '';
      innerState.locality = '';
    }
    if (name === 'district') {
      innerState.county = '';
      innerState.locality = '';
    }
    if (name === 'county') {
      innerState.locality = '';
    }

    // @ts-ignore
    innerState[name] = (e !== null) ? e?.value : '';
    setMainState(innerState);
  };

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    if (name === 'status') {
      setMainState(prevState => {
        return {...prevState, [name]: mainState.status === 'in processing' ? 'completed' : 'in processing'}
      })
    } else if (name === 'late_information') {
      setMainState(prevState => {
        return {...prevState, [name]: !mainState.late_information}
      })
    } else {
      setMainState(prevState => {
        return {...prevState, [name]: value}
      })
    }
  };

  const setDivisionHandler = (e: any) => {
    setSelectedDivision(e?.value || 0);
  };

  const handleSelectDateTime = (date: any) => {
    setMainState(prevState => {
      return {...prevState, date_emergency: date}
    })
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;
    if (name === 'title') {
      setTitle(value);
      setTitleManuallyChanged(true);
    }
    setMainState(prevState => ({...prevState, [name]: value}));
  };

  const changeCustomTimeHandler = (value: string | null, type: string, id: number) => {
    if (type === 'time') {
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          return {...el, value: value}
        }
        return {...el}
      }));
    }
  };

  const handleCustom = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | Date>, type: string, id: number) => {
    if (type === 'boolean') {
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          return {...el, value: !el.value}
        }
        return {...el}
      }));
    } else if (type === 'date' || type === 'datetime') {
      // @ts-ignore
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          // @ts-ignore
          return {...el, value: e}
        }
        return {...el}
      }));
    } else if (type === 'image') {
      // @ts-ignore
      setCurrentEsType(prevState => prevState.map(el => {
        if (el.id === id) {
          // @ts-ignore
          let arr = [...el.value]

          // @ts-ignore
          const chosenFiles = Array.prototype.slice.call(e.target.files)
          chosenFiles.forEach(elem => {
            if (!arr.some(el => el.name === elem.name)) {
              arr.push(elem);
            }
          })
          return {...el, value: arr}
        }
        return {...el}
      }))
    } else {
      setCurrentEsType(prevState => prevState.map((el) => {
        if (el.id === id) {
          // @ts-ignore
          return {...el, value: e.target.value}
        }
        return {...el}
      }));
    }
  };

  const handleDeleteCustomPicture = (e: React.MouseEvent<HTMLDivElement>, name: string, id: number) => {
    let array = currentEsType.find(el => el.id === id)?.value!;
    // @ts-ignore
    if (array.length) {
      let index;
      // @ts-ignore
      index = array.findIndex(el => el.name === name);
      // @ts-ignore
      array.splice(index, 1);
    }
    setCurrentEsType(prevState => prevState.map((el) => {
      if (el.id === id) {
        return {...el, value: array};
      }
      return {...el};
    }))
  };

  const handleChangeEmergency = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    const state = {...emergencyState, [name]: Number(value)};

    if (name === 'zero_five_men_dead' ||
      name === 'six_seventeen_men_dead' ||
      name === 'eighteen_fifty_nine_men_dead' ||
      name === 'sixty_and_older_men_dead' ||
      name === 'zero_five_women_dead' ||
      name === 'six_seventeen_women_dead' ||
      name === 'eighteen_fifty_nine_women_dead' ||
      name === 'sixty_and_older_women_dead' ||
      name === 'zero_five_men_missing' ||
      name === 'six_seventeen_men_missing' ||
      name === 'eighteen_fifty_nine_men_missing' ||
      name === 'sixty_and_older_men_missing' ||
      name === 'zero_five_women_missing' ||
      name === 'six_seventeen_women_missing' ||
      name === 'eighteen_fifty_nine_women_missing' ||
      name === 'sixty_and_older_women_missing') {
      state.men_dead = state.zero_five_men_dead + state.six_seventeen_men_dead + state.eighteen_fifty_nine_men_dead + state.sixty_and_older_men_dead;
      state.women_dead = state.zero_five_women_dead + state.six_seventeen_women_dead + state.eighteen_fifty_nine_women_dead + state.sixty_and_older_women_dead;
      state.qty_dead = state.men_dead + state.women_dead;
      state.men_missing = state.zero_five_men_missing + state.six_seventeen_men_missing + state.eighteen_fifty_nine_men_missing + state.sixty_and_older_men_missing;
      state.women_missing = state.zero_five_women_missing + state.six_seventeen_women_missing + state.eighteen_fifty_nine_women_missing + state.sixty_and_older_women_missing;
      state.qty_missing = state.men_missing + state.women_missing;
      state.zero_five_men_dead_and_missing = state.zero_five_men_dead + state.zero_five_men_missing;
      state.six_seventeen_men_dead_and_missing = state.six_seventeen_men_dead + state.six_seventeen_men_missing;
      state.eighteen_fifty_nine_men_dead_and_missing = state.eighteen_fifty_nine_men_dead + state.eighteen_fifty_nine_men_missing;
      state.sixty_and_older_men_dead_and_missing = state.sixty_and_older_men_dead + state.sixty_and_older_men_missing;
      state.zero_five_women_dead_and_missing = state.zero_five_women_dead + state.zero_five_women_missing;
      state.six_seventeen_women_dead_and_missing = state.six_seventeen_women_dead + state.six_seventeen_women_missing;
      state.eighteen_fifty_nine_women_dead_and_missing = state.eighteen_fifty_nine_women_dead + state.eighteen_fifty_nine_women_missing;
      state.sixty_and_older_women_dead_and_missing = state.sixty_and_older_women_dead + state.sixty_and_older_women_missing;
      state.men_dead_and_missing = state.zero_five_men_dead_and_missing + state.six_seventeen_men_dead_and_missing + state.eighteen_fifty_nine_men_dead_and_missing + state.sixty_and_older_men_dead_and_missing;
      state.women_dead_and_missing = state.zero_five_women_dead_and_missing + state.six_seventeen_women_dead_and_missing + state.eighteen_fifty_nine_women_dead_and_missing + state.sixty_and_older_women_dead_and_missing;
      state.qty_dead_and_missing = state.men_dead_and_missing + state.women_dead_and_missing;
    } else if (name === 'zero_five_men_affected' ||
      name === 'six_seventeen_men_affected' ||
      name === 'eighteen_fifty_nine_men_affected' ||
      name === 'sixty_and_older_men_affected' ||
      name === 'zero_five_women_affected' ||
      name === 'six_seventeen_women_affected' ||
      name === 'eighteen_fifty_nine_women_affected' ||
      name === 'sixty_and_older_women_affected') {
      state.men_affected = state.zero_five_men_affected + state.six_seventeen_men_affected + state.eighteen_fifty_nine_men_affected + state.sixty_and_older_men_affected;
      state.women_affected = state.zero_five_women_affected + state.six_seventeen_women_affected + state.eighteen_fifty_nine_women_affected + state.sixty_and_older_women_affected;
      state.qty_affected = state.men_affected + state.women_affected;
    } else if (name === 'zero_five_men_injured_but_not_hospitalized' ||
      name === 'six_seventeen_men_injured_but_not_hospitalized' ||
      name === 'eighteen_fifty_nine_men_injured_but_not_hospitalized' ||
      name === 'sixty_and_older_men_injured_but_not_hospitalized' ||
      name === 'zero_five_women_injured_but_not_hospitalized' ||
      name === 'six_seventeen_women_injured_but_not_hospitalized' ||
      name === 'eighteen_fifty_nine_women_injured_but_not_hospitalized' ||
      name === 'sixty_and_older_women_injured_but_not_hospitalized') {
      state.men_injured_but_not_hospitalized = state.zero_five_men_injured_but_not_hospitalized + state.six_seventeen_men_injured_but_not_hospitalized + state.eighteen_fifty_nine_men_injured_but_not_hospitalized + state.sixty_and_older_men_injured_but_not_hospitalized;
      state.women_injured_but_not_hospitalized = state.zero_five_women_injured_but_not_hospitalized + state.six_seventeen_women_injured_but_not_hospitalized + state.eighteen_fifty_nine_women_injured_but_not_hospitalized + state.sixty_and_older_women_injured_but_not_hospitalized;
      state.qty_injured_but_not_hospitalized = state.men_injured_but_not_hospitalized + state.women_injured_but_not_hospitalized;
    } else if (name === 'zero_five_men_injured_hospitalized' ||
      name === 'six_seventeen_men_injured_hospitalized' ||
      name === 'eighteen_fifty_nine_men_injured_hospitalized' ||
      name === 'sixty_and_older_men_injured_hospitalized' ||
      name === 'zero_five_women_injured_hospitalized' ||
      name === 'six_seventeen_women_injured_hospitalized' ||
      name === 'eighteen_fifty_nine_women_injured_hospitalized' ||
      name === 'sixty_and_older_women_injured_hospitalized') {
      state.men_injured_hospitalized = state.zero_five_men_injured_hospitalized + state.six_seventeen_men_injured_hospitalized + state.eighteen_fifty_nine_men_injured_hospitalized + state.sixty_and_older_men_injured_hospitalized;
      state.women_injured_hospitalized = state.zero_five_women_injured_hospitalized + state.six_seventeen_women_injured_hospitalized + state.eighteen_fifty_nine_women_injured_hospitalized + state.sixty_and_older_women_injured_hospitalized;
      state.qty_injured_hospitalized = state.men_injured_hospitalized + state.women_injured_hospitalized;
    } else if (name === 'zero_five_men_who_left_their_place_of_residence' ||
      name === 'six_seventeen_men_who_left_their_place_of_residence' ||
      name === 'eighteen_fifty_nine_men_who_left_their_place_of_residence' ||
      name === 'sixty_and_older_men_who_left_their_place_of_residence' ||
      name === 'zero_five_women_who_left_their_place_of_residence' ||
      name === 'six_seventeen_women_who_left_their_place_of_residence' ||
      name === 'eighteen_fifty_nine_women_who_left_their_place_of_residence' ||
      name === 'sixty_and_older_women_who_left_their_place_of_residence') {
      state.men_who_left_their_place_of_residence = state.zero_five_men_who_left_their_place_of_residence + state.six_seventeen_men_who_left_their_place_of_residence + state.eighteen_fifty_nine_men_who_left_their_place_of_residence + state.sixty_and_older_men_who_left_their_place_of_residence;
      state.women_who_left_their_place_of_residence = state.zero_five_women_who_left_their_place_of_residence + state.six_seventeen_women_who_left_their_place_of_residence + state.eighteen_fifty_nine_women_who_left_their_place_of_residence + state.sixty_and_older_women_who_left_their_place_of_residence;
      state.qty_who_left_their_place_of_residence = state.men_who_left_their_place_of_residence + state.women_who_left_their_place_of_residence;
    } else if (name === 'zero_five_men_temporarily_left_the_place_of_residence' ||
      name === 'six_seventeen_men_temporarily_left_the_place_of_residence' ||
      name === 'eighteen_fifty_nine_men_temporarily_left_the_place_of_residence' ||
      name === 'sixty_and_older_men_who_temporarily_left_the_place_of_residence' ||
      name === 'zero_five_women_temporarily_left_the_place_of_residence' ||
      name === 'six_seventeen_women_temporarily_left_the_place_of_residence' ||
      name === 'eighteen_fifty_nine_women_temporarily_left_the_place_of_residence' ||
      name === 'sixty_and_older_women_who_temporarily_left_the_place_of_residence') {
      state.men_temporarily_left_the_place_of_residence = state.zero_five_men_temporarily_left_the_place_of_residence + state.six_seventeen_men_temporarily_left_the_place_of_residence + state.eighteen_fifty_nine_men_temporarily_left_the_place_of_residence + state.sixty_and_older_men_who_temporarily_left_the_place_of_residence;
      state.women_temporarily_left_the_place_of_residence = state.zero_five_women_temporarily_left_the_place_of_residence + state.six_seventeen_women_temporarily_left_the_place_of_residence + state.eighteen_fifty_nine_women_temporarily_left_the_place_of_residence + state.sixty_and_older_women_who_temporarily_left_the_place_of_residence;
      state.qty_temporarily_left_the_place_of_residence = state.men_temporarily_left_the_place_of_residence + state.women_temporarily_left_the_place_of_residence;
    } else if (name === 'zero_five_men_resettled' ||
      name === 'six_seventeen_men_resettled' ||
      name === 'eighteen_fifty_nine_men_resettled' ||
      name === 'sixty_and_older_men_who_resettled' ||
      name === 'zero_five_women_resettled' ||
      name === 'six_seventeen_women_resettled' ||
      name === 'eighteen_fifty_nine_women_resettled' ||
      name === 'sixty_and_older_women_who_resettled') {
      state.men_resettled = state.zero_five_men_resettled + state.six_seventeen_men_resettled + state.eighteen_fifty_nine_men_resettled + state.sixty_and_older_men_who_resettled;
      state.women_resettled = state.zero_five_women_resettled + state.six_seventeen_women_resettled + state.eighteen_fifty_nine_women_resettled + state.sixty_and_older_women_who_resettled;
      state.qty_resettled = state.men_resettled + state.women_resettled;
    } else if (name === 'first_third_degre_of_damage_individual_houses' ||
      name === 'fourth_fifth_degre_of_damage_individual_houses' ||
      name === 'first_third_degre_of_damage_apartment_buildings' ||
      name === 'fourth_fifth_degre_of_damage_apartment_buildings') {
      state.qty_individual_houses = state.first_third_degre_of_damage_individual_houses + state.fourth_fifth_degre_of_damage_individual_houses;
      state.qty_apartment_buildings = state.first_third_degre_of_damage_apartment_buildings + state.fourth_fifth_degre_of_damage_apartment_buildings;
      state.qty_damaged_houses = state.qty_individual_houses + state.qty_apartment_buildings;
    } else if (name === 'first_third_degre_of_damage_health_facilities' ||
      name === 'fourth_fifth_degre_of_damage_health_facilities') {
      state.qty_health_facilities = state.first_third_degre_of_damage_health_facilities + state.fourth_fifth_degre_of_damage_health_facilities;
    } else if (name === 'first_third_degre_of_damage_preschool' ||
      name === 'fourth_fifth_degre_of_damage_preschool' ||
      name === 'first_third_degre_of_damage_general_educational_institutions' ||
      name === 'fourth_fifth_degre_of_damage_general_educational_institutions' ||
      name === 'first_third_degre_of_damage_secondary_special_and_higher_educational_institutions' ||
      name === 'fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions') {
      state.preschool = state.first_third_degre_of_damage_preschool + state.fourth_fifth_degre_of_damage_preschool;
      state.general_educational_institutions = state.first_third_degre_of_damage_general_educational_institutions + state.fourth_fifth_degre_of_damage_general_educational_institutions;
      state.secondary_special_and_higher_educational_institutions = state.first_third_degre_of_damage_secondary_special_and_higher_educational_institutions + state.fourth_fifth_degre_of_damage_secondary_special_and_higher_educational_institutions;
      state.qty_objects_of_education = state.preschool + state.general_educational_institutions + state.secondary_special_and_higher_educational_institutions;
    } else if (name === 'first_third_degre_of_damage_cultural_objects' ||
      name === 'fourth_fifth_degre_of_damage_cultural_objects') {
      state.qty_cultural_objects = state.first_third_degre_of_damage_cultural_objects + state.fourth_fifth_degre_of_damage_cultural_objects;
    } else if (name === 'roads_of_republican_significance' ||
      name === 'on_farm_roads' ||
      name === 'bridges_on_national_roads' ||
      name === 'bridges_on_roads_of_on_farm_significance' ||
      name === 'railways' ||
      name === 'tunnels_galleries') {
      state.qty_road_facilities = state.roads_of_republican_significance + state.on_farm_roads + state.bridges_on_national_roads + state.bridges_on_roads_of_on_farm_significance + state.railways + state.tunnels_galleries;
    } else if (name === 'power_stations' ||
      name === 'transformer_substations' ||
      name === 'power_transmission_towers' ||
      name === 'power_lines') {
      state.energy_facilities = state.power_stations + state.transformer_substations + state.power_transmission_towers + state.power_lines;
    } else if (name === 'communication_lines' ||
      name === 'communication_line_poles' ||
      name === 'equipment_and_devices') {
      state.communication_objects = state.communication_lines + state.communication_line_poles + state.equipment_and_devices;
    } else if (name === 'hydroelectric_power_plant' ||
      name === 'dam' ||
      name === 'reservoir' ||
      name === 'other') {
      state.hydraulic_structures = state.hydroelectric_power_plant + state.dam + state.reservoir + state.other;
    } else if (name === 'water_intake_facilities_for_drinking_purposes' ||
      name === 'water_lines' ||
      name === 'sewer_lines' ||
      name === 'gas_distribution_stations' ||
      name === 'gas_pipelines' ||
      name === 'boiler_houses' ||
      name === 'heat_pipes' ||
      name === 'treatment_facilities') {
      state.utilities_facilities = state.water_intake_facilities_for_drinking_purposes + state.water_lines + state.sewer_lines + state.gas_distribution_stations + state.gas_pipelines + state.boiler_houses + state.heat_pipes + state.treatment_facilities;
    } else if (name === 'water_intake_facilities_for_irrigation_purposes' ||
      name === 'water_distribution_facilities' ||
      name === 'reservoirs_irrigation' ||
      name === 'irrigation_networks_of_on_farm_significance_destroyed' ||
      name === 'destruction_of_irrigation_networks_of_off_farm_significance' ||
      name === 'destroyed_collector_drainage_networks') {
      state.irrigation_objects = state.water_intake_facilities_for_irrigation_purposes + state.water_distribution_facilities + state.reservoirs_irrigation + state.irrigation_networks_of_on_farm_significance_destroyed + state.destruction_of_irrigation_networks_of_off_farm_significance + state.destroyed_collector_drainage_networks;
    } else if (name === 'state_owned_industrial' ||
      name === 'in_municipal_ownership_industrial' ||
      name === 'in_private_ownership_industrial') {
      state.industrial_and_commercial_facilities = state.state_owned_industrial + state.in_municipal_ownership_industrial + state.in_private_ownership_industrial;
    } else if (name === 'state_owned_devices' ||
      name === 'in_municipal_ownership_devices' ||
      name === 'in_private_ownership_devices') {
      state.the_number_of_damaged_machinery_mobile_devices_and_equipment = state.state_owned_devices + state.in_municipal_ownership_devices + state.in_private_ownership_devices;
    } else if (name === 'irrigated_arable_land' ||
      name === 'non_irrigated_arable_land' ||
      name === 'pastures' ||
      name === 'forest_land') {
      state.agriculture_and_forestry = state.irrigated_arable_land + state.non_irrigated_arable_land + state.pastures + state.forest_land;
    } else if (name === 'cereals' ||
      name === 'roots' ||
      name === 'cotton' ||
      name === 'vegetables' ||
      name === 'beans_and_other_legumes' ||
      name === 'perennial_plantations') {
      state.total_area_of_dead_crops = state.cereals + state.roots + state.cotton + state.vegetables + state.beans_and_other_legumes + state.perennial_plantations;
    } else if (name === 'cattle' ||
      name === 'mrs' ||
      name === 'horses' ||
      name === 'birds') {
      state.total_number_of_dead_livestock_and_poultry = state.cattle + state.mrs + state.horses + state.birds;
    } else if (name === 'first_third_degre_of_damage_administrative_buildings' ||
      name === 'fourth_fifth_degre_of_damage_secondary_administrative_buildings') {
      state.qty_administrative_buildings = state.first_third_degre_of_damage_administrative_buildings + state.fourth_fifth_degre_of_damage_secondary_administrative_buildings;
    } else if (name === 'first_third_degre_of_damage_social_stationary_institutions' ||
      name === 'fourth_fifth_degre_of_damage_social_stationary_institutions' ||
      name === 'first_third_degre_of_damage_semi_permanent_institutions' ||
      name === 'fourth_fifth_degre_of_damage_semi_permanent_institutions') {
      state.social_stationary_institutions = state.first_third_degre_of_damage_social_stationary_institutions + state.fourth_fifth_degre_of_damage_social_stationary_institutions;
      state.semi_permanent_institutions = state.first_third_degre_of_damage_semi_permanent_institutions + state.fourth_fifth_degre_of_damage_semi_permanent_institutions;
      state.objects_of_social_protection = state.social_stationary_institutions + state.semi_permanent_institutions;
    } else if (name === 'budgetary_funds_of_the_ministry_of_emergency_situations' ||
      name === 'donor_funds') {
      state.the_total_amount_of_funds_allocated_to_eliminate_the_consequences_of_an_emergency = state.budgetary_funds_of_the_ministry_of_emergency_situations + state.donor_funds;
    } else if (name === 'budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services' ||
      name === 'donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services' ||
      name === 'budget_resources_resettlement_of_the_population_to_safe_areas' ||
      name === 'donor_funds_resettlement_of_the_population_to_safe_areas' ||
      name === 'budget_resources_restoration_of_the_vital_activity_of_the_economy' ||
      name === 'donor_funds_restoration_of_the_vital_activity_of_the_economy') {
      state.restoration_of_management_functions_and_provision_of_state_and_municipal_services = state.budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services;
      state.resettlement_of_the_population_to_safe_areas = state.budget_resources_resettlement_of_the_population_to_safe_areas + state.donor_funds_resettlement_of_the_population_to_safe_areas;
      state.restoration_of_the_vital_activity_of_the_economy = state.budget_resources_restoration_of_the_vital_activity_of_the_economy + state.donor_funds_restoration_of_the_vital_activity_of_the_economy;
      state.the_total_amount_of_funds_allocated_for_restoration_by_funding_sources = state.restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.resettlement_of_the_population_to_safe_areas + state.restoration_of_the_vital_activity_of_the_economy;
      state.budget_resources_allocated_for_restoration_by_funding_sources = state.budget_resources_restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.budget_resources_resettlement_of_the_population_to_safe_areas + state.budget_resources_restoration_of_the_vital_activity_of_the_economy;
      state.donor_funds_allocated_for_restoration_by_funding_sources = state.donor_funds_restoration_of_management_functions_and_provision_of_state_and_municipal_services + state.donor_funds_resettlement_of_the_population_to_safe_areas + state.donor_funds_restoration_of_the_vital_activity_of_the_economy;
    }

    setEmergencyState(state);
  }

  const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    let tempFiles = [...filesState];

    if (e.target.type === 'file') {
      const chosenFiles = Array.prototype.slice.call(files);
      chosenFiles.forEach(el => {
        if (!tempFiles.find(item => item.name === el.name)) {
          tempFiles.push(el);
        }
      });

      setFilesState(tempFiles);
    }
  };

  const handleDeleteFile = (name: string) => {
    let arr = [...filesState];
    let index = arr.findIndex(el => el.name === name);
    arr.splice(index, 1);
    setFilesState(arr);
  };

  const handleDeleteEmergencyFile = (id: number) => {
    let arr = [...emergencyFilesState];
    let idsArr = [...filesForDelete];
    let idx = arr.findIndex(el => el.id === id);
    arr.splice(idx, 1);

    if (!idsArr.includes(id)) {
      idsArr.push(id);
    }

    setEmergencyFilesState(arr);
    setFilesForDelete(idsArr);
  };

  const handleGetPosition = (e: any) => {
    setMainState(prevState => {
      return {
        ...prevState,
        latitude: e.lat,
        longitude: e.lng,
        location: {
          type: "Point",
          coordinates: [
            e.lng,
            e.lat
          ]
        }
      }
    });
  };

  const handleCreateEmergency = (asDraft?: boolean) => {
    setSavingError(null);

    if (!mainState.latitude || !mainState.longitude) {
      setSavingError({location: 'Метка не указана'});
      return null;
    }

    let data: {[key: string]: any} = {};

    let arr: any[] = [];
    currentEsType.forEach(el => {
      if (el.type !== 'image' && el.id && el.value) {
        arr.push({field_type_emergency: el.id, meaning: String(el.value)});
      }
    });

    Object.entries(mainState).forEach(el => {
      if (String(el[1])) {
        data[el[0]] = el[1];
      }
    });

    if (selectedDivision) data.division = selectedDivision;
    if (asDraft !== undefined) data.is_draft = asDraft;

    arr.length ? data.field_emergency = arr : delete data.field_emergency;
    data.title = title;
    data.latitude = mainState.latitude?.toString(10);
    data.longitude = mainState.longitude?.toString(10);
    data.late_information = mainState.late_information;
    data.date_emergency = dayjs(mainState.date_emergency).format("YYYY-MM-DDTHH:mm:ss");

    createEmergency(data).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }

      let additionalData = {...emergencyState};
      const promises = [];

      if (res?.data && mainState.type_report === 'emergency') {
        additionalData.emergency = res.data.id;
        promises.push(createAffectedPopulation(additionalData));
      }

      if (res?.data) {
        filesState.forEach(el => {
          const formData = new FormData();
          formData.append('emergency', res.data.id);
          formData.append('file', el);
          promises.push(createEmergencyFile(formData));
          // TODO: Сделать процесс загрузки файлов
        });
      }

      return Promise.all(promises);
    }).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }
      navigate({pathname: asDraft ? '/es-base-drafts' : '/es-feed'});
    }).catch(() => {
      console.log("Something went wrong...");
    });
  }

  const handleUpdateEmergency = (asDraft?: boolean) => {
    setSavingError(null);
    let data: {[key: string]: any} = {};

    let fieldEmergency: any[] = [], fieldsForRemove: any[] = [], uniqueFieldTypeEmergencyIds: any[] = [];
    const originalFieldEmergency = emergencyById?.properties?.field_emergency || [];

    currentEsType.forEach(el => {
      if (el.type !== 'image' && el.value) {
        fieldEmergency.push({field_id: el.field_id || '', emergency: Number(id), field_type_emergency: el.id, meaning: String(el.value)});
      } else if (el.field_id) {
        uniqueFieldTypeEmergencyIds.push(Number(el.id));
      }
    });

    originalFieldEmergency.forEach((el: any) => {
      if (uniqueFieldTypeEmergencyIds.includes(el.field_type_emergency?.id)) fieldsForRemove.push(el.id);
      else if (el.field_type_emergency?.id) uniqueFieldTypeEmergencyIds.push(el.field_type_emergency.id);
    });

    Object.entries(mainState).forEach(el => {
      if (String(el[1]) && el[1] !== "null") {
        if ((el[0] === 'region' || el[0] === 'district' || el[0] === 'county' || el[0] === 'locality')) {
          data[el[0]] = Number(el[1]);
        } else {
          data[el[0]] = el[1];
        }
      }
    });

    if (selectedDivision) data.division = selectedDivision;
    if (asDraft !== undefined) data.is_draft = asDraft;

    data.field_emergency = fieldEmergency;
    data.title = title;
    data.latitude = mainState.latitude?.toString(10);
    data.longitude = mainState.longitude?.toString(10);
    data.late_information = mainState.late_information;
    data.date_emergency = dayjs(mainState.date_emergency).format("YYYY-MM-DDTHH:mm:ss");

    if (fieldsForRemove.length) data.delete_field_emergency = fieldsForRemove;

    updateEmergency({body: data, id}).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }

      const additionalData = {...emergencyState};
      const promises = [];

      if (res?.data && mainState.type_report === 'emergency') {
        additionalData.emergency = res.data.id;
        if (!affectedPopulationId) {
          promises.push(createAffectedPopulation(additionalData));
        } else {
          promises.push(updateAffectedPopulation({body: additionalData, id: affectedPopulationId}));
        }
      }

      if (res?.data) {
        filesState.forEach(el => {
          const formData = new FormData();
          formData.append('emergency', res.data.id);
          formData.append('file', el);
          promises.push(createEmergencyFile(formData));
          // TODO: Сделать процесс загрузки файлов
        })
      }

      if (filesForDelete.length) {
        filesForDelete.forEach(el => {
          promises.push(deleteEmergencyFile(el));
        })
      }

      return Promise.all(promises);
    }).then((res: any) => {
      if (res?.error?.data) {
        setSavingError(res.error.data);
        return res;
      }
      setAffectedPopulationId(null);
      navigate({pathname: asDraft ? '/es-base-drafts' : '/es-feed'});
    }).catch(() => {
      console.log("Something went wrong...");
    });
  }

  let emergencyTypesOptions: TSelectOptions[] = [],
    regionsOptions: TSelectOptions[] = [],
    districtsOptions: TSelectOptions[] = [],
    countiesOptions: TSelectOptions[] = [],
    localityOptions: TSelectOptions[] = [],
    divisionsOptions: TSelectOptions[] = [];

  if (emergencyTypes?.length) {
    const list = emergencyTypes.map(el => {
      const res = { label: el.name, value: el.id };
      if (i18n.language === 'ru') res.label = res.label.split(' / ').reverse().join(' / ');
      return res;
    });
    emergencyTypesOptions = sortArrayOfObjectsByKey(list, 'label');
  }

  if (myRegions?.length) {
    regionsOptions = myRegions.map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (myDistricts?.length) {
    districtsOptions = myDistricts.filter(el => String(el.region) === mainState.region).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (counties?.length) {
    countiesOptions = counties.filter(el => String(el.district) === mainState.district).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (locality?.length) {
    localityOptions = locality.filter(el => String(el.county) === mainState.county).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    })
  }

  if (divisions?.length) {
    divisionsOptions = divisions.map(el => {
      return {label: el.name, value: el.id}
    });
    divisionsOptions.unshift({label: '', value: 0});
  }

  let layersControl = true;
  let zoomControl = true;
  let scaleControl = true;
  let loading = false;

  let position = [mainState.latitude, mainState.longitude], icon;

  let iconUrl = '';
  if (mainState.type_emergency !== 0 && responseEmergencyTypes?.results?.length) {
    // @ts-ignore
    iconUrl = responseEmergencyTypes?.results?.find((el: any) => el.id === mainState.type_emergency)?.geo_icon?.icon;
    if (iconUrl) {
      icon = new Leaflet.Icon({
        iconUrl: iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
        popupAnchor: [0, -32],
      })
    }
  }

  const handleVisible = (e: any, name: string) => {
    e.preventDefault();
    if (name === 'coordinates') {
      setCoordinatesVisible(!coordinatesVisible);
    } else {
      return;
    }
  };

  const handleInputChange = (event: any) => {
    const {name, value} = event.target;
    setCoordinates((prevCoordinates: any) => ({
      ...prevCoordinates,
      [name]: parseFloat(value) || ''
    }));
  };

  const searchCoordinates = (e: any) => {
    e.preventDefault();
    setMapKey(prevKey => prevKey + 1);
    setCenter(true);
  };

  const onHumanCasualtiesBlurHandler = () => {
    if (Number(mainState.human_casualties) > 4) setMainState(prev => ({...prev, type_report: 'emergency'}));
  };

  const additionalFieldsWhoIsRequired = useMemo(() => {
    // ["Кабыл алуу убактысы", "Чыгуу убактысы", "Жетүү убактысы", "Келүү сааты", "Чыгуу сааты", "Жетүү сааты"]

    if (config.baseUrl.includes('iamsui.yorc.org')) {
      return [3181, 3190, 3192, 3172, 3183, 3188, 2956, 2967, 2973, 3167, 3145, 3155];
    }
    if (config.baseUrl.includes('ais.mes.gov.kg')) {
      return [391, 517, 564, 397, 497, 547, 367, 510, 550, 561, 452, 506];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainState.type_emergency]);

  const fieldsDescriptionWhoIsRequired = useMemo(() => {
    const requiredFields = currentEsType.filter(el => additionalFieldsWhoIsRequired.includes(el.id));
    const fieldNames = [];
    if (!mainState.type_emergency) fieldNames.push(t("settings.esTypes.emergencyType"));
    if (!mainState.type_report) fieldNames.push(t("emergency.determineTheTypeOfReport"));
    if (!mainState.date_emergency) fieldNames.push(t("emergency.dateOfTheMessage"));
    if (!mainState.region) fieldNames.push(t("gis.geoObjects.area"));
    if (!mainState.district) fieldNames.push(t("gis.geoObjects.district"));
    if (!mainState.title) fieldNames.push(t("emergency.title"));
    if (!mainState.description) fieldNames.push(t("emergency.description"));
    if (!mainState.latitude || !mainState.longitude) fieldNames.push(t("emergency.location"));
    requiredFields.forEach(el => {
      if (!el.value) fieldNames.push(el.name);
    });
    return fieldNames.join(', ');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainState, currentEsType]);

  const renderCoordinate = (
      <div className="map-opacity-block">
        <p className="map-opacity-title">{t('gis.geoObjects.coordinates')}:</p>
        <div style={{marginBottom: '10px'}}>
          <label>
            {t("gis.geoObjects.latitude")}
            <input
                type="number"
                name="lat"
                value={coordinates.lat}
                onChange={handleInputChange}
            />
          </label>
          <label>
            {t("gis.geoObjects.longitude")}
            <input
                type="number"
                name="lng"
                value={coordinates.lng}
                onChange={handleInputChange}
            />
          </label>
        </div>
        <CButton
            type="button"
            size="sm"
            className="bg-info"
            color="black"
            onClick={e => searchCoordinates(e)}
            disabled={!(coordinates.lat && coordinates.lng)}
        >
          {t("pagination.search")}
        </CButton>
      </div>
  );

  const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];

  if (!isMyReport) {
    if (isLoading) return null;
    return (
      <div className="pb-3 mb-4 title-divider">
        <h1>403: Forbidden</h1>
        <h4>{t('You do not have permissions to edit this entry')}</h4>
      </div>
    );
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{location.pathname.includes('/es-report/update') ? t('nav.Update ES report') : t('nav.New ES report')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <CForm>
        <div className={'mb-3'}>
          <CFormLabel>
            {t("settings.esTypes.emergencyType")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <Select options={emergencyTypesOptions}
                  value={emergencyTypesOptions?.find(el => el?.value === mainState.type_emergency) || null}
                  styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelectEmergencyType(e, 'type_emergency')}
                  isClearable
          />
          {(savingError?.type_emergency && !mainState.type_emergency) &&
            <span style={{color: 'red'}}>{savingError.type_emergency}</span>}
        </div>

        <div className={'mb-3'}>
          <CFormLabel>
            {t("emergency.determineTheTypeOfReport")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <CFormCheck type="radio"
                      name="type_report"
                      id="exampleRadios1"
                      value="emergency"
                      label={t("emergency.emergency")}
                      checked={mainState.type_report === 'emergency'}
                      onChange={handleChecked}
          />
          <CFormCheck type="radio"
                      name="type_report"
                      id="exampleRadios2"
                      value="incident"
                      label={t("emergency.incident")}
                      checked={mainState.type_report === 'incident'}
                      onChange={handleChecked}
          />
          <CFormCheck type="radio"
                      name="type_report"
                      id="exampleRadios3"
                      value="other"
                      label={t("emergency.other")}
                      checked={mainState.type_report === 'other'}
                      onChange={handleChecked}
          />
          {(savingError && !mainState.type_report) && <span style={{color: 'red'}}>{t('Required field')}</span>}
        </div>

        <div style={{height: '50px'}} className="mb-4">
          <div className="react-date-picker-wrapper">
            <CFormLabel>
              {t("emergency.dateOfTheMessage")}
              <span style={{color: 'red'}}>&nbsp;*</span>
            </CFormLabel>
            <DatePicker onChange={handleSelectDateTime}
                        showTimeInput
                        selected={mainState.date_emergency}
                        dateFormat={'dd.MM.yyyy H:mm'}
                        locale={i18n.language}
                        className={"ml-5"}
            />
          </div>
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("gis.geoObjects.area")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <Select options={regionsOptions}
                  value={regionsOptions?.find(el => el.value === mainState.region) || ''}
                  styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'region')}
                  isClearable
          />
          {(savingError?.region && !mainState.region) && <span style={{color: 'red'}}>{savingError.region}</span>}
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("gis.geoObjects.district")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <Select options={districtsOptions}
                  value={districtsOptions?.find(el => String(el.value) === mainState.district) || ''}
                  styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'district')}
                  isClearable
                  isDisabled={!mainState.region || !districtsOptions?.length}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>{t("gis.geoObjects.county")}</CFormLabel>
          <Select options={countiesOptions}
                  value={countiesOptions?.find(el => el.value === mainState.county) || ''}
                  styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'county')}
                  isClearable
                  isDisabled={!mainState.district || !countiesOptions?.length}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>{t("gis.geoObjects.village")}</CFormLabel>
          <Select options={localityOptions}
                  value={localityOptions?.find(el => el.value === mainState.locality) || ''}
                  styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                  placeholder={t('Nothing is selected')}
                  onChange={e => handleSelect(e, 'locality')}
                  isClearable
                  isDisabled={!mainState.county || !localityOptions?.length}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("emergency.title")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <CFormInput type="text"
                      name={'title'}
                      value={title}
                      onChange={handleChange}
                      className={'iams-default-form-control'}
          />
          {(savingError?.title && !title) && <span style={{color: 'red'}}>{savingError.title}</span>}
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("emergency.description")}
            <span style={{color: 'red'}}>&nbsp;*</span>
          </CFormLabel>
          <CFormTextarea name={'description'}
                         value={mainState.description}
                         onChange={handleChange}
                         className={'emergency-custom-textarea iams-default-form-control'}
          />
          {(savingError?.description && !mainState.description) &&
            <span style={{color: 'red'}}>{savingError.description}</span>}
        </div>

        <div className="mb-3">
          <CFormInput type="number"
                      label={t("emergency.materialDamage")}
                      name={'material_damage'}
                      value={mainState.material_damage}
                      onChange={handleChange}
                      className={'iams-default-form-control'}
                      min={0}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("emergency.theNumberOfHumanCasualties")}
            {
              (mainState.type_report === 'emergency' && Number(mainState.human_casualties) !== Number(emergencyState.qty_dead)) ?
                <>: <span
                  className="es-report-warning">{`${t('esManagement.The data must match with')}: ${t("esReport.totalDead")}`}</span></>
                : null
            }
          </CFormLabel>
          <CFormInput type="number"
                      name={'human_casualties'}
                      value={mainState.human_casualties}
                      onChange={handleChange}
                      onBlur={onHumanCasualtiesBlurHandler}
                      className={'iams-default-form-control'}
                      min={0}
          />
        </div>

        <div className="mb-3">
          <CFormLabel>
            {t("emergency.theNumberOfVictims")}
          </CFormLabel>
          <CFormInput type="number"
                      name={'number_of_victims'}
                      value={mainState.number_of_victims}
                      onChange={handleChange}
                      className={'iams-default-form-control'}
                      min={0}
          />
        </div>

        {isDivisionExists ?
          <div className="mb-3">
            <CFormLabel>{t("Division")}</CFormLabel>
            <Select options={divisionsOptions}
                    value={divisionsOptions.find(el => el.value === selectedDivision)}
                    styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                    placeholder={t('Nothing is selected')}
                    onChange={setDivisionHandler}
                    isClearable
            />
          </div>
          : null}

        {currentEsType?.length ?
          <CCard className="mb-3">
            <CCardHeader><CCardTitle>{t('emergency.additionalInformation')}</CCardTitle></CCardHeader>
            <CCardBody>
              {currentEsType.map(el => {
                const requiredField = additionalFieldsWhoIsRequired.includes(el.id);
                if (el?.type === 'number') {
                  return (
                    <div key={el.id} className="mb-3">
                      <CFormLabel>
                        {el.name}
                        {requiredField ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
                      </CFormLabel>
                      {/*@ts-ignore*/}
                      <CFormInput value={el.value}
                                  className="mb-3"
                                  onChange={e => handleCustom(e, el.type, el.id)}
                                  type={el.type}
                                  size="sm"
                                  min={0}
                      />
                    </div>
                  )
                }
                if (el?.type === 'textarea') {
                  return (
                    <div key={el.id} className="mb-3">
                      <CFormLabel>
                        {el.name}
                        {requiredField ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
                      </CFormLabel>
                      {/*@ts-ignore*/}
                      <CFormTextarea value={el.value}
                                     className="mb-3 iams-default-form-control"
                                     onChange={e => handleCustom(e, el.type, el.id)}
                      />
                    </div>
                  )
                }
                if (el?.type === 'string') {
                  return (
                    <div key={el.id} className="mb-3">
                      <CFormLabel>
                        {el.name}
                        {requiredField ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
                      </CFormLabel>
                      {/*@ts-ignore*/}
                      <CFormInput value={el.value}
                                  className="mb-3 iams-default-form-control"
                                  onChange={e => handleCustom(e, el.type, el.id)}
                                  size="sm"
                      />
                    </div>
                  )
                }
                if (el?.type === 'image') {
                  return (
                    <div key={el.id}>
                      {/*@ts-ignore*/}
                      <CFormInput className="mb-3"
                                  onChange={e => handleCustom(e, el.type, el.id)}
                                  type={'file'}
                                  label={el.name}
                                  size="sm"
                                  multiple
                      />
                      <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap'}}>
                        {/*@ts-ignore*/}
                        {el.value.map((pic: any, i: number) => {
                          return (
                            <div key={i + '_pic'} className="image-wrapper">
                              <div className="image-delete-icon-wrapper"
                                   onClick={(e) => handleDeleteCustomPicture(e, pic.name, el.id)}>
                                <CIcon icon={cilX} className="image-delete-icon"/>
                              </div>
                              <img alt={""}
                                   src={URL.createObjectURL(pic)}
                                   style={{height: '110px', width: 'auto'}}
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
                if (el?.type === 'date') {
                  return (
                    <div key={el.id} style={{height: '50px'}} className="mb-4">
                      <div className="react-date-picker-wrapper">
                        <CFormLabel>
                          {el.name}
                          {requiredField ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
                        </CFormLabel>
                        {/*/@ts-ignore*/}
                        <DatePicker onChange={e => handleCustom(e, el.type, el.id)} selected={el.value}
                                    dateFormat={'dd.MM.yyyy'}
                                    locale={i18n.language}
                                    className={"ml-5"}
                        />
                      </div>
                    </div>
                  )
                }
                if (el?.type === 'time') {
                  return (
                    <div key={el.id} className="mb-3">
                      <CFormLabel>
                        {el.name}
                        {requiredField ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
                      </CFormLabel>
                      <TimePicker defaultValue={el.value ? String(el.value) : null}
                                  onChange={value => changeCustomTimeHandler(value, el.type, el.id)}
                      />
                    </div>
                  )
                }
                if (el?.type === 'datetime') {
                  return (
                    <div key={el.id} style={{height: '50px'}} className="mb-4">
                      <div className="react-date-picker-wrapper">
                        <CFormLabel>
                          {el.name}
                          {requiredField ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
                        </CFormLabel>
                        {/*/@ts-ignore*/}
                        <DatePicker onChange={e => handleCustom(e, el.type, el.id)} selected={el.value}
                                    showTimeInput
                                    dateFormat={'dd.MM.yyyy H:mm'}
                                    locale={i18n.language}
                                    className={"ml-5"}
                        />
                      </div>
                    </div>
                  )
                }
                if (el?.type === 'boolean') {
                  return (
                    <div key={el.id} className="mb-3">
                      {/*@ts-ignore*/}
                      <CFormCheck checked={el.value} id={el.name}
                                  name={el.name}
                                  label={el.name}
                                  onChange={e => handleCustom(e, el.type, el.id)}
                      />
                    </div>
                  )
                }
                return null
              })}
            </CCardBody>
          </CCard> : null}

        <div className="mb-3">
          {
            mainState.type_report === 'emergency' ?
              <AccordionEsReport handleChange={handleChangeEmergency}
                                 state={emergencyState}
                                 humanCasualtiesError={(mainState.type_report === 'emergency' && Number(mainState.human_casualties) !== Number(emergencyState.qty_dead))}
                                 healthFacilitiesError={emergencyState.qty_health_facilities !== (Number(emergencyState.total_number_of_outpatient_facilities_affected) + Number(emergencyState.total_number_of_affected_hospitals))}
              /> : null
          }
        </div>

        {(savingError?.description && !mainState.description) &&
          <span style={{color: 'red'}}>{savingError.description}</span>}

        <div>
          <CFormLabel>
            {t("emergency.location")}
            <span style={{color: 'red'}}>&nbsp;*</span>
            {(mainState.latitude && mainState.longitude) ? ` - [ ${mainState.latitude}, ${mainState.longitude} ]` : ''}
          </CFormLabel>
          <div className="mb-3 emergency-map-wrapper">
            {coordinatesVisible ?
              <div className="map-coordinate">
                {renderCoordinate}
              </div>
              : null
            }
            <MapContainer
              key={mapKey}
              center={center ? [coordinates.lat, coordinates.lng] : [41.1262532, 73.79516602]}
              zoom={7}
              zoomControl={false}
              attributionControl={false}
              style={{height: '100%'}}
            >
              {
                (coordinates.lat && coordinates.lng) ?
                  <Marker position={[coordinates.lat, coordinates.lng]}
                          icon={
                            Leaflet.divIcon({
                              className: 'coordinates-icon',
                              html: '<i class="fa fa-dot-circle-o" aria-hidden="true"></i>',
                              iconSize: [32, 32]
                            })
                          }>
                    <Popup>
                      <div>
                        <p>{t("gis.geoObjects.latitude")}: {coordinates.lat}</p>
                        <p>{t("gis.geoObjects.longitude")}: {coordinates.lng}</p>
                      </div>
                    </Popup>
                  </Marker>
                  : null
              }

              {layersControl ?
                <LayersControl position="topright">
                  <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="OpenStreetMap">
                    <TileLayer url={getTiles('OpenStreetMap')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Google.Hybrid">
                    <TileLayer url={getTiles('Google.Hybrid')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Esri.WorldStreetMap">
                    <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
                    <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Esri.WorldImagery">
                    <TileLayer url={getTiles('Esri.WorldImagery')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
                    <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Stamen.Terrain">
                    <TileLayer url={getTiles('Stamen.Terrain')}/>
                  </LayersControl.BaseLayer>
                </LayersControl>
                :
                <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
              }

              {zoomControl ? <ZoomControl position="topright"/> : null}

              <Control position="topright">
                <a className="leaflet-control-crosshairs leaflet-control-button"
                   href="/"
                   title="Crosshairs"
                   role="button"
                   aria-label="Crosshairs"
                   aria-disabled="false"
                   onClick={e => handleVisible(e, 'coordinates')}
                >
                  <i className="fa fa-crosshairs" aria-hidden="true"/>
                </a>
              </Control>

              {scaleControl ? <ScaleControl position="bottomleft"/> : null}

              {loading ? <MapLoader/> : null}

              {(!responseEmergencyTypes?.results?.length && mainState.type_emergency === 0) || !icon ? null :
                //@ts-ignore
                (position[0] && position[1]) ? <Marker position={position} icon={icon}/> : null}
              <MapEventsComponent handlerZoom={() => {
              }} getPosition={handleGetPosition}/>
            </MapContainer>
          </div>

          {(savingError?.location && (!mainState.latitude || !mainState.longitude)) &&
            <span style={{color: 'red'}}>{savingError.location}</span>}
        </div>

        <div className="mb-3">
          <CFormInput type="file"
                      label={t('labels.Images')}
                      name={'images'}
                      onChange={handleChangeFiles}
                      className={'emergency-custom-input mb-3'}
                      multiple
          />
          <div className="es-detail__files-wrapper">
            {filesState.map((item: any, i: number) => {
              return (
                <div key={i + '_pic'} className="image-wrapper">
                  <div className="image-delete-icon-wrapper"
                       onClick={() => handleDeleteFile(item.name)}
                  >
                    <CIcon icon={cilX} className="image-delete-icon"/>
                  </div>
                  {!item.type.includes("image") ?
                    <div className="file-item">
                      {item.name}
                    </div> :
                    <img src={URL.createObjectURL(item)} width="auto" height="110" alt={""}/>
                  }
                </div>
              )
            })}
            {emergencyFilesState.map((item: any, i: number) => {
              return (
                <div key={i + '_pic'} className="image-wrapper">
                  <div className="image-delete-icon-wrapper"
                       onClick={() => handleDeleteEmergencyFile(item.id)}
                  >
                    <CIcon icon={cilX} className="image-delete-icon"/>
                  </div>
                  {!extension.includes(item.name.split('.')[item.name.split('.').length - 1]) ?
                    <div className="file-item">
                      {item.name}
                    </div> :
                    <img src={item.file} width="auto" height="110" alt={""}/>
                  }
                </div>
              )
            })}
          </div>
        </div>

        <div className="mb-3">
          <CFormCheck id="status"
                      name="status"
                      label={t('labels.Report has been processed')}
                      checked={mainState.status === 'completed'}
                      onChange={handleChecked}
          />
        </div>

        <div className="mb-3">
          <CFormCheck id="late_information"
                      name="late_information"
                      label={t('labels.late_information')}
                      checked={mainState.late_information}
                      onChange={handleChecked}
          />
        </div>

        <div className="mb-5"
             style={{display: 'flex', alignItems: 'start', columnGap: '1rem', flexDirection: 'column'}}>
          <div className="mb-3">
            <span style={{color: 'red'}}>*&nbsp;</span>
            <span>{t('createAssignment.field star')}</span>
            {fieldsDescriptionWhoIsRequired ?
              <>: <span className="es-report-warning">{fieldsDescriptionWhoIsRequired}</span></>
              : null}
          </div>

          {
            (mainState.type_report === 'emergency' && Number(mainState.human_casualties) !== Number(emergencyState.qty_dead)) ?
              <div className="mb-3">
                <span className="es-report-warning">{t('esManagement.The data on human casualties must match')}</span>
              </div>
              : null
          }

          <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '1rem'}}>
            {(mainState.type_report === 'emergency' && (id === "create" || emergencyById?.properties?.is_draft))
              ?
              <>
                <CButton onClick={() => {
                  id === "create" ? handleCreateEmergency(true) : handleUpdateEmergency(true)
                }}
                         disabled={saveAsDraftDisable}
                >
                  {t("Save as draft")}
                </CButton>
                <CButton color="warning"
                         onClick={() => {
                           id === "create" ? handleCreateEmergency(false) : handleUpdateEmergency(false)
                         }}
                         disabled={publishDisable}
                >
                  {t("publish")}
                </CButton>
              </>
              :
              <CButton onClick={() => {
                id === "create" ? handleCreateEmergency(false) : handleUpdateEmergency(false)
              }}
                       disabled={publishDisable}
              >
                {t("save")}
              </CButton>
            }
          </div>
          {saveIsLoading ?
            <SpinnerDotted size={16} color="#091b37"/>
            : savingError ?
              <span className="error-message">{t('Changes are not saved')}</span>
              : null
          }
        </div>
      </CForm>
    </>
  );
};

export default NewEsReport;
