import React, {FC} from 'react';

import EmergencyCard from "./EmergencyCard";
import {esFeed} from "./EsFeed";
import {IPaginationResponse} from "../../../store/models";

interface IEmergencyList {
  data: esFeed[]
  onClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void
  isMenuOpen: any
  menuPosition: string
  users: any
  emergencyTypes: IPaginationResponse<any[]> | undefined
}

const EmergencyList: FC<IEmergencyList> = ({data, onClick, isMenuOpen, menuPosition, users, emergencyTypes}) => {
  return (
    <div id="es-scrollable-block" className="es-scrollable-block emergency-block">
      {data?.map(el => {
        return (
          <div key={el.id}>
            <EmergencyCard item={el}
                           onClick={onClick}
                           isMenuOpen={isMenuOpen}
                           menuPosition={menuPosition}
                           users={users}
                           icon={emergencyTypes?.results?.find(item => item.id === el.type_emergency)?.geo_icon?.icon}
            />
          </div>
        )
      })}
      <div id='feed_bottom'/>
    </div>
  );
};

export default EmergencyList;
