import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CFormCheck,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CSpinner
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilX} from "@coreui/icons";
import {
  FeatureGroup,
  GeoJSON,
  LayersControl,
  MapContainer,
  Marker,
  Popup,
  ScaleControl,
  TileLayer,
  useMapEvents,
  ZoomControl
} from "react-leaflet";
import Leaflet from "leaflet";
import Control from "react-leaflet-custom-control";
import dayjs from "dayjs";

import {getTiles} from "../../../components/maps/tiles";
import FormGroup from "../../../components/UI/Forms/FormGroup";
import TimePicker from "../../../components/UI/TimePicker/TimePicker";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import MapLoader from "../../../components/preloaders/MapLoader/MapLoader";
import SpinnerDotted from "../../../components/preloaders/SpinnerDotted/SpinnerDotted";
import makeQuery from "../../../functions/makeQuery";
import {MAP_CENTER_COORDINATES} from "../../../config";

import {useGetGeoTypesQuery} from "../../../store/geoTypes/geoTypes.api";
import {
  useCreateGeoObjectMutation,
  useLazyGetGeoObjectDetailQuery,
  useUpdateGeoObjectMutation
} from "../../../store/geoObjects/geoObjects.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useRemoveGeoObjImageMutation, useUploadGeoObjImageMutation} from "../../../store/geoObjImages/geoObjImages.api";
import {useGetGeoIconsQuery} from "../../../store/geoIcons/geoIcons.api";
import {useGetInfoUserQuery} from "../../../store/account/account.api";
import {
  IGeoFeature,
  IGeoJSON, IGeoLineString, IGeoMultiLineString,
  IGeoMultiPoint,
  IGeoMultiPolygon,
  IGeoPoint,
  IGeoPolygon
} from "../../../interfaces/IGeoJSON";

interface IMapEventComponent {
  handlerZoom: (zoom: number) => void;
  getPosition?: (event: any) => void;
}

type TGeneralInfo = {
  name: string,
  description: string,
  geo_type: number | null,
  region: number | null,
  district: number | null,
  latitude: string | number,
  longitude: string | number
};

type TGeoObjectField = {
  id?: number,
  geo_type_field: number,
  geo_type_field_name: string,
  type: string,
  value?: any
};

type TGeoObjectFiles = {
  id: number,
  geo_object: number,
  image: string
};

function getDefaultFieldValue (type: string) {
  switch (type.toLowerCase()) {
    case 'boolean': return false;
    case 'number': return 0;
    case 'string': return '';
    case 'textarea': return '';
    case 'date': return null;
    case 'time': return null;
    case 'datetime': return null;
    default: return null;
  }
}

function getGeoJsonFeature (geometry: IGeoPoint | IGeoMultiPoint | IGeoPolygon | IGeoMultiPolygon | IGeoLineString | IGeoMultiLineString): IGeoFeature {
  return {
    type: 'Feature',
    properties: {},
    geometry: geometry
  };
}

const MapEventComponent: FC<IMapEventComponent> = ({handlerZoom, getPosition}) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      mapEvents.getZoom();
    },
    click: (e) => {
      if (getPosition && e.type === 'click') getPosition(e.latlng);
    }
  });
  return null;
};

const NewGeoObject = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const {data: me, isLoading: getMeLoading} = useGetInfoUserQuery();
  const {data: geoTypes, isLoading: geoTypesLoading} = useGetGeoTypesQuery(makeQuery.stringify({page_size: 100000}));
  const [createGeoObject, {isLoading: createGeoObjectLoading}] = useCreateGeoObjectMutation();
  const [updateGeoObject, {isLoading: updateGeoObjectLoading}] = useUpdateGeoObjectMutation();
  const {data: geoIcons, isLoading: geoIconsLoading} = useGetGeoIconsQuery(makeQuery.stringify({page_size: 100000}));
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const [fetchGeoObject, {data: geoObject, isLoading: geoObjectLoading}] = useLazyGetGeoObjectDetailQuery();
  const [uploadGeoObjectImage, {isLoading: uploadGeoObjectImageLoading}] = useUploadGeoObjImageMutation();
  const [deleteGeoObjectImage, {isLoading: deleteGeoObjectImageLoading}] = useRemoveGeoObjImageMutation();

  const [generalInfo, setGeneralInfo] = useState<TGeneralInfo>({
    name: '',
    description: '',
    geo_type: null,
    region: null,
    district: null,
    latitude: '',
    longitude: ''
  });
  const [geoObjectFields, setGeoObjectFields] = useState<TGeoObjectField[]>([]);
  const [geoObjectId, setGeoObjectId] = useState<number | null>(null);
  const [filesState, setFilesState] = useState<any[]>([]);
  const [geoObjectFilesState, setGeoObjectFilesState] = useState<TGeoObjectFiles[]>([]);
  const [filesForDelete, setFilesForDelete] = useState<number[]>([]);

  const [updateMap, setUpdateMap] = useState<boolean>(true);
  const [mapCenter, setMapCenter] = useState<[number, number]>(MAP_CENTER_COORDINATES);
  const [mapKey, setMapKey] = useState(0);
  const [geoJson, setGeoJson] = useState<IGeoJSON | null>(null);
  const [pointCoordinates, setPointCoordinates] = useState<[number, number] | null>(null);
  const [searchedCoordinates, setSearchedCoordinates] = useState<{lat: string | number, lng: string | number}>({lat: '', lng: ''});

  const [opacity, setOpacity] = useState(1);
  const [visible, setVisible] = useState(false)
  const [opacityVisible, setOpacityVisible] = useState(false);
  const [coordinatesVisible, setCoordinatesVisible] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const id = Number(location.pathname.split('/').reverse()[0]);
    if (!isNaN(id)) {
      fetchGeoObject(id);
      setGeoObjectId(id);
    }
  }, []);

  useEffect(() => {
    if (geoObjectId && geoTypes?.results && geoObject?.id) {
      setGeneralInfo({
        name: geoObject.name || '',
        description: geoObject.description || '',
        geo_type: geoObject.geo_type || null,
        region: geoObject.region || null,
        district: geoObject.district || null,
        latitude: geoObject.latitude || '',
        longitude: geoObject.longitude || ''
      });

      setGeoObjectFieldsValues();

      if (geoObject.images) {
        const images = geoObject.images.map(el => ({
          id: el.id,
          geo_object: el.geo_object,
          image: el.image
        }));
        setGeoObjectFilesState(images);
      }

      const geoJson: IGeoJSON = {
        type: 'FeatureCollection',
        features: []
      };
      if (geoObject.geometry) geoJson.features.push(getGeoJsonFeature(geoObject.geometry));
      if (geoObject.geom) geoJson.features.push(getGeoJsonFeature(geoObject.geom));
      if (geoObject.geom_line_string) geoJson.features.push(getGeoJsonFeature(geoObject.geom_line_string));
      if (geoObject.geom_multi_linestring) geoJson.features.push(getGeoJsonFeature(geoObject.geom_multi_linestring));
      setGeoJson(geoJson);

      const coordinates: [number, number] = [...MAP_CENTER_COORDINATES];
      if (geoObject.location?.coordinates[1]) {
        coordinates[0] = geoObject.location.coordinates[1];
        coordinates[1] = geoObject.location.coordinates[0];
        setPointCoordinates(coordinates);
      } else if (geoObject.latitude && geoObject.longitude) {
        coordinates[0] = geoObject.latitude;
        coordinates[1] = geoObject.longitude;
        setPointCoordinates(coordinates);
      }
      setMapCenter(coordinates);
    }
  }, [geoTypes, geoObject, geoObjectId]);

  const setGeoObjectFieldsValues = (geoTypeId?: number) => {
    const fields = geoTypes?.results.find(type => String(type.id) === String(geoTypeId || geoObject?.geo_type))?.geo_type_field?.map(el => {
      const field: TGeoObjectField = {
        geo_type_field: el.id,
        geo_type_field_name: el.field_name,
        type: el.field_type,
        value: getDefaultFieldValue(el.field_type)
      };
      const existingField = geoObject?.geo_object_field?.find(geoField => String(geoField.geo_type_field) === String(el.id));
      if (existingField) {
        field.id = existingField.id;
        if (existingField.value) {
          if (field.type === 'date' || field.type === 'datetime') {
            if (!isNaN(Number(existingField.value))) field.value = dayjs(Number(existingField.value)).format('YYYY-MM-DDTHH:mm:ss');
            else field.value = dayjs(existingField.value).format('YYYY-MM-DDTHH:mm:ss');
            if (String(field.value).toLowerCase() === 'invalid date') field.value = '';
          } else field.value = existingField.value;
        }
      }
      return field;
    }) || [];
    setGeoObjectFields(fields);
  };

  const updateMapHandler = () => {
    setUpdateMap(false);
    setTimeout(() => {
      setUpdateMap(true);
    }, 100);
  };

  const isLoading = useMemo(() => {
    return (getMeLoading || regionsLoading || districtsLoading || geoTypesLoading || geoIconsLoading || geoObjectLoading);
  }, [getMeLoading, regionsLoading, districtsLoading, geoTypesLoading, geoIconsLoading, geoObjectLoading]);

  const saveIsLoading = useMemo(() => {
    return (createGeoObjectLoading || updateGeoObjectLoading || uploadGeoObjectImageLoading || deleteGeoObjectImageLoading);
  }, [createGeoObjectLoading, updateGeoObjectLoading, uploadGeoObjectImageLoading, deleteGeoObjectImageLoading]);

  const isSubmitDisabled = useMemo(() => {
    const disabled = !generalInfo.name || !generalInfo.description || !generalInfo.geo_type || !generalInfo.region || isLoading || saveIsLoading;
    if (disabled) return true;
    if (!generalInfo.latitude || !generalInfo.longitude) {
      return !geoJson?.features?.length;
    }
    return false;
  }, [generalInfo, geoJson, isLoading, saveIsLoading]);

  const myRegions = useMemo(() => {
    if (!me || !regions) return [];
    return regions.filter(region => me.district.map((d: any) => d.region).includes(region.id));
  }, [me, regions]);

  const myDistricts = useMemo(() => {
    if (!me || !districts) return [];
    return districts.filter(district => me.district.map((d: any) => d.id).includes(district.id));
  }, [me, districts]);

  const isMyGeoObject = useMemo(() => {
    if (!geoObjectId) return true;
    if (!me || !myRegions || !myDistricts || !geoObject) return false;
    if (geoObject?.region && !myRegions.find(el => el.id === geoObject.region)) return false;
    if (geoObject?.district && !myDistricts.find(el => el.id === geoObject.district)) return false;
    return true;
  }, [geoObjectId, me, myRegions, myDistricts, geoObject]);

  const mapIcon = useMemo(() => {
    updateMapHandler();
    if (geoIcons && geoTypes && generalInfo.geo_type) {
      const iconId = geoTypes.results.find(el => String(el.id) === String(generalInfo.geo_type))?.icon;
      const iconUrl = geoIcons.results.find(el => el.id === iconId)?.icon;
      return new Leaflet.Icon({
        iconUrl: iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
      });
    }
    return null;
  }, [geoIcons, geoTypes, generalInfo.geo_type]);

  const onChangeGeneralInfoHandler = (event: any) => {
    const innerState = {...generalInfo, [event.target.name]: event.target.value};
    if (event.target.name === 'geo_type') setGeoObjectFieldsValues(Number(event.target.value));
    setGeneralInfo(innerState);
  };

  const onChangeGeoObjectFieldsHandler = (value: any, id: number) => {
    const innerState = [...geoObjectFields];
    const index = innerState.findIndex((el: any) => el?.geo_type_field === id);
    if (index >= 0) {
      const field = {...innerState[index]};
      field.value = value;
      innerState[index] = field;
      setGeoObjectFields(innerState);
    }
  };

  const onChangeSearchedCoordinatesHandler = (event: any) => {
    const {name, value} = event.target;
    if (isNaN(value)) return null;
    setSearchedCoordinates(prev => ({...prev, [name]: value}));
  };

  const onSearchCoordinatesHandler = () => {
    setMapKey(prevKey => prevKey + 1);
    setMapCenter([Number(searchedCoordinates.lat), Number(searchedCoordinates.lng)]);
  };

  const onChangePointCoordinatesHandler = (coords?: {lat: number, lng: number}) => {
    setGeneralInfo(prevState => ({...prevState, latitude: coords ? coords.lat : '', longitude: coords ? coords.lng : ''}));
    setPointCoordinates(coords ? [coords.lat, coords.lng] : null);
  };

  const updateFileInput = (files: any) => {
    const dataTransfer = new DataTransfer();
    files.forEach((file: any) => dataTransfer.items.add(file));
    if (fileInputRef?.current) fileInputRef.current.files = dataTransfer.files;
  };

  const onChangeFilesHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = e.target;
    const tempFiles = [...filesState]

    if (e.target.type === 'file') {
      const chosenFiles = Array.prototype.slice.call(files);
      chosenFiles.forEach(el => {
        if (!tempFiles.find(item => item.name === el.name)) {
          tempFiles.push(el)
        }
      });
      setFilesState(tempFiles);
      updateFileInput(tempFiles);
    }
  };

  const onDeleteFilesHandler = (name: string) => {
    const innerState = [...filesState];
    const index = innerState.findIndex(el => el.name === name);
    innerState.splice(index, 1);
    setFilesState(innerState);
    updateFileInput(innerState);
  };

  const onDeleteGeoObjectFileHandler = (id: number) => {
    const innerState = [...geoObjectFilesState];
    const innerFilesIds = [...filesForDelete];
    const idx = innerState.findIndex(el => el.id === id);
    innerState.splice(idx, 1);
    if (!innerFilesIds.includes(id)) innerFilesIds.push(id);
    setGeoObjectFilesState(innerState);
    setFilesForDelete(innerFilesIds);
  };

  const onCreateHandler = () => {
    const requestData: {[key: string]: any} = {...generalInfo};

    const geo_object_field: Array<{geo_type_field: number, value: any}> = [];
    geoObjectFields.forEach(field => {
      if (field.value) geo_object_field.push({geo_type_field: field.geo_type_field, value: String(field.value)});
    });
    if (geo_object_field.length) requestData.geo_object_field = geo_object_field;

    if (requestData.latitude && requestData.longitude) {
      requestData.location = {
        type: 'Point',
        coordinates: [Number(requestData.longitude), Number(requestData.latitude)]
      };
    }

    createGeoObject(requestData).then((response: any) => {
        const promises: Array<Promise<any>> = [];

        if (response?.data?.id) {
          filesState.forEach(el => {
            const formDataImg = new FormData();
            formDataImg.append('geo_object', response.data.id);
            formDataImg.append('image', el);
            promises.push(uploadGeoObjectImage(formDataImg))
          });
        }
        return Promise.all(promises)
      }
    ).then(() => {
      navigate('/gis-geo-objects/list');
      // TODO: Сделать процесс загрузки файлов
    }).catch((e: any) => {
      console.log("Something went wrong...");
      setError(e);
    });
  };

  const onUpdateHandler = () => {
    if (!geoObjectId) {
      onCreateHandler();
      return null;
    }

    const requestData: {[key: string]: any} = {...generalInfo};
    const geo_object_field: Array<{geo_type_field: number, value: any}> = [];
    geoObjectFields.forEach(field => {
      geo_object_field.push({geo_type_field: field.geo_type_field, value: field.value});
    });
    if (geo_object_field.length) requestData.geo_object_field = geo_object_field;

    if (requestData.latitude && requestData.longitude) {
      requestData.location = {
        type: 'Point',
        coordinates: [Number(requestData.longitude), Number(requestData.latitude)]
      };
    } else if (geoObject?.location) requestData.location = null;

    updateGeoObject({id: geoObjectId, body: requestData}).then((response: any) => {
      const promises: Array<Promise<any>> = [];

      if (response?.data?.id) {
        filesState.forEach(el => {
          const formDataImg = new FormData();
          formDataImg.append('geo_object', response.data.id);
          formDataImg.append('image', el);
          promises.push(uploadGeoObjectImage(formDataImg))
        });
      }

      if (filesForDelete.length) {
        filesForDelete.forEach(el => {
          promises.push(deleteGeoObjectImage(el));
        });
      }

      return Promise.all(promises)
    }).then(() => {
      navigate('/gis-geo-objects/list');
    }).catch((e: any) => {
      console.log("Something went wrong...");
      setError(e);
    });
  };

  let geoTypesOptions, regionsOptions, districtsOptions;

  if (geoTypes?.results) {
    geoTypesOptions = geoTypes.results.map((el: any) => {
      return {label: el.type_name, value: el.id}
    })
    geoTypesOptions?.unshift({label: t('gis.geoObjects.selectType'), value: 'null'});
  }

  if (myRegions?.length) {
    regionsOptions = myRegions.map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    });
    regionsOptions.unshift({label: t('gis.geoObjects.selectArea'), value: ''});
  }

  if (myDistricts?.length) {
    districtsOptions = myDistricts.filter(el => String(el.region) === String(generalInfo.region)).map(el => {
      return {label: el.coat_code + ' - ' + el.name, value: String(el.id)}
    });
    districtsOptions.unshift({label: t('gis.geoObjects.selectDistrict'), value: ''});
  }

  const handleVisible = (e: any, name: string) => {
    e.preventDefault();
    if (name === 'tree') {
      setVisible(!visible);
    } else if (name === 'opacity') {
      setOpacityVisible(!opacityVisible);
    } else if (name === 'coordinates') {
      setCoordinatesVisible(!coordinatesVisible);
    } else {
      return;
    }
  };

  const handleOpacityChange = (e: any) => {
    const value = e.target.value / 100;
    setOpacity(value);
    const mapContainer: any = document.querySelector('#MapContainer');

    const images = mapContainer.querySelector('div');
    images.style.opacity = opacity;
  };

  const renderOpacity = (() => {
    return (
      <div className="map-opacity-block">
        <p className="map-opacity-title">{t('gis.geoObjects.titleOpacity')}</p>
        <input
          className="map-opacity-input"
          type="range"
          min="0"
          max="100"
          value={opacity * 100}
          onChange={handleOpacityChange}
        />
      </div>
    )
  });

  const geoObjectAdditionalFields = geoObjectFields.map(field => {
    switch (field.type) {
      case 'boolean':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <CFormCheck id={field.geo_type_field_name + field.id}
                        label=""
                        name={field.geo_type_field_name}
                        checked={!!field.value}
                        onChange={e => onChangeGeoObjectFieldsHandler(e.target.checked, field.geo_type_field)}
            />
          </FormGroup>
        );
      case 'number':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <CFormInput id={field.geo_type_field_name + field.geo_type_field}
                        type="number"
                        className="iams-default-form-control"
                        value={field.value}
                        name={field.geo_type_field_name}
                        onChange={e => onChangeGeoObjectFieldsHandler(e.target.value, field.geo_type_field)}
                        size="sm"
            />
          </FormGroup>
        );
      case 'string':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <CFormInput id={field.geo_type_field_name + field.geo_type_field}
                        type="text"
                        className="iams-default-form-control"
                        value={field.value}
                        name={field.geo_type_field_name}
                        onChange={e => onChangeGeoObjectFieldsHandler(e.target.value, field.geo_type_field)}
                        size="sm"
            />
          </FormGroup>
        );
      case 'date':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <input type='date'
                   value={field.value ? dayjs(field.value).format('YYYY-MM-DD') : ''}
                   name={field.geo_type_field_name}
                   onChange={e => onChangeGeoObjectFieldsHandler(e.target.value, field.geo_type_field)}
                   className="date-input"
            />
          </FormGroup>
        );
      case 'time':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <TimePicker defaultValue={field.value ? String(field.value) : null}
                        onChange={value => onChangeGeoObjectFieldsHandler(value, field.geo_type_field)}
            />
          </FormGroup>
        );
      case 'datetime':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <input onChange={e => onChangeGeoObjectFieldsHandler(e.target.value, field.geo_type_field)}
                   type='datetime-local'
                   value={field.value ? dayjs(field.value).format('YYYY-MM-DDTHH:mm') : ''}
                   name={field.geo_type_field_name}
                   className="date-input"
            />
          </FormGroup>
        );
      case 'textarea':
        return (
          <FormGroup key={field.geo_type_field_name + field.geo_type_field} htmlFor={field.geo_type_field_name + field.geo_type_field}
                     label={field.geo_type_field_name}
                     className="mb-1 main-label">
            <CFormTextarea id={field.geo_type_field_name + field.geo_type_field}
                           className="iams-default-form-control"
                           value={field.value}
                           name={field.geo_type_field_name}
                           onChange={e => onChangeGeoObjectFieldsHandler(e.target.value, field.geo_type_field)}
            />
          </FormGroup>
        );
      default:
        return null;
    }
  });

  if (!isMyGeoObject) {
    if (isLoading) return null;
    return (
      <div className="pb-3 mb-4 title-divider">
        <h1>403: Forbidden</h1>
        <h4>{t('You do not have permissions to edit this entry')}</h4>
      </div>
    );
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{geoObjectId ? t('gis.geoObjects.geoObjectUpdate') : t('gis.geoObjects.geoObjectCreate')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <FormGroup className="mb-3 main-label" htmlFor="name">
        <CFormInput id="name"
                    type="text"
                    label={<>{t("gis.geoObjects.naming")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                    className="iams-default-form-control"
                    value={generalInfo.name || ''}
                    name="name"
                    onChange={onChangeGeneralInfoHandler}
                    size="sm"
        />
      </FormGroup>

      <FormGroup className="mb-3 main-label" htmlFor="description">
        <CFormTextarea id="description"
                       label={<>{t("gis.geoObjects.description")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                       className="iams-default-form-control"
                       value={generalInfo.description || ''}
                       name="description"
                       onChange={onChangeGeneralInfoHandler}
        />
      </FormGroup>

      <FormGroup className="mb-3 main-label" htmlFor="geo_type">
        <CFormSelect size="sm"
                     id="geo_type"
                     className="iams-default-form-control"
                     label={<>{t("gis.geoObjects.type")}<span style={{color: 'red'}}>&nbsp;*</span></>}
                     value={generalInfo.geo_type || ''}
                     name="geo_type"
                     onChange={onChangeGeneralInfoHandler}
                     options={geoTypesOptions}
        />
      </FormGroup>

      <FormGroup className="mb-3 main-label" htmlFor="region">
        <CFormSelect size="sm"
                     id="region"
                     className="iams-default-form-control"
                     label={<>{t("gis.geoObjects.area")}
                       <span style={{color: 'red'}}>&nbsp;*</span>
                     </>}
                     value={generalInfo.region || ''}
                     name="region"
                     onChange={onChangeGeneralInfoHandler}
                     options={regionsOptions}
        />
      </FormGroup>

      {generalInfo.region ?
        <FormGroup className="mb-3 main-label" htmlFor="district">
          <CFormSelect size="sm"
                       id="district"
                       className="iams-default-form-control"
                       label={t("gis.geoObjects.district")}
                       value={generalInfo.district || ''}
                       name="district"
                       onChange={onChangeGeneralInfoHandler}
                       options={districtsOptions}
          />
        </FormGroup>
        :
        <FormGroup className="mb-3 main-label" htmlFor="selectRegion">
          <CFormInput id="selectRegion"
                      type="text"
                      className="iams-default-form-control"
                      label={t("gis.geoObjects.district")}
                      value={t('settings.regions.selectRegion')}
                      name="selectRegion"
                      size="sm"
                      disabled={true}
          />
        </FormGroup>
      }

      <h5 className="title-of-map">{t('gis.geoObjects.MapTitle')}
        {!geoJson?.features?.length ? <span style={{color: 'red'}}>&nbsp;*</span> : null}
        {pointCoordinates ? <>
          <span style={{marginLeft: '10px'}}>{`[ ${pointCoordinates.join(', ')} ]`}</span>
          <span style={{marginLeft: '10px', color: 'red', cursor: 'pointer'}}
                onClick={() => onChangePointCoordinatesHandler()}
          >
            {t('gis.geoObjects.deleteGeoPoint')}
          </span>
          </>
          : null}
      </h5>

      <CCard className="mb-3 emergency-map-wrapper">
        <div className="whole-place-wrapper">
          {coordinatesVisible ?
            <div className="map-coordinate">
              <div className="map-opacity-block">
                <p className="map-opacity-title">{t('gis.geoObjects.coordinates')}:</p>
                <div style={{marginBottom: '10px'}}>
                  <label>
                    {t("gis.geoObjects.latitude")}
                    <input type="number"
                           name="lat"
                           value={searchedCoordinates.lat}
                           onChange={onChangeSearchedCoordinatesHandler}
                    />
                  </label>
                  <label>
                    {t("gis.geoObjects.longitude")}
                    <input type="number"
                           name="lng"
                           value={searchedCoordinates.lng}
                           onChange={onChangeSearchedCoordinatesHandler}
                    />
                  </label>
                </div>
                <CButton type="button"
                         size="sm"
                         className="bg-info"
                         color="black"
                         onClick={onSearchCoordinatesHandler}
                         disabled={!(searchedCoordinates.lat && searchedCoordinates.lng)}
                >
                  {t("pagination.search")}
                </CButton>
              </div>
            </div>
            : null
          }

          {updateMap ? <MapContainer
              key={mapKey}
              center={mapCenter}
              zoom={7}
              zoomControl={false}
              attributionControl={false}
              style={{height: '100%', position: 'relative'}}
              id="MapContainer"
            >
              {((searchedCoordinates.lat || searchedCoordinates.lat === 0) && (searchedCoordinates.lng || searchedCoordinates.lng === 0)
                && !isNaN(Number(searchedCoordinates.lat)) && !isNaN(Number(searchedCoordinates.lng))) ?
                <Marker position={[Number(searchedCoordinates.lat), Number(searchedCoordinates.lng)]}
                        icon={
                          Leaflet.divIcon({
                            className: 'coordinates-icon',
                            html: '<i class="fa fa-dot-circle-o" aria-hidden="true"></i>',
                            iconSize: [32, 32]
                          })
                        }>
                  <Popup>
                    <div>
                      <p>{t("gis.geoObjects.latitude")}: {searchedCoordinates.lat}</p>
                      <p>{t("gis.geoObjects.longitude")}: {searchedCoordinates.lng}</p>
                    </div>
                  </Popup>
                </Marker>
                : null
              }

              {generalInfo.geo_type && mapIcon && pointCoordinates ?
                <Marker position={pointCoordinates} icon={mapIcon}/>
                : null}

              <FeatureGroup>
                {geoJson ? <GeoJSON data={geoJson}/> : null}
              </FeatureGroup>

              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="OpenStreetMap.Mapnik">
                  <TileLayer url={getTiles('OpenStreetMap.Mapnik')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="OpenStreetMap">
                  <TileLayer url={getTiles('OpenStreetMap')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Google.Hybrid">
                  <TileLayer url={getTiles('Google.Hybrid')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldStreetMap">
                  <TileLayer url={getTiles('Esri.WorldStreetMap')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldGrayCanvas">
                  <TileLayer url={getTiles('Esri.WorldGrayCanvas')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldImagery">
                  <TileLayer url={getTiles('Esri.WorldImagery')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Esri.WorldShadedRelief">
                  <TileLayer url={getTiles('Esri.WorldShadedRelief')}/>
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Stamen.Terrain">
                  <TileLayer url={getTiles('Stamen.Terrain')}/>
                </LayersControl.BaseLayer>
              </LayersControl>

              <ZoomControl position="topright"/>

              <Control position="topright">
                <a className="leaflet-control-crosshairs leaflet-control-button"
                   href="/"
                   title="Crosshairs"
                   role="button"
                   aria-label="Crosshairs"
                   aria-disabled="false"
                   onClick={e => handleVisible(e, 'coordinates')}
                >
                  <i className="fa fa-crosshairs" aria-hidden="true"/>
                </a>
              </Control>

              <Control position="topright">
                {opacityVisible ? renderOpacity() : null}
                <a className="leaflet-control-layers-satellite leaflet-control-button"
                   href="/"
                   title="Layers satellite"
                   role="button"
                   aria-label="Layers satellite"
                   aria-disabled="false"
                   onClick={e => handleVisible(e, 'opacity')}
                >
                  <i className="fa fa-adjust" aria-hidden="true"/>
                </a>
              </Control>

              <ScaleControl position="bottomleft"/>

              <MapEventComponent handlerZoom={() => {
              }}
                                 getPosition={onChangePointCoordinatesHandler}
              />
            </MapContainer>
            :
            <MapLoader/>
          }
        </div>
      </CCard>

      <CCard>
        <CCardHeader className="p-0">
          <div className="p-2 mb-0 card-header-title">
            {t('gis.geoObjects.additionalInformation')}
          </div>
        </CCardHeader>
        <CCardBody>
          {geoObjectAdditionalFields}
        </CCardBody>
      </CCard>

      <FormGroup className="main-label" htmlFor="description" label={t('gis.geoObjects.image')}>
        <CFormInput type="file"
                    onChange={onChangeFilesHandler}
                    className='emergency-custom-input mb-3'
                    multiple
                    ref={fileInputRef}
        />
        <div className="es-detail__files-wrapper">
          {filesState.map((item: any, i: number) => {
            return (
              <div key={i + '_pic'} className="image-wrapper">
                <div className="image-delete-icon-wrapper"
                     onClick={() => onDeleteFilesHandler(item.name)}
                >
                  <CIcon icon={cilX} className="image-delete-icon"/>
                </div>
                {!item.type.includes("image") ?
                  <div className="file-item">
                    {item.name}
                  </div> :
                  <img src={URL.createObjectURL(item)} width="auto" height="110" alt={""}/>
                }
              </div>
            )
          })}
          {geoObjectFilesState.map((item: any, i: number) => {
            return (
              <div key={i + '_pic'} className="image-wrapper">
                <div className="image-delete-icon-wrapper"
                     onClick={() => onDeleteGeoObjectFileHandler(item.id)}
                >
                  <CIcon icon={cilX} className="image-delete-icon"/>
                </div>
                {
                  <img src={item.image} width="auto" height="110" alt={""}/>
                }
              </div>
            )
          })}
        </div>
      </FormGroup>

      <div className="mb-3">
        <span style={{color: 'red'}}>*&nbsp;</span>
        <span>{t('createAssignment.field star')}</span>
      </div>
      <div className="mb-5" style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
        <div>
          {
            geoObjectId ?
              <CButton type="submit"
                       onClick={onUpdateHandler}
                       className="mb-1"
                       disabled={isSubmitDisabled}
              >
                {t("save")}
              </CButton>
              :
              <CButton type="submit"
                       onClick={onCreateHandler}
                       className="mb-1"
                       disabled={isSubmitDisabled}
              >
                {t("save")}
              </CButton>
          }
        </div>
        <div>
          {saveIsLoading ?
            <SpinnerDotted size={16} color="#091b37"/>
            : error ?
              <span className="error-message">{error || t('Changes are not saved')}</span>
              : null
          }
        </div>
      </div>
    </>
  );
};

export default NewGeoObject;
