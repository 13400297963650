import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  CButton, CButtonGroup,
  CFormInput,
  CTable, CTableBody, CTableDataCell,
  CTableHead, CTableHeaderCell,
  CTableRow
} from "@coreui/react";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import FormGroup from "../../../components/UI/Forms/FormGroup";

import {getPathByName} from "../../../routes";
// import {createService, deleteService, fetchServices, updateService} from "../../../store/actions/services";
import {useTranslation} from "react-i18next";
import {
  useCreateServiceMutation, useGetServicesQuery,
  useLazyGetServicesQuery, useRemoveServiceMutation,
  useUpdateServiceMutation
} from "../../../store/services/services.api";
import {sortArrayOfObjectsByKey} from "../../../functions";
import {IService} from "../../../interfaces/IServices";

const ListOfServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const [fetchServices, {isError: errorServices,  isLoading: loadingServices, data: services}] = useLazyGetServicesQuery();
  const {isError: errorServices,  isLoading: loadingServices, data: services} = useGetServicesQuery();
  const [createService] = useCreateServiceMutation();
  const [updateService] = useUpdateServiceMutation();
  const [deleteService] = useRemoveServiceMutation();
  // const services = useSelector(state => state.services.services);

  const [visible, setVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [state, setState]: any = useState({ service_name: '', url: '' });
  const [error, setError]: any = useState(null);
  const [sorting, setSorting] = useState({ by: 'service_name', direction: 'asc' });

  // useEffect(() => {
  //   // dispatch(fetchServices());
  //   fetchServices('');
  //   // eslint-disable-next-line
  // },[]);

  const sortingHandler = (field: string) => {
    const innerState = {...sorting};
    if (innerState.by === field) {
      innerState.direction = innerState.direction === 'asc' ? 'desc' : 'asc';
    }
    else {
      innerState.by = field;
      innerState.direction = 'asc';
    }
    setSorting(innerState);
  };

  const toggleEditModalHandler = (visible?: boolean, data?: any) => {
    if (data && data.id) {
      setSelectedId(data.id);
      setState({ service_name: data.service_name, url: data.url });
    }
    else {
      setTimeout(() => {
        setSelectedId(null);
        setState({ service_name: '', url: '' });
      }, 200);
    }
    setError(null);
    setVisible(!!visible);
  };

  const toggleDeleteModalHandler = (visible?: boolean, data?: any) => {
    if (data && data.id) {
      setSelectedId(data.id);
      setState({ service_name: data.service_name, url: data.url });
    }
    else {
      setTimeout(() => {
        setSelectedId(null);
        setState({ service_name: '', url: '' });
      }, 200);
    }
    setError(null);
    setDeleteModalVisible(!!visible);
  };

  const handleChange = (event: any) => {
    setState((prevState: any) => ({ ...prevState, [event.target.name]: event.target.value}));
  };

  const onBlurUrlHandler = (url: string) => {
    // eslint-disable-next-line
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    if (url.match(regex)) {
      // nothing
    } else {
      setState((prevState: any) => ({ ...prevState, url: ''}));
    }
  };

  const handleEdit = async () => {
    if (!state.service_name) {
      setError(`"${t('services.title')}" - ${t('Should not be empty')}`);
      return null;
    }
    if (!state.url) {
      setError(`"${t('URL')}" - ${t('Should not be empty')}`);
      return null;
    }

    if (selectedId) {
      // await dispatch(updateService(selectedId, state));
      updateService({id: selectedId, body: state});
    }
    else {
      // await dispatch(createService(state));
      createService(state);
    }
    toggleEditModalHandler(false);
  };

  const redirectHandler = (id: number) => {
    navigate(`/services/${id}`);
    // let nav = '/services';
    // if (!nav) return null;
    // if (nav.indexOf('/:id') > 0) {
    //   nav = nav.substring(0, nav.indexOf('/:id'));
    //   navigate(`${nav}/${id}`);
    // }
  };

  const handleDelete = async () => {

    // do nothing
    if (String(selectedId) !== 'this id can not be exist') return null;

    // await dispatch(deleteService(selectedId));
    deleteService(Number(selectedId));
    toggleDeleteModalHandler();
  };

  let servicesForTable: any[] = [];

  if (services?.length) {
    servicesForTable = sortArrayOfObjectsByKey(services, sorting.by, sorting.direction === 'desc').map((el: any) => {
      return (
        <CTableRow key={el.id}>
          <CTableDataCell>
            {el.service_name}
          </CTableDataCell>
          <CTableDataCell>
            {el.url}
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton size="sm" color="success" className="default-table__action-btn"
                       onClick={() => redirectHandler(el.id)}>
                <i className="fa fa-eye color-white" aria-hidden="true"/>
              </CButton>
              <CButton size="sm" color="info" className="default-table__action-btn"
                       onClick={() => toggleEditModalHandler(true, el)}>
                <i className="fa fa-pencil-square-o color-white"/>
              </CButton>
              <CButton size="sm" color="danger" className="default-table__action-btn"
                       onClick={() => toggleDeleteModalHandler(true, el)}>
                <i className="fa fa-times color-white" aria-hidden="true"/>
              </CButton>
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <>
      <div className="pb-3 mb-4 title-divider flex">
        <h1>{t('nav.Services')}</h1>
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={() => toggleEditModalHandler(true)}
        >
          {t('services.addService')}
        </CButton>
      </div>

      <CTable bordered striped className="default-table">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => sortingHandler('service_name')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('services.title')}</span>
                {sorting.by === 'service_name' ?
                  <i className={`fa fa-sort-amount-${sorting.direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '20%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => sortingHandler('url')}
            >
              <div className="d-flex justify-content-between">
                <span>Url</span>
                {sorting.by === 'url' ?
                  <i className={`fa fa-sort-amount-${sorting.direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '13%'}} scope="col" className="default-table__actions">
              {t('services.actions')}
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {servicesForTable}
        </CTableBody>
      </CTable>

      <DefaultModal visible={visible}
                    setVisible={toggleEditModalHandler}
                    title={selectedId ? t('services.editService') : t('services.addService')}
                    type="info"
                    cancelButton={t('cancel')}
                    onOk={handleEdit}
                    error={error}
      >
        <div className=" align-items-center mb-3 mt-3">
          <FormGroup htmlFor="service_name" label={t('services.title')}>
            <CFormInput id="service_name"
                        type="text"
                        value={state.service_name}
                        name="service_name"
                        autoComplete={'off'}
                        onChange={handleChange}
                        size="sm"
            />
          </FormGroup>
          <FormGroup htmlFor="url" label={'Url'}>
            <CFormInput id="url"
                        type="text"
                        value={state.url}
                        name="url"
                        autoComplete={'off'}
                        onChange={handleChange}
                        onBlur={() => onBlurUrlHandler(state.url)}
                        size="sm"
            />
          </FormGroup>
        </div>
      </DefaultModal>

      <DefaultModal visible={deleteModalVisible}
                    setVisible={toggleDeleteModalHandler}
                    type="danger"
                    title={t('services.deleteService')}
                    cancelButton={t('cancel')}
                    okButton={t('delete')}
                    onOk={handleDelete}
      >
        <p>{t('services.Do you really want to delete a service?')}</p>
        <p><strong>{state.service_name}</strong></p>
      </DefaultModal>
    </>
  );
};

export default ListOfServices;
