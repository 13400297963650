import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {
  CButton,
  CButtonGroup, CCollapse, CFormInput, CFormLabel, CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";

import {
  useLazyGetEmergenciesQuery,
  useRemoveEmergencyMutation
} from "../../../store/emergencies/emergencies.api";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useGetCountiesQuery} from "../../../store/counties/counties.api";
import {useGetLocalitiesQuery} from "../../../store/localities/localities.api";
import {IPagination} from "../../../interfaces/IPagination";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import DateTimeRangePicker from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import {useDebounce} from "../../../hooks/debounce";
import makeQuery from "../../../functions/makeQuery";
import {IState} from "../EsFeed/EsFeed";
import FilterBlock from "../EsFeed/FilterBlock";
import {useGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";
import Select from "react-select";
import {Numb, sortArrayOfObjectsByKey} from "../../../functions";
import {useGetInfoUserQuery} from "../../../store/account/account.api";

const reactSelectControlStyles = (baseStyles: any, state: any): any => {
  return {
    ...baseStyles,
    '&:hover': {
      borderColor: state.isFocused ? '#998fed' : '#d8dbe0'
    },
    borderRadius: '6px',
    borderColor: state.isFocused ? '#998fed' : '#d8dbe0',
    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(50, 31, 219, 0.25)' : 'unset',
    marginBottom: '5px'
  }
};

const EsBase = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const {data: user} = useGetInfoUserQuery();
  const {data: esTypes, isLoading: esTypesLoading} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 100000, sort_by: 'name', sort_direction: 'asc'}));
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: counties, isLoading: countiesLoading} = useGetCountiesQuery();
  const {data: localities, isLoading: localitiesLoading} = useGetLocalitiesQuery();
  const [fetchEmergencies, {data: emergencies, isLoading: emergenciesLoading, isFetching: emergenciesFetching}] = useLazyGetEmergenciesQuery();
  const [removeEmergency] = useRemoveEmergencyMutation();

  const [state, setState] = useState<IState>({
    region: '',
    reportType: '',
    status: '',
    reportStatus: '',
    district: '',
    esType: ''
  });
  const [pagination, setPagination] = useState<IPagination>({page_size: '100', search: '', page: 1});
  const [filter, setFilter] = useState({sort_by: 'date_emergency', sort_direction: 'desc'});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [visible, setVisible] = useState<any[]>([]);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [dateRange, setDateRange] = useState({start: '', end: ''})
  const [queryState, setQueryState] = useState<string>(makeQuery.stringify({page_size: 20}));

  const countEmergency: number | undefined = emergencies?.results.length;
  const filterDebounce = useDebounce(queryState, 2000);

  useEffect(() => {
    if(queryState && queryState !== '?page_size=20') fetchEmergencies(queryState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDebounce]);

  useEffect(() => {
    const q: { [key: string]: string } = {}
    const range: {[key: string]: string} = {};

    if (dateRange.start && dateRange.end) {
      const start = dayjs(dateRange.start).format('YYYY-MM-DDTHH:mm:ss');
      const end = dayjs(dateRange.end).format('YYYY-MM-DDTHH:mm:ss');
      if (start.toLowerCase() !== 'invalid date') q.date_emergency__gte = start;
      if (end.toLowerCase() !== 'invalid date') q.date_emergency__lte = end;
      // q.date_emergency__gte = JSON.parse(JSON.stringify(new Date(dateRange.start).toISOString().split('T')[0]));
      // q.date_emergency__lte = JSON.parse(JSON.stringify(new Date(dateRange.end).toISOString().split('T')[0]));
    }

    if (state.region) q.region = state.region;
    if (state.district) q.district = state.district;
    if (state.reportType) q.type_report = state.reportType;
    if (state.status) q.status = state.status;
    if (state.reportStatus) q.late_information = state.reportStatus;
    if (state.esType) q.type_emergency = state.esType;

    const query: {[key: string]: string | number} = {...pagination, ...filter, ...range, ...q};
    if (!query.search) delete query.search;

    setQueryState(makeQuery.stringify(query));
  }, [pagination, filter, dateRange, state]);

  useEffect(() => {
    let array: any[] = []
    if (emergencies?.results?.length) {
      array = emergencies.results.map(el => {
        return {id: el.id, value: false}
      })
      setVisible(array)
    }
  }, [emergencies, filterDebounce]);

  const isLoading = useMemo(() => {
    return (emergenciesLoading || regionsLoading || districtsLoading || countiesLoading || localitiesLoading || emergenciesFetching || esTypesLoading);
  }, [emergenciesLoading, regionsLoading, districtsLoading, countiesLoading, localitiesLoading, emergenciesFetching, esTypesLoading]);

  const esTypesOptions = useMemo(() => {
    try {
      const list = esTypes?.results.map(el => {
        const res = { label: el.name, value: el.id };
        // if (i18n.language === 'ru') res.label = res.label.split(' / ').reverse().join(' / ');
        if (i18n.language === 'ru') res.label = res.label.split(' / ').reverse()[0];
        else res.label = res.label.split(' / ')[0];
        return res;
      });
      return sortArrayOfObjectsByKey(list || [], 'label');
    } catch (e) {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esTypes]);

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};
    
    if(countEmergency && countEmergency <= Number(pagination.page_size)) {
        innerState = {
          page_size: pagination.page_size,
          search: pagination.search,
          page: 1
          , ...state}
    }
    setPagination(innerState);
    // if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
  };

  const handleSelect = (e: any, name: string) => {
    if (e?.value) {
      setState(prevState => {
        return {...prevState, [name]: e.value}
      })
    } else {
      setState(prevState => {
        return {...prevState, [name]: ''}
      })
    }
  }

  const onSelectTypeHandler = (data: any) => {
    setState(prev => ({...prev, esType: String(data?.value || '')}));
  };

  const onFilterHandler = (field: string) => {
    const state = {...filter};
    if (field === state.sort_by) state.sort_direction = state.sort_direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.sort_direction = 'asc';
    }
    if (!emergenciesFetching) setFilter(state);
  };

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleToggleDeleteModal = (visible: boolean) => {
    if (!visible) setSelectedId(null);
    setDeleteModal(visible)
  };

  const handleDelete = () => {
    if (String(user?.id) === String(selectedId))
      removeEmergency(selectedId!).then((res: any) => {
        if (res?.data === null) handleToggleDeleteModal(false);
      });
  };

  const handleRedirectToCreate = () => {
    navigate({pathname: '/es-report/create'})
  };

  const handleRedirectToUpdate = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    navigate({pathname: `/es-report/update/${id}`})
  };

  const handleClickToAdditionalInformation = (id: number) => {
    setVisible(prevState => prevState.map((el: any) => {
      if (el.id === id) return {...el, value: !el.value};
      return {...el}
    }));
  };

  const handleSelectDates = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value);
    setDateRangeVisible(false);
  };

  const dateTimeRangePickerValue = useMemo(() => {
    if (String(new Date(dateRange.start)).toLowerCase() !== 'invalid date' && String(new Date(dateRange.end)).toLowerCase() !== 'invalid date') {
      return {
        start: new Date(dateRange.start),
        end: new Date(dateRange.end)
      };
    }
    return undefined;
  }, [dateRange]);

  let esList: any [] = [];

  if (emergencies?.results?.length) {
    esList = emergencies.results.map((el: any, idx: number) => {
      return (
        <CTableRow key={'user' + idx}>
          <CTableDataCell>
            <a href={`/es-base/detail/base/${el.id}`}
               target="_blank"
               rel="noopener noreferrer"
               className="es-base__title">
              {el.title}
            </a>
          </CTableDataCell>
          <CTableDataCell>
            <div style={{cursor: el.description.length > 150 ? 'pointer' : 'initial'}}
                 onClick={el.description.length > 150 ? () => handleClickToAdditionalInformation(el.id) : () => {
                 }}>
              {visible[idx]?.value ? el.description :
                <span>{el.description.substring(0, 150)}{el.description.length > 150 ? '...' : ''}</span>}
            </div>
          </CTableDataCell>
          <CTableDataCell>
            {
              el.locality ?
                localities?.find(item => el.locality === item.id)?.coat_code :
                el.county ?
                  counties?.find(item => el.county === item.id)?.coat_code :
                  el.district ?
                    districts?.find(item => el.district === item.id)?.coat_code :
                    el.region ?
                      regions?.find(item => el.region === item.id)?.coat_code : ''

            }
          </CTableDataCell>
          <CTableDataCell>{dayjs(el.date_emergency).format('DD.MM.YYYY HH:mm')}</CTableDataCell>
          <CTableDataCell>
            {`${el.executor?.position?.name || ''} ${el.executor?.last_name || ''} ${el.executor?.first_name || ''} ${el.executor?.patronymic || ''}`}
          </CTableDataCell>
          <CTableDataCell>{el.type_emergency_name}</CTableDataCell>
          <CTableDataCell>
            <div className="mb-2">
              {el.material_damage ?
                <div>
                  <strong>{t("emergency.materialDamage")}: </strong>
                  {Numb.split(el.material_damage, 3, "'")}
                </div>
                : null}
              {el.human_casualties ?
                <div>
                  <strong>{t("emergency.theNumberOfHumanCasualties")}: </strong>
                  {Numb.split(el.human_casualties, 3, "'")}
                </div>
                : null}
              {el.number_of_victims ?
                <div>
                  <strong>{t("emergency.theNumberOfVictims")}: </strong>
                  {Numb.split(el.number_of_victims, 3, "'")}
                </div>
                : null}
            </div>

            {el.field_emergency.length ?
              <div style={{background: 'transparent', padding: '8px'}}>
                <div className={visible[idx]?.value ? "visible-item" : "collapsable-item"}
                     onClick={() => handleClickToAdditionalInformation(el.id)}>
                  {
                    visible[idx]?.value ?
                      <i className="fa fa-chevron-down" aria-hidden="true" style={{marginRight: '10px'}}></i> :
                      <i className="fa fa-chevron-right" aria-hidden="true" style={{marginRight: '10px'}}></i>
                  }
                  <div style={{cursor: 'pointer', fontWeight: 'bold'}}>
                    {t("gis.geoObjects.additionalInformation")}
                  </div>
                </div>
                <CCollapse visible={visible[idx]?.value}>
                  <ul style={{marginTop: '20px', paddingLeft: '5px'}}>
                    {el.field_emergency.map((elem: any) => {
                      const meaning: string = elem.field_type_emergency?.type === 'date' ? dayjs(elem.meaning).format('DD.MM.YYYY')
                        // : elem.field_type_emergency?.type === 'time' ? dayjs(elem.meaning).format('HH:mm')
                        : elem.field_type_emergency?.type === 'datetime' ? dayjs(elem.meaning).format('DD.MM.YYYY HH:mm')
                          : elem.field_type_emergency?.type === 'boolean' ? (elem.meaning === 'true' ? t('Yes') : t('No')) : elem.meaning || ' - ';
                      return (
                        <li key={elem.id + '_emergency-type'} className="li-dot">
                          <strong>{elem.field_type_emergency.name}:</strong> {" "} {meaning}
                        </li>
                      )
                    })}
                  </ul>
                </CCollapse>
              </div>
              : null}
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton color="info" className="default-table__action-btn"
                       onClick={(e) => handleRedirectToUpdate(e, el.id)}
              >
                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"> </i>
              </CButton>
              {user?.id === el?.author?.id ?
                <CButton color="danger" className="default-table__action-btn"
                         onClick={() => handleOpenDeleteModal(el.id)}
                >
                  <i className="fa fa-times color-white" aria-hidden="true"> </i>
                </CButton>
                : null}
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <div className={emergenciesFetching ? 'page-loading' : ''}>
      <div className="pb-3 mb-4 title-divider flex">
        <h1>{t('nav.ES base')}</h1>
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={handleRedirectToCreate}
        >
          {t('esBase.createEs')}
        </CButton>
      </div>

      <div className="filter-block-wrapper">
        <div style={{flex: 1}}>
          <FilterBlock
            regions={regions!}
            districts={districts!}
            onSelect={handleSelect}
            state={state}
            t={t}
            classname={"filter-block-other"}
          />
        </div>

        <div style={{width: '15%'}}>
          <CFormLabel>{t("labels.type_emergency")}</CFormLabel>
          <Select options={esTypesOptions}
                  value={esTypesOptions?.find(el => String(el.value) === String(state.esType))}
            // styles={selectStyles}
                  styles={{control: (baseStyles, state) => (reactSelectControlStyles(baseStyles, state))}}
                  placeholder={t('All')}
                  onChange={onSelectTypeHandler}
                  isClearable
          />
        </div>

        <div className="d-flex justify-content-md-end mb-3" style={{position: 'relative'}}>
          <div style={{minWidth: '200px'}}>
            <CFormLabel>{t('Select dates')}</CFormLabel>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {/*@ts-ignore*/}
              <CFormInput style={{borderRadius: '6px', fontSize: '12px', minHeight: '38px'}}
                          type="text"
                          value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                          placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                          onClick={() => setDateRangeVisible(true)}
              />
            </div>

          </div>
          {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
            setDateRangeVisible(false)
          }}/>}
          {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
            <DateTimeRangePicker locale={i18n.language} onChange={handleSelectDates} value={dateTimeRangePickerValue}
                                 presets/>
          </div> : null}
        </div>
      </div>

      <div className="mb-3">
        <LoaderLine visible={isLoading}/>
      </div>

      <DefaultPaginationWrapper data={emergencies}
                                state={pagination}
                                onChange={onPaginationHandler}
                                onSearchDelay={2000}
        // onSearchCallback={onSearchCallbackHandler}
      >
        <CTable bordered striped className="default-table" style={{minWidth: '1038px'}}>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell style={{width: '10%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('title')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.title')}</span>
                  {filter.sort_by === 'title' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '15%'}} scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('description')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.description')}</span>
                  {filter.sort_by === 'description' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '10%'}} scope="col"
                                className="sorted-table-cell"
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.SOATE')}</span>
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '15%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('date_emergency')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('emergency.dateOfEmergency')}</span>
                  {filter.sort_by === 'date_emergency' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '10%'}}
                                scope="col"
                                className="sorted-table-cell"
                // onClick={() => onFilterHandler('last_name')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.executor')}</span>
                  {/*{filter.sort_by === 'last_name' ?*/}
                  {/*  <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :*/}
                  {/*  <i className="fa fa-sort" aria-hidden="true"/>*/}
                  {/*}*/}
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '10%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('type_emergency__name')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('labels.type_emergency')}</span>
                  {filter.sort_by === 'type_emergency__name' ?
                    <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '25%'}}
                                scope="col"
                                className="sorted-table-cell"
              >
                <div className="d-flex justify-content-between">
                  <span>{t("gis.geoObjects.additionalInformation")}</span>
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell scope="col" className="default-table__actions">
                {t('settings.users.actions')}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {esList}
          </CTableBody>
        </CTable>
      </DefaultPaginationWrapper>

      <DefaultModal visible={deleteModal}
                    setVisible={handleToggleDeleteModal}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDelete}
        // @ts-ignore
        //             error={error?.data?.error}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">{t('esBase.doYouReallyWantToDeleteAnEmergency')}</p>
          {/*@ts-ignore*/}
          <div className="fs-6 fw-bolder">{emergencies?.results?.find(el => el.id === selectedId)?.title}?</div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default EsBase;
