import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { CButton, CFormCheck, CFormInput, CFormLabel } from "@coreui/react";
import useWebSocket from "react-use-websocket";
import dayjs from "dayjs";

import {useGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";
import {useLazyGetEsFeedQuery} from "../../../store/emergencies/emergencies.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetUsersQuery} from "../../../store/users/users.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import makeQuery from "../../../functions/makeQuery";
import config from "../../../config";
import {useDebounce} from "../../../hooks/debounce";
import FilterBlock from "./FilterBlock";
import EmergencyTypesList from "./EmergencyTypesList";
import EmergencyList from "./EmergencyList";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import DateTimeRangePicker, {TDateTimeRange} from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";

export interface IState {
  region: string
  reportType: string
  status: string
  reportStatus: string
  district: any,
  esType?: string
}

export interface esFeed {
  action_created?: boolean | null
  action_updated?: boolean | null
  action_deleted?: boolean | null
  created_at: string
  date_emergency: Date
  description: string
  executor: string
  id: number
  late_information: boolean
  region: number
  status: string
  title: string
  type_emergency: number
  type_report: string
  updated_at: Date
}

const WS_URL = config.WS_URL_ES_FEED;

const EsFeed = () => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  const {data: responseEmergencyTypes, isLoading: typesLoading} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 100000}));
  const [getEmergencies, {isLoading: emergenciesLoading, isFetching: emergenciesFetching}] = useLazyGetEsFeedQuery();
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: users, isLoading: usersLoading} = useGetUsersQuery();

  const [esTypesChecked, setEsTypesChecked] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [state, setState] = useState<IState>({
    region: '',
    reportType: '',
    status: '',
    reportStatus: '',
    district: '',
  });
  const [isMenuOpen, setMenuOpen] = useState({value: '', isOpen: false});
  const [dropdownPosition, setDropdownPosition] = useState('bottom');
  const [esFeedState, setEsFeedState] = useState<esFeed[] | []>([]);
  const [queryState, setQueryState] = useState<string>(makeQuery.stringify({page_size: 20}));
  const [lastQueryDate, setLastQueryDate] = useState<string>('');
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [dateRange, setDateRange] = useState<TDateTimeRange>({start: null, end: null})

  const filterDebounce = useDebounce(queryState, 2000);

  let token: string = '';
  try {
    const data = localStorage.getItem('getMe');
    if (data) token = (JSON.parse(data).access);
  } catch {}

  useEffect(() => {
    getEmergencyFunc(queryState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDebounce]);

  const getEmergencyFunc = (query: string, date?: string) => {
    const customQuery = query + (date ? `&date_emergency__lt=${date}` : '');
    getEmergencies(customQuery).then(res => {
      let prevEsFeed: esFeed[] = date ? [...esFeedState] : [];
      let newEsFeed = res?.data?.map((el: any) => ({
        created_at: el.created_at,
        date_emergency: el.date_emergency,
        description: el.description,
        executor: el.executor.first_name + " " + el.executor.last_name,
        id: el.id,
        late_information: el.late_information,
        region: el.region,
        status: el.status,
        title: el.title,
        type_emergency: el.type_emergency,
        type_report: el.type_report,
        updated_at: el.updated_at,
        created_by: `${dayjs(el.created_at).format('DD.MM.YYYY HH:mm')} ${el.author?.position?.name || ''} ${el.author?.last_name || ''} ${el.author?.first_name || ''} ${el.author?.patronymic || ''}`
      })) || []
        setEsFeedState(prevEsFeed.concat(newEsFeed))
    })
  };

  const {lastJsonMessage} = useWebSocket(`${WS_URL}?authorization=${token}`, {}, !!token);

  useEffect(() => {
    if (lastJsonMessage?.data && typeof lastJsonMessage?.data === 'object') {
      const data = lastJsonMessage.data
      let array = [...esFeedState];
      if (data.action_created && filterValidate(data)) {
        array.unshift(data);
      }
      if (data.action_updated) {
        const index = array.findIndex((el) => el.id === data.id);
        if (index >= 0) {
          array[index] = data;
        }
      }
      setEsFeedState(array)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  useEffect(() => {
    if (esFeedState.length) {
      const element = document.getElementById("es-scrollable-block");
      element?.addEventListener('scroll', handleEmergenciesListScroll);
      return () => element?.removeEventListener('scroll', handleEmergenciesListScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen.value, esFeedState]);

  useEffect(() => {
    if (responseEmergencyTypes?.results?.length) {
      const arr = responseEmergencyTypes.results.map(el => el.id);
      setEsTypesChecked(arr);
      setSelectAll(true);
    }
  }, [responseEmergencyTypes]);

  useEffect(() => {
    const query: { [key: string]: string } = { page_size: '20' };
    if (dateRange.start && dateRange.end) {
      query.date_emergency__gte = JSON.parse(JSON.stringify(new Date(dateRange.start).toISOString().split('T')[0]));
      query.date_emergency__lte = JSON.parse(JSON.stringify(new Date(dateRange.end).toISOString().split('T')[0]));
    }
    if (!selectAll) query.type_emergency = esTypesChecked.join(',');
    if (!esTypesChecked.length && !selectAll) query.type_emergency = '0';
    if (state.region) query.region = state.region;
    if (state.district) query.district = state.district;
    if (state.reportType) query.type_report = state.reportType;
    if (state.status) query.status = state.status;
    if (state.reportStatus) query.late_information = state.reportStatus;
    setQueryState(makeQuery.stringify(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, esTypesChecked, dateRange]);

  const isLoading = useMemo(() => {
    return (typesLoading || emergenciesLoading || regionsLoading || usersLoading || emergenciesFetching || districtsLoading);
  }, [typesLoading, emergenciesLoading, regionsLoading, usersLoading, emergenciesFetching, districtsLoading]);

  const dateTimeRangePickerValue = useMemo(() => {
    if (String(new Date(String(dateRange.start))).toLowerCase() !== 'invalid date' && String(new Date(String(dateRange.end))).toLowerCase() !== 'invalid date') {
      return {
        start: new Date(String(dateRange.start)),
        end: new Date(String(dateRange.end))
      };
    }
    return undefined;
  }, [dateRange]);

  const filterValidate = (data: esFeed) => {
    if (!esTypesChecked.includes(data.type_emergency)) return null;
    if (state.region && state.region !== String(data.region)) return null;
    if (state.reportType && state.reportType !== data.type_report) return null;
    if (state.status && state.status !== data.status) return null;
    if (state.reportStatus && state.reportStatus !== String(data.late_information)) return null;
    return data;
  };

  const handleSelectDates = (value: { start: Date; end: Date }) => {
    setDateRange(value);
    setDateRangeVisible(false);
  }

  const handleEmergenciesListScroll = () => {
    try {
      let position = window.document.getElementById(isMenuOpen.value)?.getBoundingClientRect().y;

      let element = document.getElementById("es-scrollable-block");

      let pageCenter = ((element?.clientHeight || 0) + 420) / 2;

      setDropdownPosition((position || 0) < pageCenter ? 'bottom' : 'top');
    } catch (e) {
      // do nothing
    }

    try {
      let position = window.document.getElementById('feed_bottom')?.getBoundingClientRect().y;

      let element = document.getElementById("es-scrollable-block")
      let elementPosition = element?.getBoundingClientRect().y;

      const lastEntryDate = dayjs(esFeedState[esFeedState.length - 1].date_emergency).format('YYYY-MM-DDTHH:mm:ss');

      if ((position! - elementPosition!) <= element?.clientHeight! && !emergenciesLoading && lastQueryDate !== lastEntryDate) {
        setLastQueryDate(lastEntryDate || '');
        getEmergencyFunc(queryState, lastEntryDate);
      }
    } catch (e) {
      // do nothing
    }
  }

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let arr = [...esTypesChecked];
    let index;
    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      index = arr.findIndex(el => el === id);
      arr.splice(index, 1);
    }
    if (responseEmergencyTypes?.results?.length !== arr.length) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }

    setEsTypesChecked(arr);
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    setSelectAll(!selectAll)
    let arr: any[] = []
    if (checked) {
      responseEmergencyTypes?.results.forEach(el => {
        arr.push(el.id)
      })
      setEsTypesChecked(arr);
    } else {
      setEsTypesChecked([]);
    }
  }

  const handleSelect = (e: any, name: string) => {
    if (e?.value) {
      setState(prevState => {
        return {...prevState, [name]: e.value}
      })
    } else {
      setState(prevState => {
        return {...prevState, [name]: ''}
      })
    }
  }

  const handleOpenMenu = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
    e.stopPropagation();
    let obj = {...isMenuOpen}

    let position = window.document.getElementById(String(id))?.getBoundingClientRect().y;

    let element = document.getElementById("es-scrollable-block");

    let pageCenter = ((element?.clientHeight || 0) + 420) / 2;
    if (String(id) === obj.value) {
      setMenuOpen({value: String(id), isOpen: !isMenuOpen.isOpen})
    } else {
      setDropdownPosition((position || 0) < pageCenter ? 'bottom' : 'top');
      setMenuOpen({value: String(id), isOpen: true})
    }
  }

  const handleCloseMenu = () => {
    setMenuOpen({value: '', isOpen: false})
  }

  const handleRedirectToCreate = () => {
    navigate({pathname: '/es-report/create'})
  };

  return (
    <div className={emergenciesFetching ? 'page-loading' : ''}>
      <div className="pb-3 mb-2 title-divider flex">
        <h1>{t('nav.ES feed')}</h1>
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={handleRedirectToCreate}
        >
          {t('esBase.createEs')}
        </CButton>
      </div>

      <div className="mb-2">
        <LoaderLine visible={isLoading}/>
      </div>

      <div style={{display: 'flex'}}>
        <div className="es-columns es-type-column desktop-only">
          <div className="es-type-column__header">
            <div className="es-type-column__title">{t("esFeed.filterBy")}:</div>
            <CFormCheck id={'select-all'}
                        style={{marginRight: '8px'}}
                        name={'select-all'}
                        onChange={handleSelectAll}
                        checked={selectAll}
            />
            <label htmlFor={'select-all'}>{t("esFeed.markAll")}</label>
          </div>
          <div className="es-scrollable-block">
            {
              responseEmergencyTypes?.results?.length ?
                <EmergencyTypesList data={responseEmergencyTypes?.results} state={esTypesChecked}
                                    onChange={handleChangeChecked}/> :
                null
            }
          </div>
        </div>
        <div className="es-columns es-column es-feed-mobile" onClick={handleCloseMenu}>
          <div className="filter-block-wrapper">
            <div style={{flex: 1}}>
              <FilterBlock
                regions={regions!}
                districts={districts!}
                onSelect={handleSelect}
                state={state}
                t={t}
              />
            </div>
            <div className="d-flex justify-content-md-end mb-3 desktop-only" style={{position: 'relative'}}>
              <div style={{minWidth: '200px'}}>
                <CFormLabel>{t('Select dates')}</CFormLabel>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  {/*@ts-ignore*/}
                  <CFormInput style={{borderRadius: '6px', fontSize: '12px', minHeight: '38px'}}
                              type="text"
                              value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                              placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                              onClick={() => setDateRangeVisible(true)}
                  />
                </div>

              </div>
              {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
                setDateRangeVisible(false)
              }}/>}
              {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
                <DateTimeRangePicker locale={i18n.language} onChange={handleSelectDates}
                                     value={dateTimeRangePickerValue} presets/>
              </div> : null}
            </div>
          </div>

          {
            (esFeedState.length) ?
              <EmergencyList data={esFeedState}
                             onClick={handleOpenMenu}
                             isMenuOpen={isMenuOpen}
                             menuPosition={dropdownPosition}
                             users={users}
                             emergencyTypes={responseEmergencyTypes}
              /> :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default EsFeed;
