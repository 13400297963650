import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

import ShareIcon from "../../../components/icons/ShareIcon";
import whatsappIcon from "../../../assets/socials/whatsapp.png";
import {IUser} from "../../../interfaces/IUsers";
import config from "../../../config";

interface IEmergencyCard {
  item: any
  onClick: (e: React.MouseEvent<HTMLDivElement>, id: number) => void
  isMenuOpen: any
  menuPosition: string
  users: any
  icon: string
}

const EmergencyCard: FC<IEmergencyCard> = ({item, onClick, isMenuOpen, menuPosition, users, icon}) => {
  const { t } = useTranslation();

  const esUrl = config.baseUrl + '/es-base/detail/feed/' + item.id;

  return (
    <div className="emergency-card">
      <div className="emergency-card__header">
        <div>
          <h3 className="emergency-card__title">
            <a href={`/es-base/detail/feed/${item.id}`}
               target="_blank"
               rel="noopener noreferrer"
               className="es-base__title"
            >{item.title}</a>
          </h3>
        </div>
        <div id={item.id} style={{position: 'relative'}} onClick={(e) => onClick(e, item.id)}>
          <span style={{cursor: 'pointer'}}>
            <ShareIcon active={Number(isMenuOpen.value) === item.id && isMenuOpen.isOpen}/>
          </span>
          {
            Number(isMenuOpen.value) === item.id && isMenuOpen.isOpen ?
              <div
                className={menuPosition === "top" ? "emergency-card__menu emergency-card__menu-top" : "emergency-card__menu emergency-card__menu-bottom"}
                onClick={e => e.stopPropagation()}
              >
                <div className="emergency-card__menu-header">
                  <strong>{t("esFeed.shareAnEmergency")}</strong>
                  <img src={whatsappIcon} alt="whatsapp"/>
                </div>
                <div className="emergency-card__menu-body-wrapper">
                {users?.results?.length ? users?.results?.map((user: IUser) => {
                    if (user.phone_number) {
                      return (
                        <div key={'userItem' + user.id} className="menu-row">
                          <a href={`https://api.whatsapp.com/send?phone=${user.phone_number}&text=${esUrl}`}
                             target="_blank"
                             rel="noopener noreferrer">
                            {(user.position?.name || '') + ' ' + user.first_name + ' ' + user.last_name}
                          </a>
                        </div>
                      );
                    } else return null;
                  }) : null}
                </div>
              </div> :
              null
          }
        </div>
      </div>

      <div className="emergency-card__body">
        <div className="emergency-card__body-header"
             style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginRight: '10px'}}>
                <img alt={''} src={icon}
                     style={{width: '32px', height: '37px'}}
                />
              </div>
              <div>
                <div>
                  <strong style={{marginRight: '10px'}}>{t("Created")}:</strong>
                  <span>{item.created_by}</span>
                </div>
                <div>
                  <strong style={{marginRight: '10px'}}>{t('emergency.dateOfEmergency')}:</strong>
                  <span>{dayjs(item?.date_emergency).format('DD.MM.YYYY HH:mm')}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="emergency-card__indicators-wrapper">
            <span className={`emergency-card__indicators__${item.late_information ? "danger" : "success"}`}>
              {item.late_information ? t("esFeed.informationWithDelay") : t("esFeed.informationWithoutDelay")}
            </span>
            <span className={`emergency-card__indicators__${item.status === "in processing" ? "danger" : "success"}`}
                  style={{marginLeft: '15px'}}
            >
              {item.status === "in processing" ? t("esFeed.inProcess") : t("esFeed.completed")}
            </span>
          </div>
        </div>
        <div style={{overflow: 'hidden', maxHeight: '75px', textOverflow: 'ellipsis'}}>
          {item.description}
        </div>
      </div>
    </div>
  );
};

export default EmergencyCard;
