import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {ILog, ILogDetails} from "../../interfaces/ILogs";
import {IPaginationResponse} from "../models";

export const logsApi = createApi({
    reducerPath: 'logs/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    endpoints: build => ({
        getLogs: build.query<IPaginationResponse<ILog[]>, string>({
            query: (query: string) => {
                return {
                    url: `/logs/${query || ''}`
                }
            }
        }),
        getLog: build.query<ILogDetails, string | number>({
            query: (id: string) => ({
                url: `/logs/${id}/`
            })
        }),
    })
})

export const {
    useLazyGetLogsQuery,
    useLazyGetLogQuery
} = logsApi;
