import React, {ChangeEvent, useMemo, useState} from 'react';
import {CButton, CFormCheck, CFormLabel, CFormSelect, CSpinner} from '@coreui/react';
import {useTranslation} from 'react-i18next';

import makeQuery from '../../../functions/makeQuery';
import {useLazyGenerateReportQuery} from '../../../store/esForm/esForm';

const Es1Form = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getUTCFullYear();

  const [generateReport, {isLoading: generateReportLoading}] = useLazyGenerateReportQuery();

  const [radioValue, setRadioValue] = useState("first_half");
  const [selectValueYear, setSelectValueYear] = useState("2024");
  const [isLoading, setIsLoading] = useState(false);

  const selectYearsOptions = useMemo(() => {
    const res = [];
    for (let i = currentYear; i >= 2016; i--) {
      res.push({value: i.toString(), label: i.toString()});
    }
    return res;
  }, [currentYear]);

  const generateDataHandler = async () => {
    setIsLoading(true);
    try {
      const result = await generateReport(makeQuery.stringify({term: radioValue, year: selectValueYear}));
      if (result?.data?.url) {
        const element = document.createElement('a');
        element.setAttribute('href', result.data.url);
        element.setAttribute('target', '_blank');
        element.click();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeSelectHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectValueYear(event.target.value);
  };

  const onChangeRadioHandler = (event: string) => {
    setRadioValue(event);
  };

  return (
    <>
      <div className='pb-3 mb-4 title-divider'>
        <h1>{t('nav.ES1 form')}</h1>
      </div>
      <div className='d-flex d-flex flex-column justify-content-md-start mb-3'>
        <div className='d-flex flex-column'>
          <CFormLabel style={{fontWeight: 'bold'}}>{t('esForm.period')}</CFormLabel>
          <CFormCheck
            type="radio"
            id="first_half"
            label={t('esForm.first_half')}
            checked={radioValue === "first_half"}
            onChange={() => onChangeRadioHandler('first_half')}
          />
          <CFormCheck
            type="radio"
            id="second_half"
            label={t('esForm.second_half')}
            checked={radioValue === "second_half"}
            onChange={() => onChangeRadioHandler('second_half')}
          />
          <CFormCheck
            type="radio"
            id="full_half"
            label={t('esForm.full_half')}
            checked={radioValue === "full_half"}
            onChange={() => onChangeRadioHandler('full_half')}
          />
          <div className={'mb-3 mt-3 w-10'}>
            <CFormLabel style={{fontWeight: 'bold'}}>{t("esForm.year")}</CFormLabel>
            <CFormSelect
              style={{width: '110px'}}
              value={selectValueYear}
              options={selectYearsOptions}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => onChangeSelectHandler(e)}
            />
            <CButton
              disabled={generateReportLoading || isLoading}
              className='mt-3'
              onClick={generateDataHandler}
            >
              {isLoading ? <CSpinner size="sm"/> : t('Download report')}
            </CButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Es1Form;
