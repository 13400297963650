import React, {FC, useState} from 'react';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

import {statusMessagesES} from "./PublicEsReports";
import GalleryModal from "../../../components/UI/Modals/GalleryModal";
import {getParamsOfFileByUrl} from "../../../functions";

interface ICard {
    item: any;
    onClick: (e: React.MouseEvent<HTMLDivElement>, id: number, status: string) => void;
}

const PublicEsReportsCard: FC<ICard> = ({item, onClick}) => {
    const { t } = useTranslation();
    const url = 'https://new.mes.yorc.org/reports/';

    const [showGalleryModal, setShowGalleryModal] = useState<number | null>(null);

    const onToggleGalleryModal = (value: number | null = null) => {
        setShowGalleryModal(value);
    };

    return (
        <div className="emergency-card">
            <div className="emergency-card__header">
                <div>
                    <h3 className="emergency-card__title public-es-report-title">
                        {t(`esFeed.esReportTypeTitle`)}: {item?.es_type || 'n/a'}
                    </h3>
                </div>
            </div>

            <div className="emergency-card__body">
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <div>
                                    <strong style={{marginRight: '10px'}}>{t("profile.phone")}:</strong>
                                    <span>{item.phone_number}</span>
                                </div>
                                <div>
                                    <strong style={{marginRight: '10px'}}>{t('Date of creation')}:</strong>
                                    <span>{dayjs(item?.created_at).format('DD.MM.YYYY HH:mm')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {item?.status !== statusMessagesES.processed && item?.status !== statusMessagesES.rejected ?
                            <>
                                <span className={`emergency-card__indicators__processed-created`}
                                      style={{cursor: "pointer"}}
                                      onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick(e, item.id, statusMessagesES.processed)}>
              {t('esFeed.statusProcessed')}
            </span>
                                <span
                                    className={`emergency-card__indicators__rejected-created`}
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick(e, item.id, statusMessagesES.rejected)}
                                    style={{marginLeft: '15px', cursor: "pointer"}}
                                >
              {t('esFeed.statusRejected')}
            </span>
                            </> :
                            item.status === statusMessagesES.processed ?
                                <span className={`emergency-card__indicators__processed`}
                                >
              {t('esFeed.statusProcessedDone')}
            </span>
                                :
                                <span
                                    className={`emergency-card__indicators__rejected`}
                                >
              {t('esFeed.statusRejectedDone')}
            </span>
                        }

                    </div>
                </div>
                <div style={{overflow: 'hidden', maxHeight: '75px', textOverflow: 'ellipsis'}}>
                    {item.description}
                </div>
                <div>
                    {
                        <div className="mb-3">
                            <div style={{
                                display: "flex",
                                flexWrap: 'wrap',
                                gap: '8px',
                                marginTop: '20px',
                                alignItems: 'center'
                            }}>
                                {item?.attachments?.map((item: any, i: number) => {
                                    const fileParams = getParamsOfFileByUrl(item.name);
                                    let innerJSX;
                                    if (fileParams.type === 'image') {
                                        innerJSX = (
                                          <img alt={""}
                                               src={url + item.name}
                                               style={{height: '110px', width: 'auto'}}
                                          />
                                        );
                                    }
                                    if (fileParams.type === 'video') {
                                        innerJSX = (
                                          <video id="video" width="auto" height="110">
                                              <source src={url + item.name} type={`${fileParams.type}/${fileParams.ext}`}/>
                                          </video>
                                        );
                                    }
                                    if (fileParams.type === 'audio') {
                                        innerJSX = (
                                          <i className="fa fa-file-audio-o" aria-hidden="true" style={{fontSize: '110px'}}/>
                                        );
                                    }
                                    return (
                                      <div key={i + '_pic'} className="image-wrapper" style={{cursor: 'pointer'}}
                                           onClick={() => setShowGalleryModal(i)}>
                                          {innerJSX}
                                      </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>

            <GalleryModal show={!!showGalleryModal || showGalleryModal === 0}
                          toggle={onToggleGalleryModal}
                          data={item?.attachments?.map((el: any) => ({file: url + el.name})) || []}
                          startIndex={showGalleryModal || 0}
            />
        </div>
    );
};

export default PublicEsReportsCard;
