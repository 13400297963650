import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
  CFormInput, CFormLabel,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer';
import dayjs from "dayjs";

import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import DateTimeRangePicker, {TDateTimeRange} from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

import {Query} from "../../../functions";
import {ILog} from "../../../interfaces/ILogs";
import {useLazyGetLogQuery, useLazyGetLogsQuery} from "../../../store/logs/logs.api";

const AuditLog = () => {
  const { t } = useTranslation();

  const [fetchLogs, {data: logsList, isLoading: loadingEntries, isFetching: fetchingEntries}] = useLazyGetLogsQuery();
  const [readLogEntry, {data: logEntry, isLoading: loadingEntry, isFetching: fetchingEntry}] = useLazyGetLogQuery();

  const [pagination, setPagination] = useState({ page_size: '100', search: '', page: 1 });
  const [filter, setFilter] = useState({sort_by: 'datetime', direction: 'desc'});
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const [detailsModalTitle, setDetailsModalTitle] = useState('');
  const [dateRange, setDateRange] = useState<TDateTimeRange>({start: null, end: null});
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  const countLogsList = logsList?.results?.length;

  useEffect(() => {
    const range = {date_emergency__gte: '', date_emergency__lte: ''}
    if (dateRange.start && dateRange.end) {
      range.date_emergency__gte = JSON.parse(JSON.stringify(dateRange.start));
      range.date_emergency__lte = JSON.parse(JSON.stringify(dateRange.end));
    }
    fetchLogs(Query.stringify({...pagination, ...filter, ...range}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filter, dateRange]);

  const isLoading = useMemo(() => {
    return loadingEntries || fetchingEntries;
  }, [loadingEntries, fetchingEntries]);

  const toggleDetailsModalHandler = async (visible: boolean, id: number) => {
    if (id && logEntry?.id !== id) readLogEntry(id);
    if (id) {
      const entry = logsList?.results?.find((el: ILog) => el.id === id);
      if (entry) setDetailsModalTitle(`${dayjs(entry.datetime).format('DD.MM.YYYY HH:mm:ss')} / ${entry.user?.username || ''}`);
      else setDetailsModalTitle('');
    }
    else setDetailsModalTitle('');
    setDetailsModalVisible(visible);
  };

  const handleSelectDates = (value: { start: Date; end: Date }) => {
    setDateRange(value);
    setDateRangeVisible(false);
  };

  const handleGetValue = (value: { start: Date; end: Date }) => {
    setDateRange(value);
  };

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};
    if(countLogsList && countLogsList <= Number(pagination.page_size)) {
      innerState = {
        page_size: pagination.page_size,
        search: pagination.search,
        page: 1
        , ...state}
    }
    setPagination(innerState);
    if (state.search === undefined) onSearchDataHandler({...innerState, ...filter});
  };

  const onFilterHandler = (field: any) => {
    const state = {...filter};
    if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.direction = 'asc';
    }
    setFilter(state);
    onSearchDataHandler({...pagination, ...state});
  };

  const onSearchDataHandler = (data: any) => {
    const query: {[key: string]: any} = {};

    if (data.page_size) query.page_size = data.page_size;
    if (data.page) query.page = data.page;
    if (data.search && data.search !== '') query.search = data.search;
    if (data.sort_by) query.sort_by = data.sort_by;
    if (data.direction === 'desc') query.direction = 'desc';

    fetchLogs(Query.stringify(query))
  }

  const list = logsList?.results?.map((el: any, idx: number) => (
    <CTableRow key={'log' + idx}>
      <CTableDataCell>{el.log_type}</CTableDataCell>
      <CTableDataCell>{dayjs(el.datetime).format('DD.MM.YYYY HH:mm:ss')}</CTableDataCell>
      <CTableDataCell>{el.table_name}</CTableDataCell>
      <CTableDataCell>
        {`${el.user.username} - ${el.user.last_name || ''} ${el.user.first_name || ''}`}
      </CTableDataCell>
      <CTableDataCell className="text-center">
        <span className="text-primary cursor-pointer" onClick={() => toggleDetailsModalHandler(true, el.id)}>
          {t('settings.auditLog.viewChanges')}
        </span>
      </CTableDataCell>
    </CTableRow>
  )) || [];

  let oldCode = '', newCode = '';
  try {
    oldCode = JSON.stringify(logEntry?.changes.initial, null, '\t');
    newCode = JSON.stringify(logEntry?.changes.updated, null, '\t');
  } catch (e) {}

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{t('settings.auditLog.chronology')}</h1>
        <LoaderLine visible={isLoading}/>
      </div>

      <div className="d-flex justify-content-md-end mb-3" style={{position: 'relative'}}>
        <div style={{width: '305px'}}>
          <CFormLabel>Выберите даты</CFormLabel>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <CFormInput style={{borderRadius: '6px', width: '305px'}}
                        type="text"
                        value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                        placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                        onClick={() => setDateRangeVisible(true)}
            />
          </div>
        </div>
        {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
          setDateRangeVisible(false)
        }}/>}
        {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
          <DateTimeRangePicker onChange={handleSelectDates} getValue={handleGetValue}/>
        </div> : null}
      </div>

      <DefaultPaginationWrapper data={logsList}
                                state={pagination}
                                showSearch={false}
                                onChange={onPaginationHandler}
      >
        <CTable bordered striped className="default-table">
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell style={{width: '120px', maxWidth: '120px'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('log_type')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('settings.auditLog.event')}</span>
                  {filter.sort_by === 'log_type' ?
                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '25%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('datetime')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('settings.auditLog.when')}</span>
                  {filter.sort_by === 'datetime' ?
                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '25%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('table_name')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('settings.auditLog.collection')}</span>
                  {filter.sort_by === 'table_name' ?
                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '25%'}}
                                scope="col"
                                className="sorted-table-cell"
                                onClick={() => onFilterHandler('user')}
              >
                <div className="d-flex justify-content-between">
                  <span>{t('settings.auditLog.user')}</span>
                  {filter.sort_by === 'user' ?
                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                    <i className="fa fa-sort" aria-hidden="true"/>
                  }
                </div>
              </CTableHeaderCell>

              <CTableHeaderCell style={{width: '120px', maxWidth: '120px'}}
                                scope="col"
                                className="default-table__actions"
              >
                {t('settings.auditLog.changes')}
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>

          <CTableBody>
            {list}
          </CTableBody>
        </CTable>
      </DefaultPaginationWrapper>

      <DefaultModal visible={detailsModalVisible}
                    setVisible={toggleDetailsModalHandler}
                    backdrop={true}
                    title={detailsModalTitle}
                    cancelButton={t('close')}
                    loading={loadingEntry || fetchingEntry}
                    size='xl'
      >
        <ReactDiffViewer oldValue={oldCode}
                         newValue={newCode}
                         splitView={true}
                         compareMethod={DiffMethod.LINES}
        />
      </DefaultModal>
    </>
  );
};

export default AuditLog;
