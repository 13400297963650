import React, {useEffect, useMemo, useState} from 'react';
import {
    CButton, CButtonGroup, CCard, CCardBody, CCardHeader,
    CCollapse,
    CFormInput, CFormLabel, CFormSelect, CFormTextarea,
    CTable,
    CTableBody, CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";

import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import FormGroup from "../../../components/UI/Forms/FormGroup";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import {useTranslation} from "react-i18next";
import {
    useLazyGetGeoIconsQuery,
} from "../../../store/geoIcons/geoIcons.api";
import {
    useCreateEmergencyTypeMutation,
    useLazyGetEmergencyTypesQuery, useRemoveEmergencyTypeMutation,
    useUpdateEmergencyTypeMutation
} from "../../../store/emergencyTypes/emergencyTypes.api";
import makeQuery from "../../../functions/makeQuery";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";

const fieldTypeOptions = [
    {label: 'Boolean', value: 'boolean'},
    {label: 'Number', value: 'number'},
    {label: 'String', value: 'string'},
    {label: 'Textarea', value: 'textarea'},
    // {label: 'Image', value: 'image'},
    {label: 'Date', value: 'date'},
    {label: 'Time', value: 'time'},
    {label: 'DateTime', value: 'datetime'},
];

const EsTypes = () => {
    const {t} = useTranslation();

    const [fetchGeoIcons, {data: geoIcons, isLoading: fetchGeoIconsLoading}] = useLazyGetGeoIconsQuery();
    const [fetchEmergencyTypes, {
        isLoading: fetchEmergencyTypesLoading,
        isFetching: fetchEmergencyTypesFetching,
        data: emergencyTypes
    }] = useLazyGetEmergencyTypesQuery();
    const [createEmergencyType, {isLoading: createEmergencyTypeLoading}] = useCreateEmergencyTypeMutation();
    const [updateEmergencyType, {isLoading: updateEmergencyTypeLoading}] = useUpdateEmergencyTypeMutation();
    const [deleteEmergencyType, {isLoading: deleteEmergencyTypeLoading}] = useRemoveEmergencyTypeMutation();

    const [pagination, setPagination] = useState({page_size: '100', search: '', page: 1});
    const [filter, setFilter] = useState({sort_by: 'name', direction: 'asc'});
    const [visible, setVisible] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [collapseVisible, setCollapseVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [state, setState]: any = useState({typeOfEmergency: '', typeOfEmergencyDescription: '', icon: null});
    const [fields, setFields] = useState([
        {_id: 1, options: fieldTypeOptions, textInput: '', select: ''},
    ]);
    const [fieldsForRemove, setFieldsForRemove] = useState([]);
    const [validateError, setValidateError] = useState('');
    const [additionalVisible, setAdditionalVisible] = useState<number[]>([]);
    const [okIsHover, setOkIsHover] = useState<boolean>(false);
    const countEmergencyTypes = emergencyTypes?.results.length;

    useEffect(() => {
        fetchGeoIcons(makeQuery.stringify({page_size: 100000}));
        fetchEmergencyTypes(makeQuery.stringify(filter));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isLoading = useMemo(() => {
        return (fetchGeoIconsLoading || fetchEmergencyTypesLoading || fetchEmergencyTypesFetching || createEmergencyTypeLoading || updateEmergencyTypeLoading || deleteEmergencyTypeLoading);
    }, [fetchGeoIconsLoading, fetchEmergencyTypesLoading, fetchEmergencyTypesFetching, createEmergencyTypeLoading, updateEmergencyTypeLoading, deleteEmergencyTypeLoading]);

    const handleChange = (event: any) => {
        setState((prevState: any) => {
            return {...prevState, [event.target.name]: event.target.value}
        });
    }

    const handleChangeField = (event: any, id: number) => {
        const tempFields: any[] = [...fields];
        tempFields[tempFields.findIndex(el => el._id === id)][event.target.name] = event.target.value;
        setFields(tempFields)
    }

    const handleToggleModal = (visible: boolean) => {
        let arr: any[] = [];
        setTimeout(() => {
            if (visible === false) {
                arr.push({_id: 1, options: fieldTypeOptions, textInput: '', select: ''});
                setFields(arr);
                setSelectedId(null);
                setState({typeOfEmergency: '', icon: null});
                setFieldsForRemove([]);
                setValidateError('');
            }
        }, 200)
        setVisible(visible);
    }

    const handleToggleDeleteModal = (visible: boolean) => {
        if (visible === false) {
            setValidateError('');
            setSelectedId(null);
        }
        setDeleteModal(visible)
    }

    const handleEdit = async () => {
        let data, emergencyFields: any[] = [];

        if (fields?.length) {
            emergencyFields = fields.map((el: any) => {
                const data: any = {
                    type: el.select,
                    name: el.textInput
                }
                if (el.id) data.id = el.id
                return data;
            })
        }

        data = {
            name: state.typeOfEmergency,
            description: state.typeOfEmergencyDescription,
            geo_icon: Number(state.icon),
            types_field: emergencyFields
        }

        for (const item of Object.keys(state)) {
            if (!state[item]) {
                setValidateError(t('allFieldsValidate'));
                return null
            }
        }

        for (const element of fields) {
            if (!element.textInput || !element.select) {
                setValidateError(t('allFieldsValidate'));
                return null
            }
        }

        if (selectedId) {
            if (fieldsForRemove.length) {
                //@ts-ignore
                data.delete_types_fields = fieldsForRemove
            }
            updateEmergencyType({body: data, id: selectedId})
              .then((res) => {
                  // @ts-ignore
                  const error = res?.data?.error || res?.error?.data?.error;
                  if (error) {
                      setValidateError(error);
                      return null;
                  }
                  setValidateError('');
                  handleToggleModal(false);
              })
              .catch((error) => {
                  setValidateError(error?.data?.error || t('allFieldsValidate'));
              });
        } else {
            createEmergencyType(data)
              .then((res) => {
                  // @ts-ignore
                  const error = res?.data?.error || res?.error?.data?.error;
                  if (error) {
                      setValidateError(error);
                      return null;
                  }
                  setValidateError('');
                  handleToggleModal(false);
              })
              .catch((error) => {
                  setValidateError(error?.data?.error || t('allFieldsValidate'));
              });
        }
    }

    const handlePickIcon = (id: number) => {
        setState({...state, icon: id});
        setCollapseVisible(false);
    }

    const handleManageFields = (action: string, index: any, item?: any) => {
        let arrayOfFields = [...fields], object: any;
        if (action === 'add') {
            if (arrayOfFields.length === 0) {
                arrayOfFields.push({_id: 1, options: fieldTypeOptions, textInput: '', select: ''});
                setFields(arrayOfFields);
            } else {
                object = {_id: arrayOfFields[arrayOfFields.length - 1]._id + 1, options: fieldTypeOptions};
                object.textInput = '';
                object.select = '';
                arrayOfFields.push(object);
                setFields(arrayOfFields);
            }
        }
        if (action === 'remove') {
            const newFields = [...fields];
            newFields.splice(index, 1);
            setFields(newFields);
        }
    }

    const handleOpenEditModal = (visible: boolean, item: any) => {
        setValidateError('');
        let emergencyFields = [];
        setState({typeOfEmergency: item.name, typeOfEmergencyDescription: item.description, icon: item.geo_icon?.id})
        setSelectedId(item.id)
        if (Object.keys(item).length && item.field_type_emergency.length) {
            emergencyFields = item.field_type_emergency.map((el: any, idx: number) => {
                return {
                    _id: idx + 1,
                    id: el.id,
                    options: fieldTypeOptions,
                    textInput: el.name,
                    select: el.type
                }
            })
            setFields(emergencyFields);
        }
        setVisible(visible);
    }

    const handleOpenDeleteModal = (id: any) => {
        setValidateError('');
        setDeleteModal(true);
        setSelectedId(id);
    }

    const handleDelete = () => {

        // do nothing
        if (String(selectedId) !== 'this id can not be exist') return null;

        deleteEmergencyType(Number(selectedId))
          .then((res) => {
              // @ts-ignore
              const error = res?.data?.error || res?.error?.data?.error;
              if (error) {
                  setValidateError(error);
                  return null;
              }
              setValidateError('');
              setDeleteModal(false);
          })
          .catch(error => {
              setValidateError(error?.data?.error || t('Something went wrong'));
          });
    };

    const onPaginationHandler = (state: any) => {
        let innerState = {...pagination, ...state};

        if(countEmergencyTypes && countEmergencyTypes <= Number(pagination.page_size)) {
            innerState = {
                page_size: pagination.page_size,
                search: pagination.search,
                page: 1
                , ...state}
        }

        setPagination(innerState);
        onSearchDataHandler({...innerState, ...filter});
    };

    const onFilterHandler = (field: string) => {
        const state = {...filter};
        if (field === state.sort_by) state.direction = state.direction === 'asc' ? 'desc' : 'asc';
        else {
            state.sort_by = field;
            state.direction = 'asc';
        }
        setFilter(state);
        onSearchDataHandler({...pagination, ...state});
    };

    const onSearchCallbackHandler = (search: string) => {
        onSearchDataHandler({...pagination, ...filter, search});
    };

    const onSearchDataHandler = (data: any) => {
        const query: any = {};
        if (data.page_size) query.page_size = data.page_size;
        if (data.page) query.page = data.page;
        if (data.search && data.search !== '') query.search = data.search;
        if (data.sort_by) query.sort_by = data.sort_by;
        if (data.direction === 'desc') query.direction = 'desc';
        else query.direction = 'asc';

        // dispatch(fetchEmergencyTypes(Query.stringify(query)))
        fetchEmergencyTypes(makeQuery.stringify(query))
    }

    const handleClickToAdditionalInformation = (id: number) => {
        const innerState = [...additionalVisible];
        if (innerState.includes(id)) innerState.splice(innerState.findIndex(el => el === id), 1);
        else innerState.push(id);
        setAdditionalVisible(innerState);
    };

    const okHoverHandler = (isHover: boolean = false) => {
        setOkIsHover(isHover);
    };

    const formValidate = () => {
        let isValid = !!(state.icon && state.typeOfEmergency && state.typeOfEmergencyDescription);
        fields.forEach(item => {
            isValid = !!(isValid && item.select && item.select !== 'null' && item.textInput);
        })
        return !isValid;
    };

    let selectedIcon, emergencyTypesForTable;

    if (state.icon) {
        selectedIcon = geoIcons?.results?.findIndex((el: any) => el.id === Number(state.icon));
    }

    if (emergencyTypes?.results?.length) {
        emergencyTypesForTable = emergencyTypes.results.map((el: any) => {
            const icon = geoIcons?.results?.find(ic => ic.id === el.geo_icon?.id);
            return (
                <CTableRow key={'row' + el.id}>
                    <CTableDataCell className="text-center">
                        {icon ? <img alt='' src={icon.icon} style={{height: '37px', width: '32px'}}/> : null}
                    </CTableDataCell>
                    <CTableDataCell>
                        {el.name}
                    </CTableDataCell>
                    <CTableDataCell>
                        {el.description}
                    </CTableDataCell>
                    <CTableDataCell>
                        <div style={{background: 'transparent', padding: '8px'}}>
                            <div className={additionalVisible.includes(el.id) ? "visible-item" : "collapsable-item"}
                                 onClick={() => handleClickToAdditionalInformation(el.id)}>
                                {
                                    additionalVisible.includes(el.id) ?
                                      <i className="fa fa-chevron-down" aria-hidden="true"
                                         style={{marginRight: '10px'}}></i> :
                                      <i className="fa fa-chevron-right" aria-hidden="true"
                                         style={{marginRight: '10px'}}></i>
                                }
                                <div style={{cursor: 'pointer', fontWeight: 'bold'}}>
                                    {t("gis.geoObjects.additionalInformation")}
                                </div>
                            </div>
                            <CCollapse visible={additionalVisible.includes(el.id)}>
                                <ul style={{ marginTop: '20px', paddingLeft: '5px' }}>
                                    {el.field_type_emergency.map((elem: any) => (
                                        <li key={elem.id + '_field-type'} className="li-dot">
                                            <strong>{elem.name}:</strong> {" "} {elem.type || " - "}
                                        </li>
                                    ))}
                                    {el?.division_exists && (
                                        <li key={el.id + '_division-type'} className="li-dot">
                                            <strong>{t('settings.esTypes.fields.typeId')}:</strong> {" "}
                                            {el?.division_type || "id"}
                                        </li>
                                    )}
                                </ul>
                            </CCollapse>
                        </div>
                    </CTableDataCell>

                    <CTableDataCell className="text-center">
                        <CButtonGroup size="sm">

                            <CButton size="sm" color="info" className="default-table__action-btn"
                                     onClick={() => handleOpenEditModal(true, el)}>
                                <i className="fa fa-pencil-square-o color-white"/>
                            </CButton>
                            <CButton size="sm" color="danger" className="default-table__action-btn"
                                     onClick={() => handleOpenDeleteModal(el.id)}>
                                <i className="fa fa-times color-white" aria-hidden="true"> </i>
                            </CButton>
                        </CButtonGroup>
                    </CTableDataCell>
                </CTableRow>
            )
        })
    }

    return (
      <>
          <div className="pb-3 mb-4 title-divider flex">
              <h1>{t('settings.esTypes.esTypesList')}</h1>
              <CButton size="sm" color="info" className="color-white" onClick={() => handleToggleModal(true)}>
                  {t('settings.esTypes.addEmergencyType')}
              </CButton>
          </div>

          <div className="mb-3">
              <LoaderLine visible={isLoading}/>
          </div>

          <DefaultPaginationWrapper data={emergencyTypes}
                                    state={pagination}
                                    onChange={onPaginationHandler}
                                    onSearchDelay={2000}
                                    onSearchCallback={onSearchCallbackHandler}
          >
              <CTable bordered striped className="default-table">
                  <CTableHead>
                      <CTableRow>
                          <CTableHeaderCell style={{width: '50px'}}
                                            scope="col"
                                            className="sorted-table-cell"
                          >
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '30%'}}
                                            scope="col"
                                            className="sorted-table-cell"
                                            onClick={() => onFilterHandler('name')}
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('gis.typesOfGeoObjects.title')}</span>
                                  {filter.sort_by === 'name' ?
                                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                    <i className="fa fa-sort" aria-hidden="true"/>
                                  }
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '30%'}}
                                            scope="col"
                                            className="sorted-table-cell"
                                            onClick={() => onFilterHandler('description')}
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('settings.esTypes.description')}</span>
                                  {filter.sort_by === 'description' ?
                                    <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                                    <i className="fa fa-sort" aria-hidden="true"/>
                                  }
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '30%'}}
                                            scope="col"
                                            className="sorted-table-cell"
                          >
                              <div className="d-flex justify-content-between">
                                  <span>{t('gis.typesOfGeoObjects.fields')}</span>
                              </div>
                          </CTableHeaderCell>

                          <CTableHeaderCell style={{width: '100px'}} scope="col"
                                            className="default-table__actions text-center">
                              {t('actions')}
                          </CTableHeaderCell>
                      </CTableRow>
                  </CTableHead>
                  <CTableBody>
                      {emergencyTypesForTable}
                  </CTableBody>
              </CTable>
          </DefaultPaginationWrapper>

          <DefaultModal visible={visible}
                        setVisible={handleToggleModal}
                        title={selectedId ? t('settings.esTypes.editEmergencyType') : t('settings.esTypes.addEmergencyType')}
                        type={'info'}
                        cancelButton={t('cancel')}
                        onOk={handleEdit}
                        onOkHover={okHoverHandler}
                        okDisabled={formValidate()}
                        size="xl"
                        error={validateError}
          >

              <FormGroup className="main-label" htmlFor="emergencyType" label={t('settings.esTypes.emergencyType')}>
                  <CFormInput id="emergencyType"
                              type="text"
                              className={`${(okIsHover && !state.typeOfEmergency) ? 'form-control--alert' : ''}`}
                              value={state.typeOfEmergency}
                              name="typeOfEmergency"
                              onChange={handleChange}
                              size="sm"
                  />
              </FormGroup>

              <FormGroup className="main-label" htmlFor="emergencyTypeDescription"
                         label={t("settings.esTypes.description")}>
                  <CFormTextarea id="emergencyTypeDescription"
                                 name="typeOfEmergencyDescription"
                                 className={`${(okIsHover && !state.typeOfEmergencyDescription) ? 'form-control--alert' : ''}`}
                                 value={state.typeOfEmergencyDescription}
                                 onChange={handleChange}
                  />
              </FormGroup>

              <FormGroup className="main-label" htmlFor="typeOfGeoObject" label={t('gis.typesOfGeoObjects.icon')}>
                  <div>
                      <CButton color="info"
                               className={`${(okIsHover && !selectedIcon) ? 'form-control--alert' : ''}`}
                               size="sm"
                               href="#"
                               shape="rounded-0"
                               onClick={(event) => {
                                   event.preventDefault()
                                   setCollapseVisible(!collapseVisible)

                               }}>
                          {t('gis.typesOfGeoObjects.selectIcon')} <i
                        className={`fa fa-caret-${collapseVisible ? 'up' : 'down'}`} aria-hidden="true"></i>
                      </CButton>

                      {selectedIcon ?
                        <img alt=''
                             src={geoIcons?.results?.length ? geoIcons.results[selectedIcon]?.icon : ''}
                             style={{height: '37px', width: '32px', marginLeft: '15px'}}
                        />
                      : null}

                      <CCollapse visible={collapseVisible}>
                          <CCard className="mt-3">
                              <CCardBody>
                                  {geoIcons?.results?.length ? geoIcons.results.map((el: any) => {
                                      return (
                                        <img key={el.id}
                                             alt=''
                                             src={el.icon}
                                             style={{
                                                 width: '32px',
                                                 height: '37px',
                                                 marginLeft: '5px',
                                                 cursor: 'pointer'
                                             }}
                                             onClick={() => handlePickIcon(el.id)}
                                        />
                                      )
                                  }) : null}
                              </CCardBody>
                          </CCard>
                      </CCollapse>
                  </div>
              </FormGroup>

              <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                  <CCard style={{width: '100%'}}>
                      <CCardHeader className="p-0">
                          <div className="p-2 mb-0 card-header-title">
                              {t('gis.typesOfGeoObjects.fields')}
                          </div>
                      </CCardHeader>
                      {fields?.length ? fields.map((el, idx) => {
                          return (
                            <div key={idx}>
                                <div className="p-3"
                                     style={{borderBottom: '1px solid rgba(0, 0, 21, 0.125)'}}>
                                    <CButton color="info"
                                             className="color-white"
                                             size="sm"
                                             style={{height: '5%', width: '30px', margin: '0 0 15px 0'}}
                                             onClick={() => handleManageFields('remove', idx, el)}
                                    >
                                        <i className="fa fa-minus" aria-hidden="true"/>
                                    </CButton>
                                    <CCard style={{width: '100%'}}>
                                        <CCardHeader className="p-0">
                                            <div className="p-2 mb-0 card-header-title">
                                                {t('gis.typesOfGeoObjects.field')} {idx + 1}
                                            </div>
                                        </CCardHeader>
                                        <CCardBody>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <CFormLabel
                                                  className="regions-label align-items-start w-25 fw-bold">{t('gis.typesOfGeoObjects.title')}</CFormLabel>
                                                <CFormInput size="sm"
                                                            className={`${(okIsHover && !fields[idx].textInput) ? 'form-control--alert' : ''}`}
                                                            type="text"
                                                            value={fields[idx].textInput}
                                                            name='textInput'
                                                            style={{width: '90%'}}
                                                            onChange={e => handleChangeField(e, el._id)}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <CFormLabel
                                                  className="regions-label align-items-start w-25 fw-bold">{t('gis.typesOfGeoObjects.type')}</CFormLabel>
                                                <CFormSelect size="sm"
                                                             value={fields[idx].select}
                                                             name='select'
                                                             onChange={e => handleChangeField(e, el._id)}
                                                             options={[
                                                                 {
                                                                     label: t('gis.typesOfGeoObjects.selectType'),
                                                                     value: 'null'
                                                                 },
                                                                 ...el.options
                                                             ]}
                                                             className={`regions-modal-input ${(okIsHover && (fields[idx].select === 'null' || !fields[idx].select)) ? 'form-control--alert' : ''}`}
                                                             style={{width: '90%'}}
                                                />
                                            </div>
                                        </CCardBody>
                                    </CCard>
                                </div>
                            </div>
                          )
                      }) : null}
                      <CButton color="info"
                               className="color-white m-3"
                               size="sm"
                               style={{height: '5%', width: '30px'}}
                               onClick={() => handleManageFields('add', null)}
                      >
                          <i className="fa fa-plus" aria-hidden="true"/>
                      </CButton>
                  </CCard>
              </div>

          </DefaultModal>

          <DefaultModal visible={deleteModal}
                        setVisible={handleToggleDeleteModal}
                        title={t('deletion')}
                        type="danger"
                        cancelButton={t('cancel')}
                        onOk={handleDelete}
                        error={validateError}
                        size="lg"
          >
              <div>
                  <p className="mb-0 fs-6">{t('gis.typesOfGeoObjects.geoTypeDeletion')}</p>
              </div>
          </DefaultModal>

      </>
    );
};

export default EsTypes;
