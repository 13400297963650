import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

import config from "../../config";
import {headerConfig} from "../../functions/headerConfig";
import {ICreateUser, IUser, IUsers} from "../../interfaces/IUsers";
import {IPaginationResponse} from "../models";

export const usersApi = createApi({
    reducerPath: 'users/api',
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => headerConfig(headers),
    }),
    tagTypes: ['Users'],
    endpoints: build => ({
        getUsers: build.query<IPaginationResponse<IUser[]>, string | void>({
            query: (query) => {
                return {
                    url: `/list/users/${query || ''}`
                }
            },
            providesTags: ['Users'],
        }),
        getUsersById: build.query<IUser, string>({
            query: (id: string) => ({
                url: `/detail/users/${id}/`
            })
        }),
        createUsers: build.mutation<ICreateUser, any>({
            query: (body) => ({
                url: '/create/users/',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Users'],
        }),
        updateUsers: build.mutation<any, Partial<any>>({
            query: ({body, id}) => ({
                url: `/update/users/${id}/`,
                method: 'PATCH',
                body
            }),
            invalidatesTags: ['Users'],
        }),
        removeUsers: build.mutation<{ success: boolean; id: number }, number>({
            query: (id) => ({
                url: `/delete/users/${id}/`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users'],
        })
    })
})

export const {
    useGetUsersQuery,
    useLazyGetUsersQuery,
    useCreateUsersMutation,
    useUpdateUsersMutation,
    useRemoveUsersMutation,
} = usersApi;