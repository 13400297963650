import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {
  CButton,
  CButtonGroup, CCollapse, CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";

import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";

import {useGetDraftEmergenciesQuery, useRemoveEmergencyMutation} from "../../../store/emergencies/emergencies.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";
import {useGetCountiesQuery} from "../../../store/counties/counties.api";
import {useGetLocalitiesQuery} from "../../../store/localities/localities.api";
import {useGetInfoUserQuery} from "../../../store/account/account.api";

const EsProcessed = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {data: user} = useGetInfoUserQuery();
  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: counties, isLoading: countiesLoading} = useGetCountiesQuery();
  const {data: localities, isLoading: localitiesLoading} = useGetLocalitiesQuery();
  const {data: emergencies, isLoading: emergenciesLoading, isFetching: emergenciesFetching} = useGetDraftEmergenciesQuery();
  const [removeEmergency] = useRemoveEmergencyMutation();

  const [filter, setFilter] = useState({sort_by: 'date_emergency', sort_direction: 'desc'});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [visible, setVisible] = useState<any[]>([]);

  useEffect(() => {
    let array: any[] = []
    if (emergencies?.length) {
      array = emergencies.map(el => {
        return {id: el.id, value: false}
      })
      setVisible(array)
    }
  }, [emergencies]);

  const isLoading = useMemo(() => {
    return (emergenciesLoading || regionsLoading || districtsLoading || countiesLoading || localitiesLoading || emergenciesFetching);
  }, [emergenciesLoading, regionsLoading, districtsLoading, countiesLoading, localitiesLoading, emergenciesFetching]);

  const onFilterHandler = (field: string) => {
    const state = {...filter};
    if (field === state.sort_by) state.sort_direction = state.sort_direction === 'asc' ? 'desc' : 'asc';
    else {
      state.sort_by = field;
      state.sort_direction = 'asc';
    }
    setFilter(state);
  };

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleToggleDeleteModal = (visible: boolean) => {
    if (!visible) setSelectedId(null);
    setDeleteModal(visible)
  };

  const handleDelete = () => {
    if (String(user?.id) === String(selectedId))
      removeEmergency(selectedId!).then(res => {
        // @ts-ignore
        if (res?.data === null) {
          handleToggleDeleteModal(false)
        }
      });
  };

  const handleRedirectToCreate = () => {
    navigate({pathname: '/es-report/create'})
  };

  const handleRedirectToUpdate = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>, id: number) => {
    e.preventDefault();
    navigate({pathname: `/es-report/update/${id}`})
  };

  const handleClickToAdditionalInformation = (id: number) => {
    setVisible(prevState => prevState.map((el: any) => {
      if (el.id === id) {
        return {...el, value: !el.value}
      }
      return {...el}
    }))
  };

  let esList: any [] = [];

  if (emergencies?.length) {
    esList = emergencies.map((el: any, idx: number) => {
      return (
        <CTableRow key={'user' + idx}>
          <CTableDataCell>
            <a href={`/es-base/detail/base/${el.id}`}
               target="_blank"
               rel="noopener noreferrer"
               className="es-base__title">
              {el.title}
            </a>
          </CTableDataCell>
          <CTableDataCell>
            <div style={{cursor: el.description.length > 150 ? 'pointer' : 'initial'}}
                 onClick={el.description.length > 150 ? () => handleClickToAdditionalInformation(el.id) : () => {
                 }}>
              {visible[idx]?.value ? el.description :
                <span>{el.description.substring(0, 150)}{el.description.length > 150 ? '...' : ''}</span>}
            </div>
          </CTableDataCell>
          <CTableDataCell>
            {
              el.locality ?
                localities?.find(item => el.locality === item.id)?.coat_code :
                el.county ?
                  counties?.find(item => el.county === item.id)?.coat_code :
                  el.district ?
                    districts?.find(item => el.district === item.id)?.coat_code :
                    el.region ?
                      regions?.find(item => el.region === item.id)?.coat_code : ''

            }
          </CTableDataCell>
          <CTableDataCell>{dayjs(el.date_emergency).format('DD.MM.YYYY HH:mm')}</CTableDataCell>
          <CTableDataCell>{`${el.executor?.position?.name || ''} ${el.executor?.first_name || ''} ${el.executor?.last_name || ''}`}</CTableDataCell>
          <CTableDataCell>{el.type_emergency_name}</CTableDataCell>
          <CTableDataCell>
            {el.field_emergency.length ?
              <div style={{background: 'transparent', padding: '8px'}}>
                <div className={visible[idx]?.value ? "visible-item" : "collapsable-item"}
                     onClick={() => handleClickToAdditionalInformation(el.id)}>
                  {
                    visible[idx]?.value ?
                      <i className="fa fa-chevron-down" aria-hidden="true" style={{marginRight: '10px'}}></i> :
                      <i className="fa fa-chevron-right" aria-hidden="true" style={{marginRight: '10px'}}></i>
                  }
                  <div style={{cursor: 'pointer', fontWeight: 'bold'}}>
                    {t("gis.geoObjects.additionalInformation")}
                  </div>
                </div>
                <CCollapse visible={visible[idx]?.value}>
                  <ul style={{marginTop: '20px', paddingLeft: '5px'}}>
                    {el.field_emergency.map((elem: any) => {
                      const meaning: string = elem.field_type_emergency?.type === 'date' ? dayjs(elem.meaning).format('DD.MM.YYYY')
                        : elem.field_type_emergency?.type === 'datetime' ? dayjs(elem.meaning).format('DD.MM.YYYY HH:mm')
                          : elem.field_type_emergency?.type === 'boolean' ? (elem.meaning === 'true' ? t('Yes') : t('No')) : elem.meaning || ' - ';
                      return (
                        <li key={elem.id + '_emergency-type'} className="li-dot">
                          <strong>{elem.field_type_emergency.name}:</strong> {" "} {meaning}
                        </li>
                      )
                    })}
                  </ul>
                </CCollapse>
              </div>
              : null}
          </CTableDataCell>
          <CTableDataCell className="text-center">
            <CButtonGroup size="sm">
              <CButton color="info" className="default-table__action-btn"
                       onClick={(e) => handleRedirectToUpdate(e, el.id)}
              >
                <i className="fa fa-pencil-square-o color-white" aria-hidden="true"> </i>
              </CButton>
              {user?.id === el?.author?.id ?
                <CButton color="danger" className="default-table__action-btn"
                         onClick={() => handleOpenDeleteModal(el.id)}
                >
                  <i className="fa fa-times color-white" aria-hidden="true"> </i>
                </CButton>
                : null}
            </CButtonGroup>
          </CTableDataCell>
        </CTableRow>
      )
    })
  }

  return (
    <div className={emergenciesFetching ? 'page-loading' : ''}>
      <div className="pb-3 mb-4 title-divider flex">
        <h1>{t('nav.Es processed')}</h1>
        <CButton size="sm"
                 color="info"
                 className="color-white"
                 onClick={handleRedirectToCreate}
        >
          {t('esBase.createEs')}
        </CButton>
      </div>

      <div className="mb-3">
        <LoaderLine visible={isLoading}/>
      </div>

      <CTable bordered striped className="default-table" style={{minWidth: '1038px'}}>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell style={{width: '10%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => onFilterHandler('title')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('labels.title')}</span>
                {filter.sort_by === 'title' ?
                  <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '15%'}} scope="col"
                              className="sorted-table-cell"
                              onClick={() => onFilterHandler('description')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('labels.description')}</span>
                {filter.sort_by === 'description' ?
                  <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '10%'}} scope="col"
                              className="sorted-table-cell"
            >
              <div className="d-flex justify-content-between">
                <span>{t('labels.SOATE')}</span>
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '15%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => onFilterHandler('date_emergency')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('emergency.dateOfEmergency')}</span>
                {filter.sort_by === 'date_emergency' ?
                  <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '10%'}}
                              scope="col"
                              className="sorted-table-cell"
            >
              <div className="d-flex justify-content-between">
                <span>{t('labels.executor')}</span>
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '10%'}}
                              scope="col"
                              className="sorted-table-cell"
                              onClick={() => onFilterHandler('type_emergency__name')}
            >
              <div className="d-flex justify-content-between">
                <span>{t('labels.type_emergency')}</span>
                {filter.sort_by === 'type_emergency__name' ?
                  <i className={`fa fa-sort-amount-${filter.sort_direction}`} aria-hidden="true"/> :
                  <i className="fa fa-sort" aria-hidden="true"/>
                }
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell style={{width: '25%'}}
                              scope="col"
                              className="sorted-table-cell"
            >
              <div className="d-flex justify-content-between">
                <span>{t("gis.geoObjects.additionalInformation")}</span>
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell scope="col" className="default-table__actions">
              {t('settings.users.actions')}
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>

        <CTableBody>
          {esList}
        </CTableBody>
      </CTable>

      <DefaultModal visible={deleteModal}
                    setVisible={handleToggleDeleteModal}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDelete}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">{t('esBase.doYouReallyWantToDeleteAnEmergency')}</p>
          <div className="fs-6 fw-bolder">{emergencies?.find(el => el.id === selectedId)?.title}?</div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default EsProcessed;
