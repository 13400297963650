import React from "react";
import {NavLink} from "react-router-dom";
import {CSidebarNav} from "@coreui/react";

import avatar from "../assets/images/avatars/avatar.svg";
import {useGetInfoUserQuery} from "../store/account/account.api";

const Profile = () => {
    const {data: me} = useGetInfoUserQuery();

    return (
        <CSidebarNav className='sidebar-profile'>
            {me ?
                <NavLink to={'/profile'} className="profile-block">
                    <div className="profile-block-img avatar">
                        <img src={me.avatar ? me.avatar :avatar} alt="avatar" className='avatar-img'/>
                    </div>
                    <div className="profile-block-info">
                        <p className="profile-info-username">{me.last_name || ''} {me.first_name || ''} {me.patronymic || ''}</p>
                        <p className="profile-info-username">{me.position?.name || ''}</p>
                    </div>
                </NavLink>
                : null}
        </CSidebarNav>
    )
}

export default Profile;
