import React, {useState, useEffect, ChangeEvent} from 'react';
import './DateTimeRangePicker.css';
import locales from "./locales";
import {
  getFormattedDate,
  getInputFormattedDateTime,
  hours,
  inputDateTimeValidate,
  minutes,
  presetRanges
} from "./config";
import Calendar from "./Calendar";

export type TDateTimeRange = {
  start: Date | null,
  end: Date | null
};


interface DateTimeRangePickerProps {
  locale?: string;
  value?: {
    start: Date;
    end: Date;
  };
  presets?: boolean;
  onChange: (value: { start: Date; end: Date }) => void;
  getValue?: (value: { start: Date; end: Date }) => void;
}

const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({ locale, value, presets, onChange, getValue }) => {
  const [range, setRange]: any = useState({start: null, end: null});
  const [time, setTime]: any = useState({
    hourStart: hours[0],
    minuteStart: minutes[0],
    hourEnd: hours[hours.length - 1],
    minuteEnd: minutes[minutes.length - 1]
  });
  const [preset, setPreset] = useState('Another range');
  const [inputs, setInputs] = useState({start: '', end: ''});
  const [error, setError] = useState(false);

  useEffect(() => {
    if (value) {
      const rangeState: any = {...range};
      const timeState: any = {...time};
      const inputsState: any = {...inputs};

      try {
        rangeState.start = new Date(value.start.getFullYear(), value.start.getMonth(), value.start.getDate());
        rangeState.end = new Date(value.end.getFullYear(), value.end.getMonth(), value.end.getDate());
        timeState.hourStart = value.start.getHours();
        timeState.minuteStart = value.start.getMinutes();
        timeState.hourEnd = value.end.getHours();
        timeState.minuteEnd = value.end.getMinutes();
        inputsState.start = getInputFormattedDateTime(rangeState.start, timeState.hourStart, timeState.minuteStart);
        inputsState.end = getInputFormattedDateTime(rangeState.end, timeState.hourEnd, timeState.minuteEnd);
      } catch (e) {}

      setRange(rangeState);
      setTime(timeState);
      setInputs(inputsState);
    }
    // eslint-disable-next-line
  }, []);

  const setDateRangeHandler = (type: string, v: any) => {
    const state: any = {...range, [type]: v};
    const timeState: any = {...time};
    const inputsState: any = {...inputs};

    if (type === 'end' && !state.start) state.start = state.end;
    if (state.start > state.end) {
      if (type === 'start' && state.end) {
        state.end = state.start;
        timeState.hourStart = hours[0];
        timeState.minuteStart = minutes[0];
      }
      if (type === 'end' && state.start) {
        state.start = state.end;
        timeState.hourEnd = hours[hours.length - 1];
        timeState.minuteEnd = minutes[minutes.length - 1];
      }
      setTime(timeState);
    }

    inputsState.start = getInputFormattedDateTime(state.start, timeState.hourStart, timeState.minuteStart);
    inputsState.end = getInputFormattedDateTime(state.end, timeState.hourEnd, timeState.minuteEnd);

    if (getValue) {
      getValue(state)
    }

    setError(false);
    setInputs(inputsState);
    setRange(state);
    setPreset('Another range');
  };

  const setTimeRangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    const state = {...time, [event.target.name]: event.target.value};
    const inputsState = {...inputs};

    if (getFormattedDate(range.start) === getFormattedDate(range.end) && `${state.hourStart}${state.minuteStart}` > `${state.hourEnd}${state.minuteEnd}`) {
      if (event.target.name === 'hourStart' || event.target.name === 'minuteStart') {
        state.hourEnd = state.hourStart;
        state.minuteEnd = state.minuteStart;
      }
      if (event.target.name === 'hourEnd' || event.target.name === 'minuteEnd') {
        state.hourStart = state.hourEnd;
        state.minuteStart = state.minuteEnd;
      }
    }

    inputsState.start = getInputFormattedDateTime(range.start, state.hourStart, state.minuteStart);
    inputsState.end = getInputFormattedDateTime(range.end, state.hourEnd, state.minuteEnd);

    setError(false);
    setInputs(inputsState);
    setTime(state);
    setPreset('Another range');
  };

  const setPresetRangeHandler = (presetName: string) => {
    const rangeState: any = {...range};
    const timeState: any = {
      hourStart: hours[0],
      minuteStart: minutes[0],
      hourEnd: hours[hours.length - 1],
      minuteEnd: minutes[minutes.length - 1]
    };
    const inputsState = {...inputs};
    const today = new Date();

    switch (presetName) {
      case 'Today':
        rangeState.start = today;
        rangeState.end = today;
        break;
      case 'Yesterday':
        rangeState.start = new Date(new Date(today).setDate(today.getDate() - 1));
        rangeState.end = new Date(new Date(today).setDate(today.getDate() - 1));
        break;
      case 'Last 7 days':
        rangeState.start = new Date(new Date(today).setDate(today.getDate() - 6));
        rangeState.end = today;
        break;
      case 'Last 30 days':
        rangeState.start = new Date(new Date(today).setDate(today.getDate() - 29));
        rangeState.end = today;
        break;
      case 'Current month':
        rangeState.start = new Date(new Date(today).setDate(1));
        rangeState.end = today;
        break;
      case 'Previous month':
        rangeState.start = new Date(new Date(new Date(today).setMonth(today.getMonth() - 1)).setDate(1));
        rangeState.end = new Date(new Date(today).setDate(0));
        break;
      case 'Current year':
        rangeState.start = new Date(new Date().getFullYear(), 0, 1);
        rangeState.end = today;
        break;
      case 'Previous year':
        rangeState.start = new Date(new Date().getFullYear() - 1, 0, 1);
        rangeState.end = new Date(new Date().getFullYear() - 1, 11, 31);
        break;
      default:
        setPreset('Another range');
        setError(false);
        return null;
    }

    inputsState.start = getInputFormattedDateTime(rangeState.start, timeState.hourStart, timeState.minuteStart);
    inputsState.end = getInputFormattedDateTime(rangeState.end, timeState.hourEnd, timeState.minuteEnd);

    setError(false);
    setRange(rangeState);
    setTime(timeState);
    setInputs(inputsState);
    setPreset(presetName);
  };

  const onChangeInputsHandler = (event: any) => {
    const state = {...inputs, [event.target.name]: event.target.value};
    setInputs(state);
    setPreset('Another range');
  };

  const onBlurInputsHandler = () => {
    const rangeState: any = {...range};
    const timeState: any = {...time};
    const inputsState: any = {...inputs};
    let isError: boolean = false;

    let value = inputDateTimeValidate(inputs.start);
    if (value) {
      inputsState.start = value;
      rangeState.start = new Date(value.split(' ')[0].split('.').reverse().join('.'));
      timeState.hourStart = value.split(' ')[1].split(':')[0];
      timeState.minuteStart = value.split(' ')[1].split(':')[1];
    } else isError = true;
    value = inputDateTimeValidate(inputs.end);
    if (value) {
      inputsState.end = value;
      rangeState.end = new Date(value.split(' ')[0].split('.').reverse().join('.'));
      timeState.hourEnd = value.split(' ')[1].split(':')[0];
      timeState.minuteEnd = value.split(' ')[1].split(':')[1];
    } else isError = true;

    if (`${getFormattedDate(rangeState.start)}${timeState.hourStart}${timeState.minuteStart}` >
      `${getFormattedDate(rangeState.end)}${timeState.hourEnd}${timeState.minuteEnd}`) isError = true;

    if (isError) {
      setError(true);
      return null;
    }

    setError(false);
    setRange(rangeState);
    setTime(timeState);
    setInputs(inputsState);
  };

  const onApplyHandler = (event: any) => {
    event.preventDefault();

    const value: any = { start: '', end: '' };
    const isMinutesEnd = (time.minuteEnd && time.minuteEnd !== '00');

    try {
      value.start = new Date(range.start.getFullYear(), range.start.getMonth(), range.start.getDate(), parseInt(time.hourStart || 0), parseInt(time.minuteStart || 0));
      value.end = new Date(range.end.getFullYear(), range.end.getMonth(), range.end.getDate(), parseInt(time.hourEnd || 0), parseInt(time.minuteEnd || 0));
      if (isMinutesEnd) value.end = new Date(new Date(new Date(value.end).setSeconds(59)).setMilliseconds(999));
    } catch (e) {}

    if (isNaN(new Date(value.start).getTime()) || isNaN(new Date(value.end).getTime())) {
      value.start = '';
      value.end = '';
    }

    onChange(value);
  };

  const onClearHandler = (event: any) => {
    event.preventDefault();
    setRange({start: null, end: null});
    setTime({
      hourStart: hours[0],
      minuteStart: minutes[0],
      hourEnd: hours[hours.length - 1],
      minuteEnd: minutes[minutes.length - 1]
    });
    setPreset('Another range');
    setInputs({start: '', end: ''});
    setError(false);
    const value: any = { start: '', end: '' };
    onChange(value);
  };

  return (
    <div className="date-time-range-picker"
         style={{minWidth: presets ? '45rem' : '34.5rem'}}
      onClick={e => e.stopPropagation()}
    >
      <div className="date-time-range-picker__body">
        <div className="date-time-range-picker__picker-wrapper">
          <Calendar locale={locale || 'en'}
                    range={range}
                    start
                    onClick={v => setDateRangeHandler('start', v)}
          />
          <div className="date-time-range-picker__time-picker-wrapper">
            <select name="hourStart"
                    value={time.hourStart}
                    onChange={setTimeRangeHandler}
            >
              {hours.map(el => <option key={'hourStart' + el} value={el}>{el}</option>)}
            </select>
            <span>:</span>
            <select name="minuteStart"
                    value={time.minuteStart}
                    onChange={setTimeRangeHandler}
            >
              {minutes.map(el => <option key={'minuteStart' + el} value={el}>{el}</option>)}
            </select>
          </div>
        </div>

        <div className="date-time-range-picker__picker-wrapper">
          <Calendar locale={locale || 'en'}
                    range={range}
                    end
                    onClick={v => setDateRangeHandler('end', v)}
          />
          <div className="date-time-range-picker__time-picker-wrapper">
            <select name="hourEnd"
                    value={time.hourEnd}
                    onChange={setTimeRangeHandler}
            >
              {hours.map(el => <option key={'hourEnd' + el} value={el}>{el}</option>)}
            </select>
            <span>:</span>
            <select name="minuteEnd"
                    value={time.minuteEnd}
                    onChange={setTimeRangeHandler}
            >
              {minutes.map(el => <option key={'minuteEnd' + el} value={el}>{el}</option>)}
            </select>
          </div>
        </div>

        {presets ?
          <div className="date-time-range-picker__preset-picker-wrapper">
            {presetRanges.map(presetName => {
              let className = 'date-time-range-picker__preset-picker-item';
              if (presetName === preset) className += ' date-time-range-picker__preset-picker-item--active';
              return (
                <div key={'preset' + presetName} className={className} onClick={() => setPresetRangeHandler(presetName)}>
                  {locales(locale || 'en', presetName)}
                </div>
              )
            })}
          </div>
          : null}
      </div>

      <div className="date-time-range-picker__footer">
        <div>
          {locales(locale || 'en', 'From')}
          <input type="text"
                 className={error ? 'wrong-value' : ''}
                 name="start"
                 value={inputs.start}
                 onChange={e => onChangeInputsHandler(e)}
                 onBlur={onBlurInputsHandler}
          />
          {locales(locale || 'en', 'To')}
          <input type="text"
                 className={error ? 'wrong-value' : ''}
                 name="end"
                 value={inputs.end}
                 onChange={e => onChangeInputsHandler(e)}
                 onBlur={onBlurInputsHandler}
          />
        </div>
        <div>
          <button className="date-time-range-picker__button date-time-range-picker__button--apply"
                  onClick={onApplyHandler}
          >
            {locales(locale || 'en', 'Apply')}
          </button>
          <button className="date-time-range-picker__button date-time-range-picker__button--clear"
                  onClick={onClearHandler}
          >
            {locales(locale || 'en', 'Clear')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateTimeRangePicker;
