import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import {CSidebar, CSidebarBrand, CSidebarNav} from '@coreui/react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import { AppSidebarNav } from './AppSidebarNav';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../hooks/redux";
import ProfileComponent from "./ProfileComponent";
import {useGetCurrentVersionQuery, useGetVersionHistoryListQuery} from "../store/versionHistory/versionHistory.api";

interface AppSidebarProps {
  sidebarMenu: any;
  sidebarIsCollapsed: any;
  sidebarCollapseHandler: any;
}
const AppSidebar: React.FC<AppSidebarProps> = ({sidebarMenu, sidebarIsCollapsed, sidebarCollapseHandler}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {data: lastVersion} = useGetCurrentVersionQuery();

  const {sidebarShow} = useAppSelector((state) => state.toggleBtnApp);
  const unfoldable = false;

  return (
      <CSidebar
          position="fixed"
          unfoldable={unfoldable}
          visible={sidebarShow}
          onVisibleChange={(visible) => {
            dispatch({ type: 'set', sidebarShow: visible })
          }}
          onMouseOver={() => sidebarCollapseHandler(false)}
          onMouseLeave={() => sidebarCollapseHandler(unfoldable && true)}
      >
        <CSidebarBrand className="d-none d-md-flex" style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
          <div className="d-flex align-items-center justify-content-center">
            {(!unfoldable || !sidebarIsCollapsed) &&
              <span style={{fontSize: '24px'}}>
                {t("IAMS")} ({t("MES")})
                <sub style={{fontSize: '12px', marginLeft: '5px'}}>v.{lastVersion?.version || '0.0.0'}</sub>
              </span>
            }
          </div>
        </CSidebarBrand>
        <ProfileComponent/>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav items={sidebarMenu}/>
          </SimpleBar>
        </CSidebarNav>
        {/*<CSidebarToggler*/}
        {/*    className="d-none d-lg-flex"*/}
        {/*    onClick={() => setUnfoldable(prev => !prev)}*/}
        {/*/>*/}
      </CSidebar>
  )
}

export default React.memo(AppSidebar)
