import React, {FC} from 'react';
import {CFormCheck} from "@coreui/react";
import {useTranslation} from "react-i18next";

import {IEmergencyType} from "../../../interfaces/IEmergency";

interface IEmergencyTypesList {
  data: IEmergencyType[]
  state: number[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void
}

const EmergencyTypesList: FC<IEmergencyTypesList> = ({data, state, onChange}) => {
  const {i18n} = useTranslation();

  let emergencyTypesList;

  if (data.length) {
    const arrayForSort = data.map(type => {
      const res = {...type};
      if (i18n.language === 'ru') res.name = res.name.split(' / ').reverse()[0];
      else res.name = res.name.split(' / ')[0];
      return res;
    });

    emergencyTypesList = arrayForSort.sort((a: any, b: any) => ((a.name > b.name) ? 1 : -1)).map(el => {
      return (
        <div className="es-feed-es-type" key={el.id} title={el.name}>
          <div>
            <CFormCheck id={`emergency-type_${el.id}`}
                        style={{marginRight: '10px'}}
                        checked={state.includes(el.id)}
                        name={`emergency-type_${el.id}`}
                        onChange={(e) => onChange(e, el.id)}
            />
          </div>
            <label className="es-feed-es-type__label" htmlFor={`emergency-type_${el.id}`}>
              <img alt={''} src={el.geo_icon?.icon} width={32} height={37}/>
              <span style={{fontSize: '14px', paddingLeft: '10px'}}>{el.name}</span>
            </label>
        </div>
      )
    })
  }

  return (
    <>
      {emergencyTypesList}
    </>
  );
};

export default EmergencyTypesList;
