import React, {FC} from 'react';
import './LoaderLine.css';

interface IProps {
  width?: string,
  height?: string,
  visible?: boolean,
  style?: {[key: string]: string}
}

const LoaderLine: FC<IProps> = ({width = '100%', height = '5px', visible = false, style = {}}) => {
  return (
    <div className={`loader-line${visible ? ' loader-line--visible' : ''}`} style={{...style, width: visible ? width : 0, height: height}}>
      <div className="loader-line__flash"/>
    </div>
  );
};

export default LoaderLine;
