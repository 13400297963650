import React, {useMemo, useRef, useState} from 'react';
import {CButton, CForm, CFormInput, CFormLabel} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilX} from "@coreui/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import SpinnerDotted from "../../../components/preloaders/SpinnerDotted/SpinnerDotted";

import {useCreateDailyInfoFileMutation, useCreateDailyInfoMutation} from "../../../store/dailyInfo/dailyInfo.api";

const EsDailyReportNew = () => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const fileInputRef = useRef(null);

    const [createDailyInfo, {isLoading: createIsLoading}] = useCreateDailyInfoMutation();
    const [createDailyInfoFile, {isLoading: createDailyInfoFileIsLoading}] = useCreateDailyInfoFileMutation();

    const [mainState, setMainState] = useState({actual_date: new Date()});
    const [filesState, setFilesState] = useState<any[]>([]);
    const [emergencyFilesState, setEmergencyFilesState] = useState<any[]>([]);
    const [filesForDelete, setFilesForDelete] = useState<number[]>([]);
    const [savingError, setSavingError] = useState<{[key: string]: string} | null>(null);

    const isLoading = useMemo(() => {
        return (createIsLoading || createDailyInfoFileIsLoading);
    }, [createIsLoading, createDailyInfoFileIsLoading]);

    const saveIsLoading = useMemo(() => {
        return (createIsLoading || createDailyInfoFileIsLoading);
    }, [createIsLoading, createDailyInfoFileIsLoading]);

    const handleSelectDateTime = (date: any) => {
        setMainState(prevState => {
            return {...prevState, actual_date: date}
        })
    };

    const handleChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target;
        let tempFiles = [...filesState]

        if (e.target.type === 'file') {
            const chosenFiles = Array.prototype.slice.call(files);
            chosenFiles.forEach(el => {
                if (!tempFiles.find(item => item.name === el.name)) {
                    tempFiles.push(el)
                }
            })

            setFilesState(tempFiles);
            updateFileInput(tempFiles);
        }
    };

    const updateFileInput = (files: any) => {
        const dataTransfer = new DataTransfer();
        files.forEach((file: any) => dataTransfer.items.add(file));
        // @ts-ignore
        fileInputRef.current.files = dataTransfer.files;
    };

    const handleDeleteFile = (name: string) => {
        let arr = [...filesState];
        let index = arr.findIndex(el => el.name === name);
        arr.splice(index, 1);
        setFilesState(arr);
        updateFileInput(arr);
    };

    const handleDeleteDailyInfoFile = (id: number) => {
        let arr = [...emergencyFilesState];
        let idsArr = [...filesForDelete];
        let idx = arr.findIndex(el => el.id === id);
        arr.splice(idx, 1);

        if (!idsArr.includes(id)) {
            idsArr.push(id);
        }

        setEmergencyFilesState(arr);
        setFilesForDelete(idsArr);
    };

    const handleCreateDailyReport = (e: React.MouseEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSavingError(null);
        let data: {[key: string]: any} = {};

        Object.entries(mainState).forEach(el => {
            if (String(el[1])) {
                data[el[0]] = el[1];
            }
        })

        data.actual_date = dayjs(mainState.actual_date).format("YYYY-MM-DD");

        createDailyInfo(data).then((res: any) => {
            if (res?.error?.data) {
                setSavingError(res.error.data);
                return res;
            }

            const promises: any = [];
            if (res?.data) {
                filesState.forEach(el => {
                    const formData = new FormData();
                    formData.append('daily_operational_info', res.data.id);
                    formData.append('file', el);
                    promises.push(createDailyInfoFile(formData));
                })
            }
            return Promise.all(promises);
        }).then((res: any) => {
            if (res?.error?.data) {
                setSavingError(res.error.data);
                return res;
            }
            navigate({pathname: '/es-daily-reports/list'});
        }).catch(() => {
            console.log("Something went wrong...");
        })
    };

    const extension = ['png', 'jpeg', 'jpg', 'gif', 'raw', 'tiff', 'bmp', 'psd'];

  return (
      <>
          <div className="pb-3 mb-4 title-divider">
              <h1>{t('nav.New ES report')}</h1>
              <LoaderLine visible={isLoading}/>
          </div>

          <CForm onSubmit={handleCreateDailyReport}>
              <div style={{height: '50px'}} className="mb-4">
                  <div className="react-date-picker-wrapper">
                      <CFormLabel>{t("emergency.dateOfTheMessage")}</CFormLabel>
                      <DatePicker onChange={handleSelectDateTime}
                                  showTimeInput
                                  selected={mainState.actual_date}
                                  dateFormat={'dd.MM.yyyy H:mm'}
                                  locale={i18n.language}
                                  className={"ml-5"}
                      />
                  </div>
              </div>

              <div className="mb-3">
                  <CFormInput type="file"
                              label={t('labels.Images')}
                              name={'images'}
                              onChange={handleChangeFiles}
                              className={'emergency-custom-input mb-3'}
                              multiple
                              ref={fileInputRef}
                  />
                  <div style={{display: "flex", justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px'}}>
                      {filesState.map((item: any, i: number) => {
                          return (
                              <div key={i + '_pic'} className="image-wrapper">
                                  <div className="image-delete-icon-wrapper"
                                       onClick={() => handleDeleteFile(item.name)}
                                  >
                                      <CIcon icon={cilX} className="image-delete-icon"/>
                                  </div>
                                  {!item.type.includes("image") ?
                                      <div className="file-item file-item-daily">
                                          {item.name}
                                      </div> :
                                      <img alt={""}
                                           src={URL.createObjectURL(item)}
                                           style={{height: '110px', width: 'auto'}}
                                      />}
                              </div>
                          )
                      })}
                      {emergencyFilesState.map((item: any, i: number) => {
                          return (
                              <div key={i + '_pic'} className="image-wrapper">
                                  <div className="image-delete-icon-wrapper"
                                       onClick={() => handleDeleteDailyInfoFile(item.id)}
                                  >
                                      <CIcon icon={cilX} className="image-delete-icon"/>
                                  </div>
                                  {!extension.includes(item.name.split('.')[item.name.split('.').length - 1]) ?
                                      <div className="file-item file-item-daily">
                                          {item.name}
                                      </div> :
                                      <img alt={""}
                                           src={item.file}
                                           style={{height: '110px', width: 'auto'}}
                                      />
                                  }
                              </div>
                          )
                      })}
                  </div>
              </div>

              <div className="mb-5" style={{display: 'flex', alignItems: 'center', columnGap: '1rem'}}>
                  <CButton type="submit"
                           disabled={!mainState.actual_date}
                  >
                      {t("save")}
                  </CButton>
                  {saveIsLoading ?
                      <SpinnerDotted size={16} color="#091b37"/>
                      : savingError ?
                          <span className="error-message">{t('Changes are not saved')}</span>
                          : null
                  }
              </div>
          </CForm>
      </>
  );
};

export default EsDailyReportNew;
