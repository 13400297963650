import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
  CButton, CButtonGroup,
  CFormInput,
  CFormLabel,
  CTable,
  CTableBody, CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import dayjs from "dayjs";

import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import DateTimeRangePicker from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";
import DefaultPaginationWrapper from "../../../components/UI/Pagination/DefaultPaginationWrapper";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import {IPagination} from "../../../interfaces/IPagination";
import {Query} from "../../../functions";

import {
  useLazyGetDailyInfoQuery,
  useRemoveDailyInfoMutation
} from "../../../store/dailyInfo/dailyInfo.api";

const EsDailyReports = () => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [fetchDailiesInfo, {data: dailiesInfo, isLoading: dailiesInfoLoading}] = useLazyGetDailyInfoQuery();
  const [removeDailiesInfo] = useRemoveDailyInfoMutation();

  const [pagination, setPagination] = useState<IPagination>({page_size: '100', search: '', page: 1});
  const [filter, setFilter] = useState({sort_by: 'actual_date',direction: 'asc'});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [dateRange, setDateRange] = useState({start: '', end: ''});

  const countEmergency: number | undefined = dailiesInfo?.results.length;

  useEffect(() => {
    const range: {[key: string]: string} = {};
    if (dateRange.start && dateRange.end) {
      range.date_from = JSON.parse(JSON.stringify(dateRange.start));
      range.date_to = JSON.parse(JSON.stringify(dateRange.end));
    }
    const query: {[key: string]: string | number} = {...pagination, ...filter, ...range};
    if (!query.search) delete query.search;
    fetchDailiesInfo(Query.stringify(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filter, dateRange]);

  const isLoading = useMemo(() => {
    return (dailiesInfoLoading);
  }, [dailiesInfoLoading]);

  const onPaginationHandler = (state: any) => {
    let innerState = {...pagination, ...state};

    if(countEmergency && countEmergency <= Number(pagination.page_size)) {
      innerState = {
        page_size: pagination.page_size,
        search: pagination.search,
        page: 1
        , ...state}
    }
    setPagination(innerState);
  };

  const onFilterHandler = (field: string) => {
    const state = {...filter};
    if (field === state.sort_by) {
      state.direction = state.direction === 'asc' ? 'desc' : 'asc';
    } else {
      state.sort_by = field;
      state.direction = 'asc';
    }
    setFilter(state);
  };

  const handleOpenDeleteModal = (id: any) => {
    setDeleteModal(true);
    setSelectedId(id);
  }

  const handleToggleDeleteModal = (visible: boolean) => {
    if (!visible) setSelectedId(null);
    setDeleteModal(visible)
  }

  const handleDelete = () => {
    removeDailiesInfo(selectedId!).then(res => {
      // @ts-ignore
      if (res?.data === null) {
        handleToggleDeleteModal(false)
      }
    })
  }

  const handleRedirectToCreate = () => {
    navigate({pathname: '/es-daily-reports/new'})
  }

  const handleSelectDates = (value: any) => {
    // Преобразование даты в формат YYYY-MM-DD
    const formattedStart = value.start ? value.start.toISOString().split('T')[0] : '';
    const formattedEnd = value.end ? value.end.toISOString().split('T')[0] : '';

    // @ts-ignore
    setDateRange(prevState => {
      return {...prevState, start: formattedStart ? formattedStart : '', end: formattedEnd ? formattedEnd : ''}
    });

    setDateRangeVisible(false);
  }

  const handleGetValue = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value);
  }

  let esList: any [] = [];

  if (dailiesInfo?.results?.length) {
    esList = dailiesInfo.results.map((el: any, idx: number) => {
      return (
          <CTableRow key={'user' + idx}>
            <CTableDataCell>{dayjs(el.created_at).format('DD.MM.YYYY HH:mm:ss')}</CTableDataCell>
            <CTableDataCell>{el?.user.first_name + " " + el?.user.last_name}</CTableDataCell>
            <CTableDataCell className="daily-file-table">{el?.file_operational_info?.length ?
              el?.file_operational_info?.map((file: any) => (
                <a key={file.id}
                   href={file.file}
                   className="daily-file-block"
                   target="_blank"
                   rel="noopener noreferrer"
                   style={{wordBreak: 'break-all'}}
                >
                  {decodeURIComponent(file?.file.substring(file?.file.lastIndexOf('/') + 1))}
                </a>
              ))
              : null}
            </CTableDataCell>
            <CTableDataCell className="text-center">
              <CButtonGroup size="sm">
                <CButton color="danger" className="default-table__action-btn"
                         onClick={() => handleOpenDeleteModal(el.id)}
                >
                  <i className="fa fa-times color-white" aria-hidden="true"> </i>
                </CButton>
              </CButtonGroup>
            </CTableDataCell>
          </CTableRow>
      )
    })
  }

  return (
      <>
        <div className="pb-3 mb-4 title-divider">
          <h1>{t('ESDailyReports.title')}</h1>
          <LoaderLine visible={isLoading}/>
        </div>

        <div className="d-flex justify-content-md-end mb-3">
          <CButton size="sm"
                   color="info"
                   className="color-white"
                   onClick={handleRedirectToCreate}
          >
            {t('createAssignment.create')}
          </CButton>
        </div>

        <div className="d-flex justify-content-md-end mb-3" style={{position: 'relative'}}>
          <div style={{width: '305px'}}>
            <CFormLabel>{t('Select dates')}</CFormLabel>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {/*@ts-ignore*/}
              <CFormInput style={{borderRadius: '6px', width: '305px'}}
                          type="text"
                          value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                          placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                          onClick={() => setDateRangeVisible(true)}
              />
            </div>

          </div>
          {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
            setDateRangeVisible(false)
          }}/>}
          {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
            <DateTimeRangePicker locale={i18n.language} onChange={handleSelectDates} getValue={handleGetValue}/>
          </div> : null}
        </div>

        <DefaultPaginationWrapper data={dailiesInfo}
                                  state={pagination}
                                  onChange={onPaginationHandler}
                                  onSearchDelay={2000}
            // onSearchCallback={onSearchCallbackHandler}
        >
          <CTable bordered striped className="default-table">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell
                                  scope="col"
                                  className="sorted-table-cell"
                                  onClick={() => onFilterHandler('actual_date')}
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('Date of creation')}</span>
                    {filter.sort_by === 'actual_date' ?
                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                        <i className="fa fa-sort" aria-hidden="true"/>
                    }
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell  scope="col"
                                  className="sorted-table-cell"
                                  onClick={() => onFilterHandler('user__first_name')}
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('labels.description')}</span>
                    {filter.sort_by === 'user__first_name' ?
                        <i className={`fa fa-sort-amount-${filter.direction}`} aria-hidden="true"/> :
                        <i className="fa fa-sort" aria-hidden="true"/>
                    }
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell style={{minWidth: "150px", maxWidth: '300px'}} scope="col"
                                  className="sorted-table-cell"
                >
                  <div className="d-flex justify-content-between">
                    <span>{t('labels.file')}</span>
                  </div>
                </CTableHeaderCell>

                <CTableHeaderCell scope="col" className="default-table__actions">
                  {t('settings.users.actions')}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>

            <CTableBody>
              {esList}
            </CTableBody>
          </CTable>
        </DefaultPaginationWrapper>
        <DefaultModal visible={deleteModal}
                      setVisible={handleToggleDeleteModal}
                      title={t('deletion')}
                      type="danger"
                      cancelButton={t('cancel')}
                      onOk={handleDelete}
            // @ts-ignore
            //             error={error?.data?.error}
                      size="lg"
        >
          <div>
            <p className="mb-2 fs-6">{t('esBase.doYouReallyWantToDeleteAnEmergency')}</p>
            {/*@ts-ignore*/}
            <div className="fs-6 fw-bolder">{dailiesInfo?.results?.find(el => el.id === selectedId)?.title}?</div>
          </div>
        </DefaultModal>
      </>

  );
};

export default EsDailyReports;
