import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {CButton} from "@coreui/react";
import {Marker} from "react-leaflet";
import Leaflet from "leaflet";
import dayjs from "dayjs";

import {
  useGetEmergencyByIdQuery,
  useRemoveEmergencyMutation,
  useUpdateEmergencyMutation
} from "../../../store/emergencies/emergencies.api";
import {useGetDivisionsQuery} from "../../../store/divisions/divisions.api";
import DefaultModal from "../../../components/UI/Modals/DefaultModal";
import BaseMap from "../../../components/maps/BaseMap";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import GalleryModal from "../../../components/UI/Modals/GalleryModal";
import {getParamsOfFileByUrl, Numb} from "../../../functions";
import {
  useCreateEmergencyMessageMutation,
  useLazyGetEmergencyChatByIdQuery,
} from "../../../store/emergencyChat/emergencyChat.api";
import {useGetInfoUserQuery} from "../../../store/account/account.api";
import {
  useLazyGetEmergencyTypeByIdQuery
} from "../../../store/emergencyTypes/emergencyTypes.api";

type TMessage = {
  title: string,
  description?: string,
  recipient?: string | number,
  emergency: string | number,
  status: boolean
};

const EsDetail = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const id = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const {data: user} = useGetInfoUserQuery();
  const {data: divisions, isLoading: divisionsLoading} = useGetDivisionsQuery();
  const {data: emergencyById, isLoading: getLoading} = useGetEmergencyByIdQuery(id);
  const [getEmergencyTypeById, {data: emergencyType, isLoading: emergencyTypeLoading}] = useLazyGetEmergencyTypeByIdQuery();
  const [removeEmergency, {isLoading: updateLoading}] = useRemoveEmergencyMutation();
  const [updateEmergency, {isLoading: removeLoading}] = useUpdateEmergencyMutation();
  const [fetchEmergencyChatById, {data: emergencyChatById, isLoading: emergencyChatByIdLoading}] = useLazyGetEmergencyChatByIdQuery();
  const [createEmergencyMessage] = useCreateEmergencyMessageMutation();

  const [removeVisible, setRemoveVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [type, setType] = useState('');
  const [showGalleryModal, setShowGalleryModal] = useState<number | null>(null);
  const [showChat, setShowChat] = useState<boolean>(false);
  const [message, setMessage] = useState<TMessage>({
    title: "",
    description: "",
    recipient: '',
    emergency: isNaN(Number(id)) ? '' : Number(id),
    status: true
  });

  const isLoading = useMemo(() => {
    return (getLoading || emergencyTypeLoading || updateLoading || removeLoading || divisionsLoading || emergencyChatByIdLoading);
  }, [getLoading, emergencyTypeLoading, updateLoading, removeLoading, divisionsLoading, emergencyChatByIdLoading]);

  const author = useMemo(() => {
    const data = emergencyById?.properties?.author;
    if (!data) return '';
    return `${data.position?.name || ''} ${data.first_name || ''} ${data.last_name || ''}`;
  }, [emergencyById]);

  const { icon, position } = useMemo(() => {
    const res: any = { icon: null, position: [] };
    if (emergencyById?.properties?.hasOwnProperty('latitude')) {
      res.position = [emergencyById.properties.latitude || null, emergencyById.properties.longitude || null];
      const iconUrl = emergencyById.properties.type_emergency?.geo_icon?.icon;
      if (iconUrl) {
        res.icon = new Leaflet.Icon({
          iconUrl: iconUrl,
          iconSize: [32, 37],
          iconAnchor: [16, 37],
          popupAnchor: [0, -32],
        });
      }
    }
    return res;
  }, [emergencyById]);

  useEffect(() => {
    if (id) fetchEmergencyChatById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const typeId = emergencyById?.properties?.type_emergency?.id;
    if (typeId) getEmergencyTypeById(typeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyById]);

  const handlerChat = () => {
    setShowChat(!showChat);
  };

  const onToggleGalleryModal = (value: number | null = null) => {
    setShowGalleryModal(value);
  };

  const handleOpenDeleteModal = () => {
    setRemoveVisible(true);
  };

  const handleDeleteEmergency = () => {
    if (user?.id === emergencyById?.properties?.author?.id)
      removeEmergency(Number(id)).then(() => {
        setRemoveVisible(false);
        navigate({pathname: location.pathname.split('/')[3] === 'base' ? '/es-base' : '/es-feed'})
      });
  };

  const handleRedirectToUpdate = () => {
    navigate({pathname: `/es-report/update/${id}`})
  };

  const handleOpenUpdateModal = (type: string) => {
    setType(type);
    setUpdateVisible(true);
  };

  const handleUpdateSettings = () => {
    if (type === 'late') {
      let data = {
        late_information: !emergencyById?.properties?.late_information
      }
      updateEmergency({body: data, id: emergencyById?.properties?.id});
      setUpdateVisible(false);
    }
    if (type === 'status') {
      let data = {
        status: emergencyById?.properties?.status === 'in processing' ? 'completed' : 'in processing'
      }
      updateEmergency({body: data, id: emergencyById?.properties?.id})
      setUpdateVisible(false);
    }
  };

  const handleMessageChange = (event: any) => {
    const messageUser = event.target.value;
    setMessage((prev: any) => ({
      ...prev,
      title: messageUser
    }));
  };

  const handleMessageSend = async () => {
    if (!message.emergency || !message.title) return null;

    const data: TMessage = {title: message.title, emergency: message.emergency, status: message.status};
    if (message.description) data.description = message.description;
    if (message.recipient) data.recipient = message.recipient;

    try {
      await createEmergencyMessage(data).then(() => {
        setMessage((prev: any) => ({
          ...prev,
          title: ''
        }));
      });
    } catch (error) {
      console.error("Ошибка при отправке сообщения:", error);
    }
  };

  const getMonthName = (month: number): string => {
    const months = [t('chat.Jan'), t('chat.Feb'), t('chat.Mar'), t('chat.Apr'), t('chat.May'), t('chat.Jun'), t('chat.Jul'), t('chat.Aug'), t('chat.Sep'), t('chat.Oct'), t('chat.Nov'), t('chat.Dec'), t('chat.Aug')];
    return months[month];
  };

  const formatTime = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const additionalInformation = useMemo(() => {
    const fields = emergencyById?.properties?.field_emergency;
    const fieldTypeIds = emergencyType?.field_type_emergency?.map(fieldType => fieldType.id);

    if (fields && fields.length && fieldTypeIds) {
      return fields.map((el: any) => {
        if (fieldTypeIds.includes(el.field_type_emergency.id)) return (
          <li key={'additionalInformation' + el.id}>
            <strong style={{marginRight: '10px'}}>{el?.field_type_emergency?.name || '-'}:</strong>
            <span>
                    {
                      el?.field_type_emergency?.type === 'date' ?
                        dayjs(el.meaning).format('DD.MM.YYYY') :
                        el?.field_type_emergency?.type === 'datetime' ?
                          dayjs(el.meaning).format('DD.MM.YYYY HH:mm') :
                          el.meaning
                    }
                  </span>
          </li>
        );
        return null;
      });
    }
    return null;
  }, [emergencyById, emergencyType]);

  if (isLoading) return <LoaderLine visible={isLoading}/>;

  return (
    <>
      <div className="pb-3 mb-4 title-divider">
        <h1>{emergencyById?.properties?.title}</h1>

        <div>
          <CButton className="btn btn-info btn-sm color-white" type='button' onClick={() => handlerChat()} style={{display: 'flex', marginLeft: 'auto'}}>
            {t('assignmentsPage.commentsDesc')}
          </CButton>

          {showChat ?
              <div className="chat es-chat" style={{
                position: "fixed",
                top: '62px',
                right: '15px',
                background: "white",
                zIndex: 1001
              }}>
                <div className="chat-wrapper chat-wrapper-es">
                  <div className="chat-content-wrapper">
                    <div className="chat-content">

                      {
                        <div className="chat-messages">
                          <div className="chat-inner-title">
                            <p className="chat-inner-title-text">{t('chat.chatEs')}</p>
                            <i
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() => setShowChat(false)}
                            >
                            </i>
                          </div>
                          <div className="msger-inputarea">
                            <input className="msger-input" type="text"
                                   value={message.title}
                                   onChange={handleMessageChange}/>
                            <button disabled={!message.title} className="btn btn-info color-white"
                                    onClick={handleMessageSend}
                            >{t('chat.send')}
                            </button>
                          </div>
                          <div className="chat-main"
                               style={{overflowY: 'scroll'}}>
                            {
                              emergencyChatById?.map((message: any, i: number) => {
                                const dateObject = new Date(message.created_at);
                                const formattedDate = `${dateObject.getDate()} ${getMonthName(dateObject.getMonth())} ${formatTime(dateObject)}`;

                                if (user.id === message.author.id) return (
                                  <div key={`${message.id}-${i}`}
                                       className="msg right-msg">
                                    <div className="msg-bubble">
                                      <div className="msg-info">
                                        <div className="msg-info-name">
                                          {t('assignmentsPage.you')}
                                        </div>
                                        <div className="msg-info-time">
                                          {formattedDate}
                                        </div>
                                      </div>
                                      <div className="msg-text">
                                        {message.title}
                                      </div>
                                    </div>
                                  </div>
                                );
                                else return (
                                  <div key={`${message.id}-${i}`}
                                       className="msg left-msg">
                                    <div className="msg-bubble">
                                      <div className="msg-info">
                                        <div
                                          className="msg-info-name">{message.author.username}</div>
                                        <div
                                          className="msg-info-time">{formattedDate}</div>
                                      </div>
                                      <div className="msg-text">
                                        {message.title}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              : null}
        </div>
        <LoaderLine visible={isLoading}/>
      </div>

      <div className="pb-3 mb-4 es-detail__card-title">
        <div className="es-detail__card-description">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: '10px'}}>
              <img alt={''} src={emergencyById?.properties?.type_emergency?.geo_icon?.icon}
                   style={{width: '32px', height: '37px'}}/>
            </div>
            <div>
              <div>
                <strong style={{marginRight: '10px'}}>{t("emergency.thisEvent")}</strong>
                <span>{emergencyById?.properties?.type_report === 'incident' ? t("emergency.incident") : emergencyById?.properties?.type_report === 'emergency' ? t("emergency.emergency") : t("emergency.other")}</span>
              </div>
              <div>
                <strong style={{marginRight: '10px'}}>{t("settings.esTypes.emergencyType")}:</strong>
                <span>{emergencyById?.properties?.type_emergency?.name}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="es-detail__card-buttons">
          <CButton className="detail-es__btn" size="sm" color="success" style={{color: 'white'}}
                   onClick={() => handleOpenUpdateModal('late')}>
            <i className="fa fa-calendar" aria-hidden="true"
               style={{marginRight: '7px'}}></i>{emergencyById?.properties?.late_information ? t("emergency.markAsWithoutDelay") : t("emergency.markAsLate")}
          </CButton>
          <CButton className="detail-es__btn" size="sm" color="secondary" style={{color: 'white'}}
                   onClick={() => handleOpenUpdateModal('status')}>
            <i className="fa fa-square-o" aria-hidden="true"
               style={{marginRight: '7px'}}></i>{emergencyById?.properties?.status === 'in processing' ? t("emergency.complete") : t("emergency.resume")}
          </CButton>
          <CButton className="detail-es__btn" size="sm" color="info" style={{color: 'white'}}
                   onClick={handleRedirectToUpdate}>
            <i className="fa fa-pencil-square-o" aria-hidden="true" style={{marginRight: '7px'}}></i>{t("edit")}
          </CButton>
          {(user?.id === emergencyById?.properties?.author?.id) ?
            <CButton className="detail-es__btn detail-es__remove-btn" size="sm"
                     onClick={handleOpenDeleteModal}>
              <i className="fa fa-times" aria-hidden="true" style={{marginRight: '7px'}}></i>{t("delete")}
            </CButton>
            : null
          }
        </div>
      </div>

      <div className="es-detail__card-body">
        <div className="es-detail__map">
          {emergencyById?.properties?.hasOwnProperty('title') ? <div className="emergency-map-wrapper">
            <div className="whole-place-wrapper">
              <BaseMap layersControl
                       zoomControl
                       center={position}
                       zoom={7}
                       scaleControl
                       loading={false}
                       handlerZoom={() => {
                       }}
                       getPosition={() => {
                       }}
              >
                {(position[0] && position[1]) ? <Marker position={position} icon={icon}/> : null}
              </BaseMap>
            </div>
          </div> : null}
        </div>

        <p className="es-detail__description">
          <p className="pb-3 mb-2">
            <strong>{t("emergency.dateOfEmergency")}: </strong>
            {dayjs(emergencyById?.properties?.date_emergency).format('DD.MM.YYYY HH:mm')}
          </p>

          <p className="pb-3 mb-2">
            <p><strong>{t("emergency.description")}:</strong></p>
            <p>{emergencyById?.properties?.description || ''}</p>
          </p>

          <p className="pb-3 mb-2">
            <p>
              <strong>{t("emergency.materialDamage")}: </strong>
              {Numb.split(emergencyById?.properties?.material_damage, 3, "'")}
            </p>
            <p>
              <strong>{t("emergency.theNumberOfHumanCasualties")}: </strong>
              {Numb.split(emergencyById?.properties?.human_casualties, 3, "'")}
            </p>
            <p>
              <strong>{t("emergency.theNumberOfVictims")}: </strong>
              {Numb.split(emergencyById?.properties?.number_of_victims, 3, "'")}
            </p>
          </p>

          {(emergencyById?.geometry?.type === 'Point' && emergencyById?.geometry?.coordinates.length === 2) ?
            <p className="pb-3 mb-2">
              <p>
                <strong>{t("labels.latitude")}: </strong>
                <span>{emergencyById.geometry.coordinates[1]}</span>
              </p>
              <p>
                <strong>{t("labels.longitude")}: </strong>
                <span>{emergencyById.geometry.coordinates[0]}</span>
              </p>
            </p>
            : null}

          {emergencyById?.properties?.division ?
            <p className="pb-3 mb-2">
              <p><strong>{t("Division")}:</strong></p>
              <p>{divisions?.find(div => div.id === emergencyById.properties.division)?.name || ''}</p>
            </p>
            : null}

          {additionalInformation ?
            <p className="pb-3 mb-2">
              <p><strong>{t("gis.geoObjects.additionalInformation")}:</strong></p>
              <ul style={{paddingLeft: '16px'}}>
                {additionalInformation}
              </ul>
            </p>
            : null}

          <p className="pb-3 mb-2">
            <p>
              <strong>{t("Author")}: </strong>{author || '-'}
            </p>
            <p>
              <strong>{t("Created")}: </strong>
              {dayjs(emergencyById?.properties?.created_at).format('DD.MM.YYYY HH:mm')}
            </p>
            <p>
              <strong>{t("Updated")}: </strong>
              {dayjs(emergencyById?.properties?.updated_at).format('DD.MM.YYYY HH:mm')}
            </p>
          </p>
        </p>
      </div>

      <div className="pb-3 mb-2">
        <div className="es-detail__files-wrapper">
          {emergencyById?.properties?.file_emergency?.map((item: any, i: number) => {
            const fileParams = getParamsOfFileByUrl(item.file);
            let innerJSX;
            if (fileParams.type === 'image') {
              innerJSX = (
                <img src={item.file} width="auto" height="110" alt={""}/>
              );
            }
            if (fileParams.type === 'video') {
              innerJSX = (
                <video width="auto" height="110">
                  <source src={item.file} type={`${fileParams.type}/${fileParams.ext}`}/>
                </video>
              );
            }
            if (fileParams.type === 'audio') {
              innerJSX = (
                <i className="fa fa-file-audio-o" aria-hidden="true"/>
              );
            }
            return (
              <div key={i + '_pic'} className="image-wrapper" style={{cursor: 'pointer'}}
                   onClick={() => setShowGalleryModal(i)}>
                {innerJSX}
              </div>
            )
          })}
        </div>
      </div>

      <GalleryModal show={!!showGalleryModal || showGalleryModal === 0}
                    toggle={onToggleGalleryModal}
                    data={emergencyById?.properties?.file_emergency || []}
                    startIndex={showGalleryModal || 0}
      />

      <DefaultModal visible={removeVisible}
                    setVisible={() => setRemoveVisible(false)}
                    title={t('deletion')}
                    type="danger"
                    cancelButton={t('cancel')}
                    onOk={handleDeleteEmergency}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">{t('esBase.doYouReallyWantToDeleteAnEmergency')}</p>
          <div className="fs-6 fw-bolder">{emergencyById?.properties?.title}?</div>
        </div>
      </DefaultModal>

      <DefaultModal visible={updateVisible}
                    setVisible={() => setUpdateVisible(false)}
                    title={t('edit')}
                    type="info"
                    cancelButton={t('cancel')}
                    onOk={handleUpdateSettings}
                    size="lg"
      >
        <div>
          <p className="mb-2 fs-6">
            {
              type === 'late' ?
                (emergencyById?.properties?.late_information ? t("emergency.markAsWithoutDelay") : t("emergency.markAsLate")) :
                emergencyById?.properties?.status === 'in processing' ? t("emergency.complete") : t("emergency.resume")
            }?
          </p>
        </div>
      </DefaultModal>
    </>
  );
};

export default EsDetail;
