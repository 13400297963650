import React, {useEffect} from 'react';
import {RouterProvider} from "react-router-dom";
import {useTranslation} from "react-i18next";
import router from "./router";

import './scss/style.scss';
import 'font-awesome/css/font-awesome.css';

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    try {
      const lang = localStorage.getItem('language');
      if (lang && lang !== i18n.language) i18n.changeLanguage(lang);
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
        <>
            <RouterProvider router={router}/>
        </>
    );
};

export default App;
