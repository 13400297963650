import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CButton, CFormCheck, CFormInput, CFormLabel} from "@coreui/react";
import dayjs from "dayjs";

import FilterBlock from "../EsFeed/FilterBlock";
import EmergencyTypesList from "../EsFeed/EmergencyTypesList";
import LoaderLine from "../../../components/preloaders/LoaderLine/LoaderLine";
import DateTimeRangePicker from "../../../components/UI/DateTimeRangePicker/DateTimeRangePicker";
import EmergencyListMap from "./EmergencyListMap";

import makeQuery from "../../../functions/makeQuery";
import {useDebounce} from "../../../hooks/debounce";
import config from "../../../config";

import {useGetEmergencyTypesQuery} from "../../../store/emergencyTypes/emergencyTypes.api";
import {useGetRegionsQuery} from "../../../store/regions/regions.api";
import {useGetDistrictsQuery} from "../../../store/districts/districts.api";

export interface IState {
  region: string
  district: string
  reportType: string
  status: string
  reportStatus: string
  [key: string]: string
}

export interface User {
  id: number
  name: string
}

export interface EmergencyAuthor {
  created_at: string
  first_name: string
  id: number
  last_name: string
  user_groups: User[]
  username: string
}

export interface EmergencyExecutor {
  created_at: string
  first_name: string
  id: number
  last_name: string
  user_groups: User[]
  username: string
}

export interface Emergency {
  author?: EmergencyAuthor | undefined
  county: number
  created_at: string
  date_emergency: string
  description: string
  district: number
  executor: EmergencyExecutor
  field_emergency: any[]
  human_casualties: number
  id: number
  late_information: boolean
  latitude: string
  locality: any
  longitude: string
  material_damage: number
  region: number
  status: string
  title: string
  type_emergency: number
  type_emergency_name: string
  type_report: string
  updated_at: string
}

export interface esFeed {
  action_created?: boolean | null
  action_updated?: boolean | null
  action_deleted?: boolean | null
  created_at: string
  date_emergency: Date
  description: string
  executor: string
  file_emergency?: {file: string, emergency: number}[]
  id: number
  late_information: boolean
  region: number
  status: string
  title: string
  type_emergency: number
  type_report: string
  updated_at: Date
}

const EsMap = () => {
  const {t, i18n} = useTranslation();

  const {data: regions, isLoading: regionsLoading} = useGetRegionsQuery();
  const {data: districts, isLoading: districtsLoading} = useGetDistrictsQuery();
  const {data: esTypes, isLoading: esTypesLoading} = useGetEmergencyTypesQuery(makeQuery.stringify({page_size: 1000000}));
  const [esTypesChecked, setEsTypesChecked] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [state, setState] = useState<IState>({
    region: '',
    reportType: '',
    status: '',
    reportStatus: '',
    district: '',
  });
  const [visibleLegend, setVisibleLegend] = useState(true);
  const [queryState, setQueryState] = useState<string>('');
  const [esResults, setEsResults]: any = useState([]);
  const [filterArray, setFilterArray] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState({start: '', end: ''});
  const [dateRangeVisible, setDateRangeVisible] = useState(false);
  const [switcher, setSwitcher] = useState<string>('region');
  const [emergenciesGeom, setEmergenciesGeom] = useState<any>({type: 'FeatureCollection', features: []});
  const [loadingProgress, setLoadingProgress] = useState<number | null>(null);
  const [esPartedData, setEsPartedData] = useState<any>(null);

  const filterDebounce = useDebounce(queryState, 2000);

  function fetchPartedData (page: number = 0) {
    const query = filterDebounce + (page ? `&page=${page}` : '');
    fetch(config.apiUrl + '/feed/geom/emergency/' + query).then(res => res.json()).then(res => {
      if (res.results) {
        if (res.page === 1 && res.pages > 5) {
          const isContinue = window.confirm(t('largeDataWarning', {records: res.total, seconds: res.pages * 5}));
          if (!isContinue) {
            setLoadingProgress(null);
            return null;
          }
        }
        const progress = (page || 1) / res.pages * 100;
        setLoadingProgress(progress > 100 ? 100 : progress);
        setEsPartedData(res);
      } else setTimeout(() => setLoadingProgress(null), 1000);
    });
  }

  const isLoading = useMemo(() => {
    return (regionsLoading || districtsLoading || esTypesLoading);
  }, [regionsLoading, districtsLoading, esTypesLoading]);

  useEffect(() => {
    if (filterDebounce && (esTypesChecked.length || selectAll)) {
      setLoadingProgress(1);
      setEmergenciesGeom({type: 'FeatureCollection', features: []});
      fetchPartedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDebounce]);

  useEffect(() => {
    if (esPartedData?.results) {
      const list: any[] = esPartedData.results.features || [];
      setEmergenciesGeom((prev: any) => ({...prev, features: esPartedData.page === 1 ? list : prev.features.concat(list)}));

      if (esPartedData.page < esPartedData.pages) setTimeout(() => fetchPartedData(esPartedData.page + 1), 100);
      else setTimeout(() => setLoadingProgress(null), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esPartedData]);

  useEffect(() => {
    const query: { [key: string]: string } = {};

    if (esTypesChecked && esTypesChecked.length > 0) {
      query.type_emergency = esTypesChecked.join(',');
    }

    if (!selectAll) query.type_emergency = esTypesChecked.join(',');
    if (state.region) query.region = state.region;
    if (state.district) query.district = state.district;
    if (state.reportType) query.type_report = state.reportType;
    if (state.status) query.status = state.status;
    if (state.reportStatus) query.late_information = state.reportStatus;

    if (dateRange.start && dateRange.end) {
      const start = dayjs(dateRange.start).format('YYYY-MM-DDTHH:mm:ss');
      const end = dayjs(dateRange.end).format('YYYY-MM-DDTHH:mm:ss');
      if (start.toLowerCase() !== 'invalid date') query.date_emergency__gte = start;
      if (end.toLowerCase() !== 'invalid date') query.date_emergency__lte = end;
    }

    setQueryState(makeQuery.stringify(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, esTypesChecked, esTypes, dateRange]);

  useEffect(() => {
    let esArray: any[] = [];

    if (emergenciesGeom) {
      emergenciesGeom?.features?.forEach((es: any) => {
        esTypes?.results?.forEach((type: any) => {
          if (es.properties.type_emergency === type.id && esTypesChecked.includes(type.id)) {
            const {geo_icon: {icon}} = type;
            if (es?.geometry?.type === 'Point') {
              esArray.push({
                id: es.properties.id,
                title: es.properties.title,
                description: es.properties.description,
                executor: es.properties.executor,
                status: es.properties.status,
                type_report: es.properties.type_report,
                date_emergency: es.properties.date_emergency,
                type_emergency: es.properties.type_emergency,
                type_emergency_name: es.type_emergency_name,
                icon,
                county: es.properties.county,
                human_casualties: es.properties.human_casualties,
                material_damage: es.properties.material_damage,
                field_emergency: es.properties.field_emergency,
                late_information: es.properties.late_information,
                region: es.properties.region,
                file_emergency: es.properties.file_emergency,
                position: [es?.geometry?.coordinates[1] !== null && es?.geometry?.coordinates[1] ? es?.geometry?.coordinates[1] : 0, es?.geometry?.coordinates[0] !== null && es?.geometry?.coordinates[0] ? es?.geometry?.coordinates[0] : 0]
              });
            } else {
              return
            }
          }
        });
      });
    }

    setEsResults(esArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergenciesGeom]);

  useEffect(() => {
    if (!esTypesChecked) return;

    const query: { [key: string]: string } = {}
    if (esTypesChecked && esTypesChecked.length > 0) {
      query.type_emergency = esTypesChecked.join(',');
    }
    if (!selectAll) query.type_emergency = esTypesChecked.join(',');
    if (state.region) query.region = state.region;
    if (state.reportType) query.type_report = state.reportType;
    if (state.status) query.status = state.status;
    if (state.reportStatus) query.late_information = state.reportStatus;

    const filteredResults = filterResults(esResults);
    setFilterArray(filteredResults);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [esResults, state, esTypesChecked]);

  const filterResults = (results: any) => {
    const result: any[] = [];
    results.forEach((es: any) => {
      if (
        (!state.region || String(state.region) === String(es.region)) &&
        (!state.reportType || state.reportType === es.type_report) &&
        (!state.status || state.status === es.status) &&
        (!state.reportStatus || String(state.reportStatus) === String(es.late_information)) &&
        (esTypesChecked.includes(es.type_emergency) || selectAll) &&
        (!result.find(el => el.id === es.id))
      ) result.push(es);
    });
    return result;
  };

  const dateTimeRangePickerValue = useMemo(() => {
    if (String(new Date(dateRange.start)).toLowerCase() !== 'invalid date' && String(new Date(dateRange.end)).toLowerCase() !== 'invalid date') {
      return {
        start: new Date(dateRange.start),
        end: new Date(dateRange.end)
      };
    }
    return undefined;
  }, [dateRange]);

  const handleSelectDates = (value: { start: Date; end: Date }) => {
    // @ts-ignore
    setDateRange(value)
    setDateRangeVisible(false)
  }

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let arr = [...esTypesChecked];
    let index;
    if (!arr.includes(id)) {
      arr.push(id);
    } else {
      index = arr.findIndex(el => el === id);
      arr.splice(index, 1);
    }
    if (esTypes?.results?.length !== arr.length) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }
    setEsTypesChecked(arr.sort());
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    setSelectAll(!selectAll)
    let arr: any[] = []
    if (checked) {
      esTypes?.results.forEach(el => {
        arr.push(el.id)
      })
      setEsTypesChecked(arr.sort());
    } else {
      setEsTypesChecked([]);
    }
  }

  const handleSelect = (e: any, name: string) => {
    const innerState: IState = {...state};
    innerState[name] = e?.value || '';
    if (name === 'region') innerState.district = '';
    setState(innerState);
  }

  const showLegend = () => {
    setVisibleLegend(!visibleLegend);
  }

  let obj: any = { count: 0 };
  filterArray.forEach((es: any) => {
    obj.count++;
    if (obj[es.type_emergency_name] === undefined) {
      obj[es.type_emergency_name] = 0;
    }
    obj[es.type_emergency_name]++;
  });

  const legendData = useMemo(() => {
    const data: {[key: string | number]: any} = { total: filterArray.length, results: [] };
    const byRegions: {[key: number]: any} = {};

    filterArray.forEach((es: any) => {
      if (byRegions[es.region || 0]) byRegions[es.region || 0]++;
      else byRegions[es.region || 0] = 1;
    });
    Object.entries(byRegions).forEach(pair => {
      data.results.push({ name: regions?.find(r => String(r.id) === String(pair[0]))?.name || 'n/a', count: pair[1] });
    })
    return data;
  }, [filterArray, regions]);

  const legendTypes = useMemo(() => {
    const data: {[key: string | number]: any} = { total: filterArray.length, results: [] };
    const byRegions: {[key: number]: any} = {};

    filterArray.forEach((es: any) => {
      if (byRegions[es.type_emergency || 0]) byRegions[es.type_emergency || 0]++;
      else byRegions[es.type_emergency || 0] = 1;
    });
    Object.entries(byRegions).forEach(pair => {
      data.results.push({ name: esTypes?.results?.find(r => String(r.id) === String(pair[0]))?.name || 'n/a', count: pair[1] });
    })
    return data;
  }, [filterArray, esTypes]);

  const legend = (
    visibleLegend
      ?
      <>
        {switcher === "region"
          ?
          <div className="map-legend">
            <div className="map-legend-inner-info">
              <p className="map-legend-title">{t('Total')}: {emergenciesGeom?.features?.length || 0}</p>
              <p className="map-legend-title">{t('shown')}: {legendData.total}</p>
              <div className="map-legend-info-block">
                {legendData.results.map((el: any, idx: number) => (
                  <div key={'legendItem' + idx} className="map-legend-info-inner-block">
                    <div className='map-legend-circle' style={{background: 'grey'}}></div>
                    <p
                      className="map-legend-type map-legend-es-type"><span
                      className="long-text">{el.name}</span><span className="number-text">{el.count}</span></p>
                  </div>
                ))}
              </div>
            </div>
            <div className="map-legend-btns">
              {switcher === 'region'
                ?
                <CButton size="sm" color="info" className="map-legend-switcher"
                         onClick={() => setSwitcher('types')}>
                  {t('ESMap.types')}
                </CButton>
                :
                <CButton size="sm" color="info" className="map-legend-switcher"
                         onClick={() => setSwitcher('region')}>
                  {t('ESMap.regions')}
                </CButton>
              }
              <i
                className="fa fa-arrow-right map-legend-arrow-hide map-legend-arrow-click"
                aria-hidden="true"
                onClick={() => showLegend()}
              />
            </div>

          </div>
          :
          <div className="map-legend">
            <div className="map-legend-inner-info">
              <p className="map-legend-title">{t('Total')}: {legendTypes.total}</p>
              <div className="map-legend-info-block">
                {legendTypes.results.map((el: any, idx: number) => (
                  <div key={'legendItem' + idx} className="map-legend-info-inner-block">
                    <div className='map-legend-circle' style={{background: 'grey'}}></div>
                    <p
                      className="map-legend-type map-legend-es-type"><span
                      className="long-text">{el.name}</span><span className="number-text">{el.count}</span></p>
                  </div>
                ))}
              </div>
            </div>
            <div className="map-legend-btns">
              {switcher === 'region'
                ?
                <CButton size="sm" color="info" className="map-legend-switcher"
                         onClick={() => setSwitcher('types')}>
                  {t('ESMap.types')}
                </CButton>
                :
                <CButton size="sm" color="info" className="map-legend-switcher"
                         onClick={() => setSwitcher('region')}>
                  {t('ESMap.regions')}
                </CButton>
              }
              <i
                className="fa fa-arrow-right map-legend-arrow-hide map-legend-arrow-click"
                aria-hidden="true"
                onClick={() => showLegend()}
              />
            </div>
          </div>
        }
      </>
      :
      <div className="map-legend-arrow-block">
        <i
          className="fa fa-arrow-left map-legend-arrow map-legend-arrow-click"
          aria-hidden="true"
          onClick={() => showLegend()}
        />
      </div>
  );

  return (
    <>
      <div className="pb-3 mb-2 title-divider">
        <h1>{t('nav.ES map')}</h1>
      </div>

      <div className="mb-2">
        {loadingProgress ?
          <LoaderLine visible={!!loadingProgress} width={loadingProgress + '%'}/>
          :
          <LoaderLine visible={isLoading}/>
        }
      </div>

      <div style={{display: 'flex'}}>
        <div className="es-columns es-type-column ">
          <div className="es-type-column__header">
            <div className="es-type-column__title">{t("esFeed.filterBy")}</div>
            <CFormCheck id={'select-all'}
                        style={{marginRight: '8px'}}
                        name={'select-all'}
                        onChange={handleSelectAll}
                        checked={selectAll}
            />
            <label htmlFor={'select-all'}>{t("esFeed.markAll")}</label>
          </div>
          <div className="es-scrollable-block">
            {
              esTypes?.results?.length ?
                <EmergencyTypesList data={esTypes?.results} state={esTypesChecked}
                                    onChange={handleChangeChecked}/> :
                null
            }
          </div>
        </div>

        <div className="es-columns es-column">
          <div className="filter-block-wrapper">
            <div style={{flex: 1}}>
              <FilterBlock regions={regions!} districts={districts!} onSelect={handleSelect} state={state} t={t}/>
            </div>

            <div className="d-flex justify-content-md-end mb-3 desktop-only" style={{position: 'relative'}}>
              <div style={{minWidth: '200px'}}>
                <CFormLabel>{t('Select dates')}</CFormLabel>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  {/*@ts-ignore*/}
                  <CFormInput style={{borderRadius: '6px', fontSize: '12px', minHeight: '38px'}}
                              type="text"
                              value={(dateRange.start ? dayjs(dateRange.start).format("DD/MM/YYYY") + " - " : "") + (dateRange.end ? dayjs(dateRange.end).format("DD/MM/YYYY") : "")}
                              placeholder={dateRange.start || dateRange.end ? "" : t('Date is not selected')}
                              onClick={() => setDateRangeVisible(true)}
                  />
                </div>
              </div>
              {dateRangeVisible && <div className="language-select-overlay" onClick={() => {
                setDateRangeVisible(false);
              }}/>}
              {dateRangeVisible ? <div style={{position: 'absolute', zIndex: 1031, top: '75px'}}>
                <DateTimeRangePicker locale={i18n.language} onChange={handleSelectDates}
                                     value={dateTimeRangePickerValue} presets/>
              </div> : null}
            </div>
          </div>
          {
            <EmergencyListMap
              data={loadingProgress ? [] : filterArray}
              legend={legend}
            />
          }
        </div>
      </div>
    </>
  );
};

export default EsMap;
