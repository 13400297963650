import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import './TimePicker.css';

const hoursOptions = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutesOptions = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];

type TTimePickerProps = {
  defaultValue?: string | null,
  onChange: (value: string | null) => void
};

const TimePicker: FC<TTimePickerProps> = ({defaultValue, onChange}) => {
  const {t} = useTranslation();

  const [state, setState] = useState<{[key: string]: string}>({hours: '', minutes: ''});

  useEffect(() => {
    const data = defaultValue?.split(':');
    if (data && !isNaN(Number(data[0])) && !isNaN(Number(data[1])) && Number(data[0]) < 24 && Number(data[1]) < 60) {
      const hours = '00' + data[0], minutes = '00' + data[1];
      setState({hours: hours.slice(hours.length - 2), minutes: minutes.slice(minutes.length - 2)});
    }
  }, []);

  const onSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const innerState = {...state};
    if (e.target.value) {
      innerState[e.target.name] = e.target.value;
      if (e.target.name === 'hours' && !innerState.minutes) innerState.minutes = '00';
      if (e.target.name === 'minutes' && !innerState.hours) innerState.hours = '00';
    } else {
      innerState.hours = '';
      innerState.minutes = '';
    }

    if (innerState.hours && innerState.minutes) onChange(`${innerState.hours}:${innerState.minutes}`);
    else onChange(null);

    setState(innerState);
  };

  return (
    <div className="time-picker">
      <div className="time-picker__select-wrapper">
        <select name="hours"
                value={state.hours}
                onChange={onSelectHandler}
        >
          <option value="">--</option>
          {hoursOptions.map(el => <option key={'hour' + el} value={el}>{el}</option>)}
        </select>
        <span>{t('hours')}</span>
      </div>

      <span className="time-picker__divider">:</span>

      <div className="time-picker__select-wrapper">
        <select name="minutes"
                value={state.minutes}
                onChange={onSelectHandler}
        >
          <option value="">--</option>
          {minutesOptions.map(el => <option key={'minute' + el} value={el}>{el}</option>)}
        </select>
        <span>{t('minutes')}</span>
      </div>
    </div>
  );
};

export default TimePicker;
